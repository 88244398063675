import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'

import { Redirect } from 'react-router-dom'
import { postLoginApplicantAction } from '../../../actions/api_actions'
import { saveRouteData } from '../../../actions/global_actions'
import { openTimeoutImageModal } from '../../../actions/modals_actions'

class AutoLogin extends Component {
  state = {
    redirect: false,
  }

  componentDidMount() {
    const { route, match, location } = this.props
    /* save global route data */
    this.props.saveRouteData(route, match, location)

    /* We extract just the apploi token from query string */
    const queryString = this.props.location.search
    const token = queryString.substring(
      queryString.indexOf('=') + 1,
      queryString.indexOf('&')
    )

    this.props
      .postLoginApplicantAction(window.activeEnvironment, null, null, token)
      .then((response) => {
        /* After we login we redirect to the job in question */
        this.setState({
          redirect: true,
        })
      })
      .catch((error) => {
        /* If there is property email in error message during register
        it means the email is already taken */
        if (error.errors.general.indexOf('invalid_login') > -1) {
          /* IMPORTANT: We need to redirect user to search route if he was not able to auto login (maybe jwt token expired on that link) or second stage job
          but we don't login the user.
          The id that we get in link is for second stage job. */
          this.props.openTimeoutImageModal({
            image: 'error',
            bodyMessage:
              'THERE WAS AN ERROR WHILE TRYING TO LOGIN. PLEASE TRY AGAIN.',
            timeout: 6000,
            pageReload: false,
            redirect: false,
            removeCloseModalOptions: false,
          })
          this.setState({
            redirect: true,
          })
        }
        if (error.errors.general.indexOf('signature_has_expired') > -1) {
          this.props.openTimeoutImageModal({
            image: 'error',
            bodyMessage: 'LOGIN TOKEN HAS EXPIRED',
            timeout: 6000,
            pageReload: false,
            redirect: false,
            removeCloseModalOptions: false,
          })
          this.setState({
            redirect: true,
          })
        }
      })
  }

  configureRedirect() {
    /* Extract utm's and other query params from url string. Separate everything after '?' based on '&' */
    if (window.location.search && window.location.search.length > 0) {
      const queryArray = window.location.search
        .substring(window.location.search.indexOf('?') + 1)
        .split('&')

      let buildQuery = ''

      /* check if utm exists in url and build a query string */
      queryArray.forEach((param) => {
        if (param.indexOf('utm_campaign') >= 0) {
          buildQuery += `utm_campaign=${param.substring(
            param.indexOf('=') + 1
          )}&`
        }
        if (param.indexOf('utm_source') >= 0) {
          buildQuery += `utm_source=${param.substring(param.indexOf('=') + 1)}&`
        }
        if (param.indexOf('utm_medium') >= 0) {
          buildQuery += `utm_medium=${param.substring(param.indexOf('=') + 1)}&`
        }
        if (param.indexOf('utm_term') >= 0) {
          buildQuery += `utm_term=${param.substring(param.indexOf('=') + 1)}&`
        }
        /* next to utm's we can also have interview_guide query in URL which tells us if we should show normal full apply page or interview guide page */
        if (param.indexOf('interview_guide') >= 0) {
          buildQuery += `interview_guide=${param.substring(
            param.indexOf('=') + 1
          )}&`
        }
      })

      /* If buildQuery string length is bigger then 0 add '?' at the beginning and remove the
      last '&' sign that was added while building the string */
      const checkBuildQuery = () => {
        if (buildQuery.length > 0) {
          return `?${buildQuery.substring(0, buildQuery.length - 1)}`
        }
        return null
      }

      return `/view/${this.props.match.params.slug}${checkBuildQuery()}`
    }
  }

  render() {
    return (
      <Fragment>
        {/* We need to use Redirect in order for Redux store stays filled with data. If we sues something like </a> it would be deleted */}
        {this.state.redirect ? (
          <Redirect to={this.configureRedirect()} />
        ) : null}
      </Fragment>
    )
  }
}

const mapStateToProps = (state) => ({})

export default connect(mapStateToProps, {
  saveRouteData,
  postLoginApplicantAction,
  openTimeoutImageModal,
})(AutoLogin)
