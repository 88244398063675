import styled from 'styled-components'

export const ButtonAction = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 260px;
  height: 35px;
  box-sizing: border-box;
  border: 2px solid #a761a9;
  border-radius: 8px;
  background-color: #a761a9;
  color: #fff;
  transition: all 0.3s;

  &:hover {
    cursor: pointer;
    background-color: #fff;
    color: #a761a9;
    transition: all 0.3s;
  }
`
