/* Scenes is a top level component so in `scenes_styled` we
should put styles meant for the the whole app */

import React from 'react'
import { hot } from 'react-hot-loader/root'

import Navigation from 'Navigation'
import * as Styled from 'scenes_styled'
import ModalManager from './sharedComponents/modals/ModalManager'

const Scenes = () => (
  <Styled.ScenesContainer>
    <Navigation />
    <ModalManager />
  </Styled.ScenesContainer>
)
/* Don't use hot in production mode */
const exportedApp = process.env.NODE_ENV === 'production' ? Scenes : hot(Scenes)

export default exportedApp
