import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import * as Styled from 'jobDescriptionFullApplyForm_styled'
import { Checkbox, Accordion, Input } from 'semantic-ui-react'
import AnimateHeight from 'react-animate-height'
import DatePicker, { registerLocale } from "react-datepicker";
import es from 'date-fns/locale/es';
import moment from 'moment'
import { withTranslation } from 'react-i18next'
import {
  savePersonalInformationQuestions,
  savePersonalQuestionsSubmitRequirements,
  addNewQuestion,
  deleteQuestion,
  changeQuestionExpand,
} from '../../../../../../../actions/jobApplication_actions'

registerLocale('es', es)

class ExperienceQuestion extends Component {
  state = {
    questionType: 'experienceQuestion',
    inputSuccessCheck: [],
  }

  componentDidMount() {
    this.checkOptionalConditions()
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    /* When we add or remove experience question we must update the state array so it has the same
    number of objects in array as there are questions in redux store.  We need to have at least
    one as a default in redux store.
    getDerivedStateFromProps is invoked right before calling the render method, both on the
    initial mount and on subsequent updates.
    */

    if (
      nextProps.experienceQuestion.length !== prevState.inputSuccessCheck.length
    ) {
      const experienceQuestionNumber = []

      for (let i = 0; i < nextProps.experienceQuestion.length; i += 1) {
        experienceQuestionNumber.push({
          employerInput: false,
          titleInput: false,
          descriptionInput: false,
          startDateInput: false,
          endDateInput: false,
          currentJobInput: false,
        })
      }

      /* By returning the object we are setting the state */
      return {
        inputSuccessCheck: experienceQuestionNumber,
      }
    }
    return null
  }

  componentDidUpdate(prevProps) {
    const { experienceQuestion } = this.props

    const checkChangedInputs = () => {
      let employerInput = false
      let titleInput = false
      let descriptionInput = false
      let startDateInput = false
      let endDateInput = false
      let currentJobInput = false

      /* Since we can delete or add experience question we need to pick the shorter length between prevProps and this.props. Example we have 2 items in array and we delete one, then in prevProps we still have 2 items but in this.props we have 1 item. If we loop through prevProps always we will get an error because index will be bigger then the number of items. */

      const lengthChoice =
        prevProps.experienceQuestion.length <= experienceQuestion.length
          ? prevProps.experienceQuestion.length
          : experienceQuestion.length

      for (let i = 0; i < lengthChoice; i += 1) {
        /* Compare previous state and current for all monitored inputs and if its changed we
      call the function */

        if (
          JSON.stringify(prevProps.experienceQuestion[i].employer) !==
          JSON.stringify(experienceQuestion[i].employer)
        ) {
          employerInput = true
        }
        if (
          JSON.stringify(prevProps.experienceQuestion[i].title) !==
          JSON.stringify(experienceQuestion[i].title)
        ) {
          titleInput = true
        }
        if (
          JSON.stringify(prevProps.experienceQuestion[i].description) !==
          JSON.stringify(experienceQuestion[i].description)
        ) {
          descriptionInput = true
        }

        if (
          JSON.stringify(prevProps.experienceQuestion[i].start_date) !==
          JSON.stringify(experienceQuestion[i].start_date)
        ) {
          startDateInput = true
        }

        if (
          JSON.stringify(prevProps.experienceQuestion[i].end_date) !==
          JSON.stringify(experienceQuestion[i].end_date)
        ) {
          endDateInput = true
        }

        if (
          JSON.stringify(prevProps.experienceQuestion[i].current) !==
          JSON.stringify(experienceQuestion[i].current)
        ) {
          currentJobInput = true
        }
      }

      /* In parenthesis we put all the inputs that could have changed and if any of them has
      we call the function */
      if (
        employerInput ||
        titleInput ||
        descriptionInput ||
        startDateInput ||
        endDateInput ||
        currentJobInput ||
        // we must have this because by deleting and adding the questions we are changing the length of array but not the values. So in order to trigger 'checkOptionalConditions' we must also compare the length. We aee this for example when we have one filled out question and one empty. After deleting the empty question design color is not changed back to success.
        prevProps.experienceQuestion.length !== experienceQuestion.length
      ) {
        this.checkOptionalConditions()
      }
    }

    checkChangedInputs()
  }

  checkOptionalConditions = () => {
    const { experienceQuestion } = this.props

    const newInputSuccessCheck = this.state.inputSuccessCheck.slice()

    /* Make conditions when specific inputs are considered true and successfully filled out */
    for (let i = 0; i < experienceQuestion.length; i += 1) {
      /* check every property individually and set it to true based on custom condition */

      /* employerInput */
      /* ------------------------------- */
      if (
        experienceQuestion[i].employer &&
        experienceQuestion[i].employer.trim().length > 0
      ) {
        newInputSuccessCheck[i].employerInput = true
      } else {
        newInputSuccessCheck[i].employerInput = false
      }

      /* titleInput */
      /* ------------------------------- */
      if (
        experienceQuestion[i].title &&
        experienceQuestion[i].title.trim().length > 0
      ) {
        newInputSuccessCheck[i].titleInput = true
      } else {
        newInputSuccessCheck[i].titleInput = false
      }

      /* descriptionInput */
      /* ------------------------------- */
      if (
        experienceQuestion[i].description &&
        experienceQuestion[i].description.trim().length > 0
      ) {
        newInputSuccessCheck[i].descriptionInput = true
      } else {
        newInputSuccessCheck[i].descriptionInput = false
      }

      /* startDateInput */
      /* ------------------------------- */
      if (
        experienceQuestion[i].start_date &&
        experienceQuestion[i].start_date.trim().length > 0
      ) {
        newInputSuccessCheck[i].startDateInput = true
      } else {
        newInputSuccessCheck[i].startDateInput = false
      }

      /* endDateInput */
      /* ------------------------------- */
      /* When checkbox for current job input is checked, then endDateInput and currentJobInput is set to 'true'. In redux 'end_date' property is also set to true and 'current' property can be empty string or have a date.

      When endDate input is filled out but checkbox for current job is not checked, then 'endDateInput' and 'currentJobInput' properties will be true but 'end_date' property in redux store will be 'false'. This way we can submit because 'submitReady' property will be set to true (checks the local 'endDateInput' and 'currentJobInput' state) */
      if (
        (experienceQuestion[i].end_date &&
          experienceQuestion[i].end_date.trim().length > 0) ||
        experienceQuestion[i].current
      ) {
        newInputSuccessCheck[i].endDateInput = true
        newInputSuccessCheck[i].currentJobInput = true
      } else {
        newInputSuccessCheck[i].endDateInput = false
      }

      /* currentJobInput */
      /* ------------------------------- */
      if (
        (experienceQuestion[i].end_date &&
          experienceQuestion[i].end_date.trim().length > 0) ||
        experienceQuestion[i].current
      ) {
        newInputSuccessCheck[i].currentJobInput = true
      } else {
        newInputSuccessCheck[i].currentJobInput = false
      }
    }

    /* Set new state after checking conditions for the inputs */
    this.setState(
      {
        inputSuccessCheck: newInputSuccessCheck,
      },
      () => {
        let submitReady = true
        /* If one of the values in the array is false, it means one of the inputs is not filled correctly and we set submitReady to false.
          Until submit ready is true we can not submit application. */
        for (let i = 0; i < this.state.inputSuccessCheck.length; i += 1) {
          if (
            Object.values(this.state.inputSuccessCheck[i]).indexOf(false) > -1
          ) {
            submitReady = false
          }
        }

        /* We save if the question is submitReady or not to redux store */
        this.props.savePersonalQuestionsSubmitRequirements(
          this.state.questionType,
          submitReady
        )
      }
    )
  }

  handleDateChangeRaw = (e) => {
    /* We use this for stopping user from using keyboard or pasting data in input field so we don't get an error */
    e.preventDefault()
  }

  handleDeleteQuestion = (property, index) => {
    this.props.deleteQuestion(property, index)
  }

  handleAddNewQuestion = (property) => {
    this.props.addNewQuestion(property)
  }

  handleQuestionExpand = (questionType) => {
    this.props.changeQuestionExpand({ questionType, id: '1-experience' })
  }

  handleInputChange = (questionType, property, value, index) => {
    /* "isClearable" property in DatePicker is used to clear the input. When we click it "handleInputChange" is called
    automatically. "Invalid date" value is sent, so we check if this string value is sent and reset input to empty string if it is. */
    if (value === 'Invalid date' || value === '') {
      this.props.savePersonalInformationQuestions(
        questionType,
        property,
        null,
        index
      )
    } else {
      this.props.savePersonalInformationQuestions(
        questionType,
        property,
        value,
        index
      )
    }
  }

  checkQuestionDesign(property, index) {
    const { firstTimeSubmitButtonClicked } = this.props
    const { inputSuccessCheck } = this.state

    /* Depending if input or some other element on the page is filled out correctly, wrongly or nothing we change design */
    let warning
    let success
    let neutral

    const returnDesignPattern = () => {
      if (warning) {
        return warning
      }

      if (success) {
        return success
      }

      return neutral
    }

    /* check if any of the property in inputSuccessCheck is 'false' */
    const checkIfFalse = () => {
      let falseCheck = false

      for (let i = 0; i < inputSuccessCheck.length; i += 1) {
        if (Object.values(inputSuccessCheck[i]).indexOf(false) > -1) {
          falseCheck = true
        }
      }

      return falseCheck
    }

    /* Checks for which design we are gonna use on which element */
    /* ---------------------------------------------- */

    const checkInputWarning = (name) => {
      /* Warning input design can only show up after we clicked the submit button if
      input is not filled out correctly */
      if (
        firstTimeSubmitButtonClicked &&
        inputSuccessCheck[index][name] === false
      ) {
        return 'warning'
      }
    }

    const checkInputSuccess = (name) => {
      /* We show success input design before we hit submit button for the first
      time and after also so the only condition is that input is filled out correctly. */
      if (inputSuccessCheck[index][name] === true) {
        return 'success'
      }
    }

    /* We show warning design only after submit button is clicked for the first TimeRanges,
    if one of the inputs is not filled out correctly.
    If there is a 'false' in property we get back 'true'.
    */
    const checkQuestionContainerWarning = () => {
      if (firstTimeSubmitButtonClicked && checkIfFalse()) {
        return 'warning'
      }
    }

    /* Check the whole question container design. If all the inputs are filled out correctly we
    show success design */

    const checkQuestionContainerSuccess = () => {
      /* check if all the properties in inputSuccessCheck are true. If there isn't a false property
      we get back in response 'false'. We turn it around with "!" and trigger success  */
      if (!checkIfFalse()) {
        return 'success'
      }
    }
    /* ---------------------------------------------- */

    /* based on the input that we are checking we are passing that property to check design */
    switch (property) {
      case 'employerInput':
      case 'titleInput':
      case 'descriptionInput':
      case 'startDateInput':
      case 'endDateInput':
        warning = checkInputWarning(property)
        success = checkInputSuccess(property)
        neutral = 'neutral'

        return returnDesignPattern()

      case 'questionContainer':
        warning = checkQuestionContainerWarning()
        success = checkQuestionContainerSuccess()
        neutral = 'neutral'

        return returnDesignPattern()
      default:
        return returnDesignPattern()
    }
  }

  renderExperienceQuestions() {
    const { experienceQuestion, t, i18n } = this.props
    return experienceQuestion.map((data, index) => (
      <Styled.QuestionBlock key={index}>
        <Styled.FormGroup>
          <Styled.FormField>
            <Styled.InputDesignWrapper
              statedesign={this.checkQuestionDesign('employerInput', index)}
            >
              <input
                placeholder={t('employer')}
                onChange={(e) =>
                  this.handleInputChange(
                    this.state.questionType,
                    'employer',
                    e.target.value,
                    index
                  )
                }
                value={experienceQuestion[index].employer || ''}
              />
            </Styled.InputDesignWrapper>
          </Styled.FormField>
          <Styled.FormField>
            <Styled.InputDesignWrapper
              statedesign={this.checkQuestionDesign('titleInput', index)}
            >
              <input
                placeholder={t('jobTitle')}
                onChange={(e) =>
                  this.handleInputChange(
                    this.state.questionType,
                    'title',
                    e.target.value,
                    index
                  )
                }
                value={experienceQuestion[index].title || ''}
              />
            </Styled.InputDesignWrapper>
          </Styled.FormField>
        </Styled.FormGroup>
        <Styled.FormGroup>
          <Styled.FormField>
            <Styled.TextAreaInput
              placeholder={t('jobDescription')}
              onChange={(e) =>
                this.handleInputChange(
                  this.state.questionType,
                  'description',
                  e.target.value,
                  index
                )
              }
              value={experienceQuestion[index].description || ''}
              statedesign={this.checkQuestionDesign('descriptionInput', index)} // pass which design we will use
            />
          </Styled.FormField>
        </Styled.FormGroup>
        <Styled.FormGroup>
          <Styled.FormFieldContainer>
            <Styled.FormFieldInputTitle>{t('startDate')}</Styled.FormFieldInputTitle>
            <Styled.DatePickerWrapper
              statedesign={this.checkQuestionDesign('startDateInput', index)}
            >
              <DatePicker
                locale={i18n.language}
                dateFormat="MM/dd/yyyy"
                showYearDropdown
                selectsStart
                startDate={
                  (experienceQuestion[index].start_date &&
                    new Date(experienceQuestion[index].start_date)) ||
                  null
                }
                endDate={
                  (experienceQuestion[index].end_date &&
                    new Date(experienceQuestion[index].end_date)) ||
                  null
                }
                maxDate={
                  (experienceQuestion[index].end_date &&
                    new Date(experienceQuestion[index].end_date)) ||
                  null
                }
                isClearable
                showPopperArrow={false}
                onChangeRaw={this.handleDateChangeRaw}
                customInput={
                  <Input icon="calendar alternate" iconPosition="left" />
                }
                placeholderText={t('startDate')}
                onChange={(value, e) =>
                  this.handleInputChange(
                    this.state.questionType,
                    'start_date',
                    moment(value).format(),
                    index
                  )
                }
                selected={
                  (experienceQuestion[index].start_date &&
                    new Date(experienceQuestion[index].start_date)) ||
                  null
                }
              />
            </Styled.DatePickerWrapper>
          </Styled.FormFieldContainer>
          <Styled.FormFieldContainer>
            {experienceQuestion[index].current ? null : (
              <Fragment>
                <Styled.FormFieldInputTitle>
                  {t('endDate')}
                </Styled.FormFieldInputTitle>
                <Styled.DatePickerWrapper
                  statedesign={this.checkQuestionDesign('endDateInput', index)}
                >
                  <DatePicker
                    locale={i18n.language}
                    dateFormat="MM/dd/yyyy"
                    showYearDropdown
                    selectEnd
                    startDate={
                      (experienceQuestion[index].start_date &&
                        new Date(experienceQuestion[index].start_date)) ||
                      null
                    }
                    endDate={
                      (experienceQuestion[index].end_date &&
                        new Date(experienceQuestion[index].end_date)) ||
                      null
                    }
                    minDate={
                      (experienceQuestion[index].start_date &&
                        new Date(experienceQuestion[index].start_date)) ||
                      null
                    }
                    isClearable
                    showPopperArrow={false}
                    onChangeRaw={this.handleDateChangeRaw}
                    customInput={
                      <Input icon="calendar alternate" iconPosition="left" />
                    }
                    placeholderText={t('endDate')}
                    onChange={(value, e) =>
                      this.handleInputChange(
                        this.state.questionType,
                        'end_date',
                        moment(value).format(),
                        index
                      )
                    }
                    selected={
                      (experienceQuestion[index].end_date &&
                        new Date(experienceQuestion[index].end_date)) ||
                      null
                    }
                  />
                </Styled.DatePickerWrapper>
              </Fragment>
            )}
          </Styled.FormFieldContainer>
        </Styled.FormGroup>
        <Styled.FormField>
          <Checkbox
            label={t('presentJob')}
            onChange={() =>
              this.handleInputChange(
                this.state.questionType,
                'current',
                !experienceQuestion[index].current || false,
                index
              )
            }
            checked={
              experienceQuestion[index].current
                ? experienceQuestion[index].current
                : false
            }
          />
        </Styled.FormField>

        <Styled.FormField>
          {/* Dont show the trash can if only one experienceQuestion is left */}
          {experienceQuestion.length > 1 ? (
            <Styled.DeleteIcon
              onClick={() =>
                this.handleDeleteQuestion('experienceQuestion', index)
              }
            >
              {t('remove')}
            </Styled.DeleteIcon>
          ) : null}
        </Styled.FormField>
        {/* Only show Add more on the last Question in array */}
        {experienceQuestion.length - 1 === index && (
          <Styled.AddMoreButton
            onClick={() => this.handleAddNewQuestion('experienceQuestion')}
          >
            {t('addMoreExperience')}
          </Styled.AddMoreButton>
        )}
      </Styled.QuestionBlock>
    ))
  }

  render() {
    const { t } = this.props
    return (
      <Styled.PersonalQuestionContainer
        statedesign={this.checkQuestionDesign('questionContainer')}
      >
        <Accordion>
          <Styled.AccordionTitle
            active={
              this.props.questionExpand[this.state.questionType][0].expand
            }
            onClick={() => this.handleQuestionExpand(this.state.questionType)}
          >
            <Styled.AccordionTitleTextContainer>
              <Styled.QuestionIconBorder>
                <Styled.QuestionIcon name="suitcase" />{' '}
              </Styled.QuestionIconBorder>

              <Styled.AccordionTitleText>
                {t('jobAddDetailsTitle')}
                <br />
                <span>{t('jobAddDetailsSubTitle')}</span>
              </Styled.AccordionTitleText>
            </Styled.AccordionTitleTextContainer>

            {this.props.questionExpand[this.state.questionType][0].expand ? (
              <Styled.ExpandIcon name="chevron circle up" />
            ) : (
              <Styled.ExpandIcon name="chevron circle down" />
            )}
          </Styled.AccordionTitle>
          <Styled.AccordionContent
            active={
              this.props.questionExpand[this.state.questionType][0].expand
            }
          >
            <AnimateHeight
              animateOpacity
              duration={300}
              height={
                this.props.questionExpand[this.state.questionType][0].expand
                  ? 'auto'
                  : 0
              }
            >
              {this.renderExperienceQuestions()}
            </AnimateHeight>
          </Styled.AccordionContent>
        </Accordion>
      </Styled.PersonalQuestionContainer>
    )
  }
}

const mapStateToProps = (state) => ({
  experienceQuestion:
    state.jobApplicationReducer.fullApplicationPersonalQuestions
      .experienceQuestion,
  firstTimeSubmitButtonClicked:
    state.jobApplicationReducer.submitRequirements.firstTimeSubmitButtonClicked,
  questionExpand: state.jobApplicationReducer.questionExpand,
})

ExperienceQuestion.propTypes = {
  addNewQuestion: PropTypes.func, // used to add new question to the list of questions in redux
  deleteQuestion: PropTypes.func, // used to delete a question from the list of questions in redux
  changeQuestionExpand: PropTypes.func, // used to expand or collapse question accordion.
  experienceQuestion: PropTypes.array, // array of all the questions that we added
  firstTimeSubmitButtonClicked: PropTypes.bool, // if true it means that "Submit" form button was clicked at least once.
  questionExpand: PropTypes.object, // contains all the questions and tells if question accordion is expanded or not.
  savePersonalInformationQuestions: PropTypes.func, // used to save personal information questions input data to redux store
  savePersonalQuestionsSubmitRequirements: PropTypes.func, // used to save if personal questions are submit ready
}

export default connect(mapStateToProps, {
  savePersonalInformationQuestions,
  savePersonalQuestionsSubmitRequirements,
  addNewQuestion,
  deleteQuestion,
  changeQuestionExpand,
})(withTranslation('jobDescriptionFullApply')(ExperienceQuestion))
