import styled from 'styled-components'

export const IndustriesFilterContainer = styled.div`
`

export const IndustriesHeaderTitle = styled.h3`
  border-bottom: 2px solid #795548;
  color: #939598;
  font-size: 1.2rem;
  letter-spacing: 0.2rem;
  margin-top: 3rem;
  margin-top: 1.3rem;
  padding: 0.8rem;
  padding-left: 1.3rem;
`

export const IndustriesBodyCheckboxes = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 62vh;
  overflow-x: hidden;
  overflow-y: auto;
`

export const LabelCheckbox = styled.label`
  cursor: pointer;
  display: flex;
  font-weight: normal;
  margin-bottom: 0;
  padding: 0.5rem 0;
  word-break: break-all;
`

export const InputCheckbox = styled.input`
  margin-right: 0.5rem;
`
