import styled from 'styled-components'

export const JobDescriptionField = styled.div`
  margin-bottom: 25px;

  h3 {
    margin: 0;
    font-family: Helvetica !important;
    font-size: 18px;
    font-weight: 700;
    line-height: 20.7px;
    text-align: left;
  }

  p {
    margin-top: 13px;
    font-family: Helvetica !important;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }
`
