import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Modal } from 'semantic-ui-react'
import { withTranslation } from "react-i18next";
import * as Styled from 'authorizationModals_styled'
import spinner from '../../../../../../public/assets/img/spinner.gif'
import { postFullApplyFormQuestionsAction } from '../../../../actions/api_actions'
import {
  openTimeoutImageModal,
  closeSubmitModal,
} from '../../../../actions/modals_actions'
import {
  getURLStringParams
} from '../../../../helperFunctions/helperFunctions'

class SubmitModal extends Component {
  state = {
    /* spinner */
    showSpinner: false,
  }

  configureRedirectLocation = () => {
    const keyParamsToFilter = [
      {
        key: 'utm_source',
        replaceValue: false,
        valueToReplace: null
      },
      {
        key: 'utm_campaign',
        replaceValue: true,
        valueToReplace: 'apploi_sourced'
      },
      {
        key: 'utm_medium',
        replaceValue: false,
        valueToReplace: null
      },
    ]
    const urlParams = getURLStringParams(keyParamsToFilter)

    /* Check if it's a blind job. If it is don't redirect the page to company profile */
    if (this.props.jobDescription.show_company_logo) {
      /* If there is a slug value inside 'parent_url_slug' that means we are on a child team.
    If we apply on child team based on the redirect_to_parent property from
    truman we will redirect user to parent company profile or child company profile */
      if (this.props.companyProfile.parent_url_slug) {
        return this.props.companyProfile.redirect_to_parent
          ? `${window.location.origin}/profile/${this.props.companyProfile.parent_url_slug}?${urlParams}`
          : `${window.location.origin}/profile/${this.props.companyProfile.url_slug}?${urlParams}`
      }
      /* If there is no value in 'parent_url_slug' that means we are on parent team company profile
      and we redirect back to it after apply */
      return `${window.location.origin}/profile/${this.props.companyProfile.url_slug}?${urlParams}`
    }

    return false
  }

  configureInterviewGuideRedirectLocation = (redirectPostSubmit) => {
    window.location.href = redirectPostSubmit
  }

  handleFullApplyFormSubmit = () => {
    this.setState(
      {
        showSpinner: true,
      },
      () => {
        const { t } = this.props
        this.props
          .postFullApplyFormQuestionsAction(
            window.activeEnvironment,
            this.props.fullApplicationForm.id,
            this.props.applicant.token,
            this.props.modalProps.interviewGuide
          )
          .then((response) => {
            const [wotcResponse, submitResponse] = response
            /* If interviewGuide is active and we Submit it needs to redirect back to Truamn. */
            if (
              this.props.modalProps.interviewGuide &&
              submitResponse.redirect_post_submit
            ) {
              this.configureInterviewGuideRedirectLocation(
                submitResponse.redirect_post_submit
              )
            } else {
              this.props.openTimeoutImageModal({
                image: 'success',
                bodyMessage: t('submitSuccessful'),
                timeout: 3000,
                gtm: 'apply-success',
                pageReload: true,
                redirect: wotcResponse?.wotc_redirect_url ? wotcResponse.wotc_redirect_url : this.configureRedirectLocation(),
                removeCloseModalOptions: false,
              })
              this.props.closeSubmitModal()
            }
          })
          .catch((error) => {
            this.props.openTimeoutImageModal({
              image: 'error',
              bodyMessage: error.developer_message,
              timeout: 3000,
              pageReload: false,
              redirect: false,
              removeCloseModalOptions: false,
            })
            this.props.closeSubmitModal()
          })
      }
    )
  }

  render() {
    const { t } = this.props
    return (
      <Styled.ModalContainer
        size="mini"
        open
        onClose={this.props.closeSubmitModal}
        closeIcon
      >
        <Styled.ModalContent>
          <Modal.Description>
            <Styled.ModalHeader>
              {t('submitConfirmation')}
            </Styled.ModalHeader>

            {this.state.showSpinner ? (
              <img src={spinner} width="50" />
            ) : (
              <Fragment>
                <Styled.ButtonModal
                  authentication="warning"
                  onClick={() => this.handleFullApplyFormSubmit()}
                >
                  {t('submitOk')}
                </Styled.ButtonModal>

                <Styled.ButtonModal
                  authentication="neutral"
                  onClick={() => this.props.closeSubmitModal()}
                >
                  {t('submitCancel')}
                </Styled.ButtonModal>
              </Fragment>
            )}
          </Modal.Description>
        </Styled.ModalContent>
      </Styled.ModalContainer>
    )
  }
}

const mapStateToProps = (state) => ({
  jobDescription: state.jobDescriptionReducer.jobDescription,
  companyProfile: state.companyProfileReducer.companyProfile,
  fullApplicationForm: state.jobApplicationReducer.fullApplicationForm,
  applicant: state.authenticationReducer.applicant,
  globalReducer: state.globalReducer,
})

SubmitModal.propTypes = {
  jobDescription: PropTypes.object, // comes from jobDescriptionReducer. Contains data about current active job.
  closeSubmitModal: PropTypes.func, // close submit modal
  modalProps: PropTypes.shape({
    interviewGuide: PropTypes.bool, // tells us if we should show resume.
  }),
  applicant: PropTypes.object, // comes from authenticationReducer. Contains data about the current logged in user.
  fullApplicationForm: PropTypes.object, // original response that we get back from API when retrieve full apply questions form data (not changed or adjusted).
}

export default connect(mapStateToProps, {
  closeSubmitModal,
  postFullApplyFormQuestionsAction,
  openTimeoutImageModal,
})(withTranslation('jobDescriptionFullApply')(SubmitModal))
