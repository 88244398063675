import React from 'react'
import PropTypes from 'prop-types'
import { NursysVerificationError, SVVerificationError } from './components'

const VerificationError = (props) => {
  const component = {
    nursysapi: (
      <NursysVerificationError
        verificationType={props.verificationType}
        verificationData={props.verificationData}
        changeActiveStep={props.changeActiveStep}
      />
    ),
    svapi: (
      <SVVerificationError
        verificationType={props.verificationType.toUpperCase()}
        verificationStatus={props.verificationStatus}
        changeActiveStep={props.changeActiveStep}
      />
    ),
  }

  return component[props.verificationEngine.toLowerCase()] || null
}

VerificationError.propTypes = {
  // used to change activeStep which changes the verification screen
  changeActiveStep: PropTypes.func,
  // data extracted from URL string
  verificationData: PropTypes.object,
  // lets us know which verification type we are one (LPN, RN...)
  verificationType: PropTypes.string,
  // full name of the state
  longStateName: PropTypes.string,
  // Verification engine used (Streamline Verify or Nursys APIs)
  verificationEngine: PropTypes.string,
}

export default VerificationError
