import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import * as Styled from './styled'

const CustomLinkButton = props => {
  const {
    external,
    text,
    url,
  } = props

  const linkProps = external ? {
    to: { pathname: url },
    target: '_blank',
    rel: 'noopener noreferrer',
  } : {
    to: url,
  }

  return (
    <Link {...linkProps}>
      <Styled.ButtonAction>
        {text}
      </Styled.ButtonAction>
    </Link>
  )
}

CustomLinkButton.propTypes = {}

export default CustomLinkButton
