import React from 'react'

const SuccessShieldIcon = () => {
  return (
    <svg
      width="42"
      height="39"
      viewBox="0 0 42 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.25 8.125L21 1.625L36.75 8.125V17.875C36.75 26.8938 30.03 35.3275 21 37.375C11.97 35.3275 5.25 26.8938 5.25 17.875V8.125ZM10.5 21.125L17.5 27.625L31.5 14.625L29.0325 12.3175L17.5 23.0263L12.9675 18.8337L10.5 21.125Z"
        fill="#219653"
      />
    </svg>
  )
}

export default SuccessShieldIcon
