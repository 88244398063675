/* save user data when user makes a quick applyMiddleware, logins or something else */

const saveUserData = (userData) => ({
  type: 'SAVE_USER_DATA',
  /* userData is object that can have different properties so we just spread them */
  payload: {
    ...userData,
  },
})

export { saveUserData }
