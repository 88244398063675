import { createStore, combineReducers, applyMiddleware, compose } from 'redux'
import thunkMiddleware from 'redux-thunk'
import globalReducer from '../reducers/global_reducer'
import companyProfileReducer from '../reducers/companyProfile_reducer'
import jobDescriptionReducer from '../reducers/jobDescription_reducer'
import modalsReducer from '../reducers/modals_reducer'
import userReducer from '../reducers/user_reducer'
import jobApplicationReducer from '../reducers/jobApplication_reducer'
import authenticationReducer from '../reducers/authentication_reducer'
import licenseVerificationReducer from '../reducers/licenseVerification_reducer'

/* eslint-disable no-underscore-dangle */
const composeEnhancer =
  (typeof window !== 'undefined' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose
/* eslint-enable */

export default () =>
  createStore(
    combineReducers({
      globalReducer,
      companyProfileReducer,
      jobDescriptionReducer,
      modalsReducer,
      userReducer,
      jobApplicationReducer,
      authenticationReducer,
      licenseVerificationReducer,
    }),

    composeEnhancer(applyMiddleware(thunkMiddleware))
  )
