/* actions for closing and opening of modals */
/* to skip specific prop send null instead */

const savePersonalInformationQuestions = (
  questionType,
  property,
  value,
  index
) => ({
  type: 'SAVE_PERSONAL_INFORMATION_QUESTIONS',
  payload: {
    questionType,
    property,
    value,
    index,
  },
})

const saveTrumanQuestionsInformation = (question_id, propertyValue) => ({
  type: 'SAVE_TRUMAN_QUESTIONS_INFORMATION',
  payload: {
    question_id,
    propertyValue,
  },
})

const addNewQuestion = (property) => ({
  type: 'ADD_NEW_QUESTION',
  payload: { property },
})

const deleteQuestion = (property, index) => ({
  type: 'DELETE_QUESTION',
  payload: { property, index },
})

const changeQuestionExpand = (property) => ({
  type: 'CHANGE_QUESTION_EXPAND',
  payload: { property },
})

const saveFullApplyForm = (form) => ({
  type: 'SAVE_FULL_APPLY_FORM',
  payload: { form },
})

const adjustFullApplyFormResponse = (form) => ({
  type: 'ADJUST_FULL_APPLY_FORM_RESPONSE',
  payload: { form },
})

const savePersonalQuestionsSubmitRequirements = (
  questionType,
  submitReady
) => ({
  type: 'SAVE_PERSONAL_QUESTIONS_SUBMIT_REQUIREMENTS',
  payload: { questionType, submitReady },
})

const saveSubmitRequirements = (id, submitReady) => ({
  type: 'SAVE_SUBMIT_REQUIREMENTS',
  payload: { id, submitReady },
})

const saveSubmitButtonClicked = (state) => ({
  type: 'SAVE_SUBMIT_BUTTON_CLICKED',
  payload: { state },
})

const startFullApplyFormActivate = (activateState) => ({
  type: 'START_FULL_APPLY_FORM_ACTIVATE',
  payload: { activateState },
})

const disableFullApplyFormAutosave = (disabledAutosaveProperties) => ({
  type: 'DISABLE_FULL_APPLY_FORM_AUTOSAVE',
  payload: { disabledAutosaveProperties },
})

const saveFullApplyFormPersonalData = (personalData) => ({
  type: 'SAVE_FULL_APPLY_FORM_PERSONAL_DATA',
  payload: { personalData },
})

const saveSpecificAnswers = (questionAnswers) => ({
  type: 'SAVE_SPECIFIC_ANSWERS',
  payload: { questionAnswers },
})

const saveUploadedResumeFile = (uploadedResumeFile) => ({
  type: 'SAVE_UPLOADED_RESUME_FILE',
  payload: { uploadedResumeFile },
})

export {
  savePersonalInformationQuestions,
  saveTrumanQuestionsInformation,
  addNewQuestion,
  deleteQuestion,
  changeQuestionExpand,
  saveFullApplyForm,
  adjustFullApplyFormResponse,
  savePersonalQuestionsSubmitRequirements,
  saveSubmitRequirements,
  saveSubmitButtonClicked,
  startFullApplyFormActivate,
  disableFullApplyFormAutosave,
  saveFullApplyFormPersonalData,
  saveSpecificAnswers,
  saveUploadedResumeFile,
}
