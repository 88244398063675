import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import en from './langs/en'
import es from './langs/es'

const resources = {
  en,
  es,
}

const detectionOptions = {
  order: ['querystring', 'localStorage'],
  lookupQuerystring: 'language',
  lookupLocalStorage: 'i18nextLng',
  caches: ['localStorage'],
}

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    fallbackLng: 'en',
    resources,
    interpolation: {
      escapeValue: false,
    },
    detection: detectionOptions,
  })

export default i18n
