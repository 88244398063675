import styled from 'styled-components'
import {Dropdown} from "semantic-ui-react";

export const JobDescriptionQuickApplyContainer = styled.div`
  margin-top: 20px;
`

export const BlockContainer = styled.div`
  margin-bottom: 35px;
`

export const ShareModalButton = styled.span`
  color: #3da9f4;
  font-family: 'Neutraface2TextBold';
  font-size: 18px;
  font-weight: bold;

  &:hover {
    cursor: pointer;
  }
`
export const BrandContainer = styled.div`
  display: flex;
`

export const TranslatorWithContent = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`

export const LanguageDropdown = styled(Dropdown)`
  &&&{
    align-self: flex-end;
    margin: 1rem 4rem;
  }
`
