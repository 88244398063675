import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import * as Styled from 'jobDescriptionFullApplyForm_styled'
import { Accordion, Input } from 'semantic-ui-react'
import AnimateHeight from 'react-animate-height'
import DatePicker, { registerLocale } from "react-datepicker";
import es from 'date-fns/locale/es';
import 'react-datepicker/dist/react-datepicker.css'
import moment from 'moment'
import {
  changeQuestionExpand,
  saveTrumanQuestionsInformation,
  saveSubmitRequirements,
} from '../../../../../../../../actions/jobApplication_actions'

registerLocale('es', es)

class CalendarQuestion extends Component {
  state = {
    inputSuccessCheck: {
      // all the properties here can be only true or false. We put null at beginning in order to not apply statedesign when app first renders
      calendarInput: false,
    },
  }

  componentDidMount() {
    this.checkOptionalConditions()
  }

  componentDidUpdate(prevProps) {
    const { questionData, answers } = this.props

    const checkChangedInputs = () => {
      /* Compare previous state and current for all monitored inputs and if its changed we
      call the function */
      /* -----calendarInput------ */
      const calendarInput =
        JSON.stringify(prevProps.answers[questionData.question_id].date) !==
        JSON.stringify(answers[questionData.question_id].date)

      /* -----calendarInput END------ */

      /* In parenthesis we put all the inputs that could have changed and if any of them has
      we call the function */
      if (calendarInput) {
        this.checkOptionalConditions()
      }
    }

    checkChangedInputs()
  }

  checkOptionalConditions = () => {
    const { questionData, answers, answersRequirements } = this.props

    /* Make conditions when specific inputs are considered true and successfully filled out */
    /* --------------------- */
    let calendarInput = false
    if (answers[questionData.question_id].date) {
      calendarInput = true
    }
    /* --------------------- */

    /* Set new state after checking conditions for the inputs */
    this.setState(
      {
        inputSuccessCheck: {
          ...this.state.inputSuccessCheck,
          calendarInput,
        },
      },
      () => {
        /* For questions that are not optional every input needs to be filled out correctly in order for us to be able to submit. */
        if (answersRequirements[questionData.question_id].optional === false) {
          /* We turn the object into array of boolean values */
          const inputSuccessList = Object.values(this.state.inputSuccessCheck)

          let submitReady = true
          /* If one of the values in the array is false, it means one of the inputs is not filled correctly and we set submitReady to false.
          Until submit ready is true we can not submit application. */
          for (let i = 0; i < inputSuccessList.length; i += 1) {
            if (inputSuccessList[i] === false) {
              submitReady = false
              break
            }
          }
          /* We save if the question is submitReady or not to redux store */
          this.props.saveSubmitRequirements(
            questionData.question_id,
            submitReady
          )
        }
        /* If question is optional inputs don't need to be filled out in order for us to submit so we set submitReady to true in redux store */
        if (answersRequirements[questionData.question_id].optional === true) {
          this.props.saveSubmitRequirements(questionData.question_id, true)
        }
      }
    )
  }

  handleInputChange = (question_id, property, value) => {
    const propertyValue = {
      [property]: value === 'Invalid date' ? null : value,
    }
    this.props.saveTrumanQuestionsInformation(question_id, propertyValue)
  }

  handleQuestionExpand = (questionType) => {
    const { questionData } = this.props
    this.props.changeQuestionExpand({
      questionType,
      id: questionData.question_id,
    })
  }

  handleDateChangeRaw = (e) => {
    /* We use this for stopping user from using keyboard or pasting data in input field so we don't
    get an error */
    e.preventDefault()
  }

  checkQuestionDesign(property) {
    const {
      firstTimeSubmitButtonClicked,
      answersRequirements,
      questionData,
    } = this.props
    const { inputSuccessCheck } = this.state

    /* Depending if input or some other element on the page is filled out correctly, wrongly or nothing
    we change design */

    let warning
    let success
    let neutral

    const returnDesignPattern = () => {
      if (warning) {
        return warning
      }

      if (success) {
        return success
      }

      return neutral
    }

    /* Checks for which design we are gonna use on which element */
    /* ---------------------------------------------- */

    const checkInputWarning = (name) => {
      /* Warning input design can only show up after we clicked the submit button, if
      the question is not optional and input is not filled out correctly */
      if (
        firstTimeSubmitButtonClicked &&
        !answersRequirements[questionData.question_id].optional &&
        inputSuccessCheck[name] === false
      ) {
        return 'warning'
      }
    }

    const checkInputSuccess = (name) => {
      /* We show success input design before we hit submit button for the first
      time and after also so the only condition is that input is filled out correctly. */
      if (inputSuccessCheck[name] === true) {
        return 'success'
      }
    }

    /* We show warning design only after submit button is clicked for the first TimeRanges,
    if question is not optional and if one of the inputs is not filled out correctly */
    const checkQuestionContainerWarning = () => {
      if (
        firstTimeSubmitButtonClicked &&
        !answersRequirements[questionData.question_id].optional &&
        Object.keys(inputSuccessCheck).some((k) => !inputSuccessCheck[k]) // check if any of the property in inputSuccessCheck is false
      ) {
        return 'warning'
      }
    }

    /* Check the whole question container design. If all the inputs are filled out correctly we
    show success design */

    const checkQuestionContainerSuccess = () => {
      if (
        Object.keys(inputSuccessCheck).every(
          (k) => inputSuccessCheck[k] === true
        )
      ) {
        return 'success'
      }
    }
    /* ---------------------------------------------- */

    /* based on the input that we are checking we are passing that property to check design */
    switch (property) {
      case 'calendarInput':
        warning = checkInputWarning(property)
        success = checkInputSuccess(property)
        neutral = 'neutral'

        return returnDesignPattern()

      case 'questionContainer':
        warning = checkQuestionContainerWarning()
        success = checkQuestionContainerSuccess()
        neutral = 'neutral'

        return returnDesignPattern()
      default:
        return returnDesignPattern()
    }
  }

  checkQuestionExpandState() {
    const { questionExpand, questionData } = this.props
    let questionState = false
    if (questionExpand[this.props.questionType].length > 0) {
      const filteredQuestion = questionExpand[this.props.questionType].filter(
        (question) => {
          if (question.id === questionData.question_id) {
            return question
          }
        }
      )
      questionState = filteredQuestion[0].expand
    }
    return questionState
  }

  renderCalendarQuestions() {
    const { questionData, answers, t, i18n } = this.props
    return (
      <Styled.QuestionBlock>
        <Styled.FormGroup>
          <Styled.FormField>
            <Styled.DatePickerWrapper
              statedesign={this.checkQuestionDesign('calendarInput')}
            >
              <DatePicker
                locale={i18n.language}
                dateFormat="MM/dd/yyyy"
                isClearable
                showYearDropdown
                showPopperArrow={false}
                onChangeRaw={this.handleDateChangeRaw}
                customInput={
                  <Input icon="calendar alternate" iconPosition="left" />
                }
                placeholderText={t('selectDate')}
                onChange={(value) =>
                  this.handleInputChange(
                    questionData.question_id,
                    'date',
                    moment(value).format()
                  )
                }
                selected={
                  (answers[questionData.question_id] &&
                    answers[questionData.question_id].date &&
                    new Date(answers[questionData.question_id].date)) ||
                  null
                }
              />
            </Styled.DatePickerWrapper>
          </Styled.FormField>
        </Styled.FormGroup>
      </Styled.QuestionBlock>
    )
  }

  render() {
    const { questionData, answersRequirements, t } = this.props
    return (
      <Styled.QuestionContainer
        statedesign={this.checkQuestionDesign('questionContainer')}
      >
        <Accordion>
          <Styled.AccordionTitle
            active={this.checkQuestionExpandState()}
            onClick={() => this.handleQuestionExpand(this.props.questionType)}
          >
            <Styled.AccordionTitleTextContainer>
              <Styled.QuestionIconBorder>
                <Styled.QuestionIcon name="calendar alternate" />{' '}
              </Styled.QuestionIconBorder>

              <Styled.AccordionTitleText>
                {questionData.question_text}
                <br />
                {answersRequirements[questionData.question_id].optional ? (
                  <span>({t('optional')})</span>
                ) : null}
              </Styled.AccordionTitleText>
            </Styled.AccordionTitleTextContainer>

            {this.checkQuestionExpandState() ? (
              <Styled.ExpandIcon name="chevron circle up" />
            ) : (
              <Styled.ExpandIcon name="chevron circle down" />
            )}
          </Styled.AccordionTitle>
          <Styled.AccordionContent active={this.checkQuestionExpandState()}>
            <AnimateHeight
              animateOpacity
              duration={300}
              height={this.checkQuestionExpandState() ? 'auto' : 0}
            >
              {this.renderCalendarQuestions()}
            </AnimateHeight>
          </Styled.AccordionContent>
        </Accordion>
      </Styled.QuestionContainer>
    )
  }
}

const mapStateToProps = (state) => ({
  answers: state.jobApplicationReducer.answers,
  answersRequirements:
    state.jobApplicationReducer.submitRequirements.answersRequirements,
  firstTimeSubmitButtonClicked:
    state.jobApplicationReducer.submitRequirements.firstTimeSubmitButtonClicked,
  questionExpand: state.jobApplicationReducer.questionExpand,
})

CalendarQuestion.propTypes = {
  answers: PropTypes.object, // comes from parent 'jobApplicationReducer'. Contains all the answers for the questions coming from truman, type of the question and id.
  answersRequirements: PropTypes.object, // comes from parent 'jobApplicationReducer'. Contains if questions coming from truman are optional or not. It also contains submitReady property that tells us if specific question is filled out ok.
  changeQuestionExpand: PropTypes.func, // used to expand or collapse question accordion.
  firstTimeSubmitButtonClicked: PropTypes.bool, // if true it means that "Submit" form button was clicked at least once.
  questionData: PropTypes.object, // passed down from parent 'JobDescriptionFullApplyFormQuestions' component. It contains all the data about that specific question coming from truman.
  questionExpand: PropTypes.object, // contains all the questions and tells if question accordion is expanded or not.
  questionType: PropTypes.string, // passed down from parent 'JobDescriptionFullApplyFormQuestions' component. It says which questionType question belongs to.
  saveSubmitRequirements: PropTypes.func, // Used to save if the question is submitReady or not to redux store.
  saveTrumanQuestionsInformation: PropTypes.func, // used to save user answers to redux store for every question.
}

export default connect(mapStateToProps, {
  changeQuestionExpand,
  saveTrumanQuestionsInformation,
  saveSubmitRequirements,
})(withTranslation('jobDescriptionFullApply')(CalendarQuestion))
