import React from 'react'
import PropTypes from 'prop-types'
import LpnRnVerification from 'LpnRnVerification'
import CNAVerification from './components/CNAVerification'

const LicenseVerification = (props) => {
  const component = {
    nursysapi: (
      <LpnRnVerification
        verificationType={props.verificationType}
        verificationData={props.verificationData}
        changeActiveStep={props.changeActiveStep}
        longStateName={props.longStateName}
      />
    ),
    svapi: (
      <CNAVerification
        verificationType={props.verificationType}
        verificationData={props.verificationData}
        changeActiveStep={props.changeActiveStep}
        longStateName={props.longStateName}
        lastFourSsnEnabled={props.lastFourSsnEnabled}
      />
    ),
  }

  return component[props.verificationEngine.toLowerCase()] || null
}

LicenseVerification.propTypes = {
  // used to change activeStep which changes the verification screen
  changeActiveStep: PropTypes.func,
  // data extracted from URL string
  verificationData: PropTypes.object,
  // lets us know which verification type we are one (LPN, RN...)
  verificationType: PropTypes.string,
  // full name of the state
  longStateName: PropTypes.string,
}

export default LicenseVerification
