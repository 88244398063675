import styled from 'styled-components'

export const SearchContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`

export const JobsContainer = styled.div`
  width: 100%;
`
