import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { Grid } from 'semantic-ui-react'
import ReadMoreAndLess from 'react-read-more-less'
import Scroll from 'react-scroll'
/* Styled Components */
import * as Styled from 'companyProfileBody_styled'
import CompanyProfileImageGallery from 'CompanyProfileImageGallery'
import CompanyProfileJobs from 'CompanyProfileJobs'
import CompanyProfileVideo from 'CompanyProfileVideo'
import ErrorBoundaryWrapper from '../../../sharedComponents/errorPages/ErrorBoundary'

const CompanyProfileJobsScroll = Scroll.Element
const CompanyProfileDescriptionScroll = Scroll.Element
const CompanyProfileSkillsScroll = Scroll.Element
const CompanyProfilePhotosScroll = Scroll.Element
const CompanyProfileVideosScroll = Scroll.Element

export class CompanyProfileBody extends Component {
  renderSkills() {
    const { companyProfile } = this.props
    return companyProfile.skills.map((skill) => (
      <Styled.SkillWrapper key={skill} style={{ display: 'inline-flex' }}>
        {skill}
      </Styled.SkillWrapper>
    ))
  }

  render() {
    const {
      companyProfile,
      companyProfileJobs,
      allTeamsSearch,
      backfilledJobs,
      debug,
      updateJobs,
      utm,
    } = this.props
    return (
      <Grid.Row columns={2}>
        {!allTeamsSearch && (
          <Grid.Column tablet={7}>
            <Styled.BlockContainer>
              <CompanyProfileDescriptionScroll name="companyProfileDescriptionScroll" />
              {companyProfile && companyProfile.description ? (
                <Fragment>
                  <Styled.CompanyProfileTagline>
                    {companyProfile.tagline}
                  </Styled.CompanyProfileTagline>
                  <ReadMoreAndLess
                    id="read-more-and-less"
                    charLimit={250}
                    readMoreText=" Read more"
                    readLessText=" Read less"
                  >
                    {companyProfile.description}
                  </ReadMoreAndLess>
                </Fragment>
              ) : null}
            </Styled.BlockContainer>

            <Styled.BlockContainer>
              <ErrorBoundaryWrapper
                tags={{ component: 'CompanyProfileSkills' }}
                sectionName="Skills"
              >
                <CompanyProfileSkillsScroll name="companyProfileSkillsScroll" />
                {companyProfile &&
                 companyProfile.skills &&
                 companyProfile.skills.length > 0 ? (
                  <Styled.SkillsContainer>
                    <h4>SKILLS</h4>
                    {this.renderSkills()}
                  </Styled.SkillsContainer>
                ) : null}
              </ErrorBoundaryWrapper>
            </Styled.BlockContainer>

            <Styled.BlockContainer>
              <ErrorBoundaryWrapper
                tags={{ component: 'CompanyProfilePhotos' }}
                sectionName="Company Photos"
              >
                <CompanyProfilePhotosScroll name="companyProfilePhotosScroll" />
                <CompanyProfileImageGallery />
              </ErrorBoundaryWrapper>
            </Styled.BlockContainer>
            <Styled.BlockContainer>
              <ErrorBoundaryWrapper
                tags={{ component: 'CompanyProfileVideo' }}
                sectionName="Company Videos"
              >
                <CompanyProfileVideosScroll name="companyProfileVideosScroll" />
                <CompanyProfileVideo />
              </ErrorBoundaryWrapper>
            </Styled.BlockContainer>
          </Grid.Column>
        )}
        <Grid.Column tablet={9}>
          <ErrorBoundaryWrapper
            tags={{ component: 'CompanyProfileJobs' }}
            sectionName="Company Jobs"
          >
            <CompanyProfileJobsScroll name="companyProfileJobsScroll" />
            {companyProfileJobs && companyProfileJobs.length >= 0 && (
              <CompanyProfileJobs
                allTeamsSearch={allTeamsSearch}
                backfilledJobs={backfilledJobs}
                debug={debug}
                updateJobs={updateJobs}
                utm={utm}
              />
            )}
          </ErrorBoundaryWrapper>
        </Grid.Column>
      </Grid.Row>
    )
  }
}

const mapStateToProps = (state) => ({
  companyProfile: state.companyProfileReducer.companyProfile,
  companyProfileJobs: state.companyProfileReducer.companyProfileJobs,
})

export default connect(mapStateToProps, {})(CompanyProfileBody)
