import styled from 'styled-components'
import { Icon } from 'semantic-ui-react'

export const FileInputContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  font-family: 'Neutraface2Text';
  margin-bottom: 16px;
  text-align: left;
  label {
    input {
      display: none;
    }
  }
`

export const FileInputContainerSpinner = styled.div`
  margin-left: 45px;
`

export const FileInputText = styled.div`
  &:hover {
    cursor: pointer;
  }
`
export const UploadResumeIcon = styled(Icon)`
  color: ${(props) => {
    if (props.customcolor === 'warning') {
      return '#ea4750'
    }
    return 'rgba(0, 0, 0, 0.87)'
  }};
`

export const UploadResumeText = styled.span`
  color: ${(props) => {
    if (props.customcolor === 'warning') {
      return '#ea4750'
    }
    return 'rgba(0, 0, 0, 0.87)'
  }};
`

export const UploadResumeOptionalText = styled.div`
  text-align: center;
`

export const UploadResumeWarning = styled.div`
  color: #ea4750;
  margin: 5px 20px;
`
