import React from 'react'
import PropTypes from 'prop-types'
import { CSSTransition } from 'react-transition-group'
import * as Styled from 'verificationSuccess_styled'
import { useTranslation } from 'react-i18next'
import SuccessShieldIcon from '../../../../../../../../../public/assets/svg/SuccessShieldIcon'
import CustomLinkButton from '../CustomLinkButton/CustomLinkButton'

const NursysVerificationSuccess = (props) => {
  const { verificationType, verificationData } = props

  const texts = {
    icon: <SuccessShieldIcon />,
    titleText: `${verificationType}TitleText`,
    descriptionText: `${verificationType}DescriptionText`,
    buttonText: `${verificationType}ButtonText`,
  }

  const { t } = useTranslation(['verificationSuccess', 'verification'])

  const {
    icon, titleText, descriptionText, buttonText,
  } = texts

  const fromEmployeeProfile = verificationData.source === 'employee_profile'

  return (
    <Styled.VerificationSuccessContainer data-testid="verification-success">
      <Styled.IconContainer>
        <CSSTransition timeout={1500} classNames="status-icon-animation" in={!!icon}>
          <SuccessShieldIcon />
        </CSSTransition>
      </Styled.IconContainer>

      <Styled.TitleText>{t(titleText)}</Styled.TitleText>

      <Styled.DescriptionText>
        {t(descriptionText, { state: props.longStateName })}
      </Styled.DescriptionText>

      <Styled.ButtonContainer>
        <CustomLinkButton
          external={fromEmployeeProfile}
          url={
            fromEmployeeProfile ?
              `${window.hiringURL}/employees/profile/${verificationData.employee_id}` :
              `/profile/${verificationData.company_profile}`
          }
          text={fromEmployeeProfile ? t('verification:backToEmployee') : t(buttonText)}
        />
      </Styled.ButtonContainer>
    </Styled.VerificationSuccessContainer>
  )
}

NursysVerificationSuccess.propTypes = {
  // lets us know which verification type we are one (LPN, RN...)
  verificationType: PropTypes.string,
  // data extracted from URL string
  verificationData: PropTypes.object,
  // full name of the state
  longStateName: PropTypes.string,
}

export default NursysVerificationSuccess
