import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import * as Styled from 'personalQuestionsResumeUpload_styled'
import { withTranslation } from 'react-i18next'
import { savePersonalQuestionsSubmitRequirements } from '../../../../../../../actions/jobApplication_actions'
import {
  openTimeoutMessageModal,
  openTimeoutImageModal,
} from '../../../../../../../actions/modals_actions'
import { postFullApplyFormResumeAction } from '../../../../../../../actions/api_actions'
import spinner from '../../../../../../../../../public/assets/img/spinner.gif'

class PersonalQuestionsResumeUpload extends Component {
  state = {
    questionType: 'resumeUpload',
    showSpinner: false,
  }

  componentDidUpdate(prevProps) {
    /* has_resume property tells us if user has uploaded some time of resume at any point */
    if (
      this.props.personQuestion &&
      prevProps.personQuestion.has_resume !==
        this.props.personQuestion.has_resume
    ) {
      if (!this.props.jobDescription.job_detail?.resume_required) {
        const submitReady = true
        this.props.savePersonalQuestionsSubmitRequirements(
          this.state.questionType,
          submitReady
        )
      } else {
        const submitReady = this.props.personQuestion.has_resume
        this.props.savePersonalQuestionsSubmitRequirements(
          this.state.questionType,
          submitReady
        )
      }
    }
  }

  checkResumeUploadState = () => {
    /* We only show warning if: submit button was clicked, if feature toggle is enabled and user still didn't upload any resume */
    if (
      this.props.submitRequirements.firstTimeSubmitButtonClicked === true &&
      this.props.jobDescription.job_detail?.resume_required &&
      this.props.personQuestion.has_resume === false
    ) {
      return 'warning'
    }
  }

  handleFileChange = (e) => {
    const file = e.target.files[0]
    const { t } = this.props
    /* match .pdf, .docx, .doc */
    if (
      file.type.match(
        /application\/(pdf|vnd.openxmlformats-officedocument.wordprocessingml.document|msword)$/
      )
    ) {
      const formFile = new FormData()
      formFile.append('file', file)

      this.setState({
        showSpinner: true,
      })

      this.props
        /* Here we process uploaded resume, save it to database and we get back response with data */
        .postFullApplyFormResumeAction(
          window.activeEnvironment,
          formFile,
          this.props.applicant.token
        )
        .then((response) => {
          this.setState(
            {
              showSpinner: false,
            },
            () => {
              this.props.openTimeoutMessageModal({
                headerMessage: t('uploadCompleteTitle'),
                bodyMessage:
                t('uploadCompleteText'),
                footerMessage: null,
                timeout: 10000,
                pageReload: false,
              })
            }
          )
        })
        .catch((error) => {
          this.setState(
            {
              showSpinner: false,
            },
            () => {
              this.props.openTimeoutImageModal({
                image: 'error',
                bodyMessage:
                  (error && error.user_message) ||
                  t('uploadError1'),
                timeout: 3000,
                pageReload: false,
                redirect: false,
                removeCloseModalOptions: false,
              })
            }
          )
        })
    } else {
      this.props.openTimeoutMessageModal({
        headerMessage: 'ERROR',
        bodyMessage:
          t('uploadError2'),
        footerMessage: null,
        timeout: null,
        pageReload: false,
      })
    }
  }

  render() {
    const { t } = this.props
    return (
      <Styled.FileInputContainer>
        <div>
          {this.state.showSpinner ? (
            <Styled.FileInputContainerSpinner>
              <img src={spinner} width="50" />
            </Styled.FileInputContainerSpinner>
          ) : (
            <Fragment>
              <label htmlFor="fileInput">
                <div>
                  <Styled.FileInputText>
                    <Styled.UploadResumeIcon
                      name="attach"
                      flipped="horizontally"
                      customcolor={this.checkResumeUploadState()}
                    />
                    <Styled.UploadResumeText
                      customcolor={this.checkResumeUploadState()}
                    >
                      {t('uploadResume')}
                    </Styled.UploadResumeText>
                  </Styled.FileInputText>
                  {/* show "optional" text if resume upload is not required */}
                  {!this.props.jobDescription.job_detail?.resume_required && (
                    <Styled.UploadResumeOptionalText>
                      {t('uploadResumeOptional')}
                    </Styled.UploadResumeOptionalText>
                  )}
                </div>

                <input
                  type="file"
                  name="myFile"
                  onChange={(e) => this.handleFileChange(e)}
                  id="fileInput"
                  accept=".pdf, .docx, .doc"
                />
              </label>
              {/* show warning explanation message if resume not uploaded but required */}
              {this.checkResumeUploadState() === 'warning' ? (
                <Styled.UploadResumeWarning>
                  {t('uploadResumeWarning')}
                </Styled.UploadResumeWarning>
              ) : null}
            </Fragment>
          )}
        </div>
      </Styled.FileInputContainer>
    )
  }
}

const mapStateToProps = (state) => ({
  jobDescription: state.jobDescriptionReducer.jobDescription,
  applicant: state.authenticationReducer.applicant,
  personQuestion:
    state.jobApplicationReducer.fullApplicationPersonalQuestions.personQuestion,
  submitRequirements: state.jobApplicationReducer.submitRequirements,
})

PersonalQuestionsResumeUpload.propTypes = {
  jobDescription: PropTypes.object, // comes from jobDescriptionReducer. Contains data about current active job.
  applicant: PropTypes.object, // comes from authenticationReducer. Contains data about the current logged in user.
  openTimeoutImageModal: PropTypes.func, // used to open TimeoutImageModal.
  openTimeoutMessageModal: PropTypes.func, // used to open TimeoutMessageModal.
  postFullApplyFormResumeAction: PropTypes.func, // used to post uploaded resume file. Thre response that we get back we save to database API.
  savePersonalQuestionsSubmitRequirements: PropTypes.func, // used to save if personal questions are submit ready
  personQuestion: PropTypes.object, // saved inputs data that user filled out
  submitRequirements: PropTypes.object, // tells us if specific groups of questions are ready to be submitted or not
}

export default connect(mapStateToProps, {
  openTimeoutMessageModal,
  openTimeoutImageModal,
  postFullApplyFormResumeAction,
  savePersonalQuestionsSubmitRequirements,
})(withTranslation('jobDescriptionFullApply')(PersonalQuestionsResumeUpload))
