// Inside of global_styled we put global styles for example "html", "body"

// **Hot reloading will not work for global StyleSheet. We need to refresh the page to see changes**

import { createGlobalStyle } from 'styled-components'
import Neutraface from '../../../public/assets/fonts/NeutraDisplay-Medium.otf'
import NeutrafaceBold from '../../../public/assets/fonts/NeutraDisplay-Bold.otf'
import NeutrafaceLight from '../../../public/assets/fonts/NeutraDisplay-Light.otf'
import Neutraface2TextLight from '../../../public/assets/fonts/Neutra2Text-Light.otf'
import Neutraface2TextBold from '../../../public/assets/fonts/Neutra2Text-Bold.otf'
import Neutraface2TextDemi from '../../../public/assets/fonts/Neutra2Text-Demi.otf'
import Neutraface2TextBook from '../../../public/assets/fonts/Neutra2Text-Book.otf'
import OpenSansRegular from '../../../public/assets/fonts/OpenSans-Regular.ttf'
import OpenSansBold from '../../../public/assets/fonts/OpenSans-Bold.ttf'
import OpenSansSemiBold from '../../../public/assets/fonts/OpenSans-SemiBold.ttf'

const GlobalStyled = createGlobalStyle`
  html {
    @font-face {
      font-family: "Neutraface";
      src: url('${Neutraface}') format('opentype');
    }
    @font-face {
      font-family: "NeutrafaceBold";
      src: url('${NeutrafaceBold}') format('opentype');
    }
    @font-face {
      font-family: "NeutrafaceLight";
      src: url('${NeutrafaceLight}') format('opentype');
    }
    @font-face {
      font-family: "Neutraface2TextLight";
      src: url('${Neutraface2TextLight}') format('opentype');
    }
    @font-face {
      font-family: "Neutraface2TextBold";
      src: url('${Neutraface2TextBold}') format('opentype');
    }
    @font-face {
      font-family: "Neutraface2TextDemi";
      src: url('${Neutraface2TextDemi}') format('opentype');
    }
    @font-face {
      font-family: "Neutraface2TextBook";
      src: url('${Neutraface2TextBook}') format('opentype');
    }
    @font-face {
      font-family: "OpenSansRegular";
      src: url('${OpenSansRegular}') format('truetype');
    }
    @font-face {
      font-family: "OpenSansBold";
      src: url('${OpenSansBold}') format('truetype');
    }
    @font-face {
      font-family: "OpenSansSemiBold";
      src: url('${OpenSansSemiBold}') format('truetype');
    }
  }
  h3, h4 {
    font-family: "NeutrafaceBold" !important;
  }
`
export default GlobalStyled
