import React from 'react'
import { connect } from 'react-redux'
import { Grid, Image } from 'semantic-ui-react'
import Scroll from 'react-scroll'
/* Styled Components */
import * as Styled from 'companyProfileHeader_styled'
/* Images */
import companyProfileBanner from '../../../../../../public/assets/img/company_profile_banner.png'
import companyProfileLogoPlaceholder from '../../../../../../public/assets/img/company_profile_logo_placeholder.png'

const { scroller } = Scroll

const CompanyProfileHeader = (props) => (
  <Styled.GridRow columns={2} reversed="tablet computer">
    <Grid.Column computer={13} tablet={11} stretched>
      {/* Background image */}
      {/* If we get external link from background image we show it, if not we show a default one so the page grid doesn't get messed up */}

      <Styled.BannerImageContainer
        link={
          props.companyProfile &&
          props.companyProfile.background_img &&
          props.companyProfile.background_img.external_link
            ? props.companyProfile.background_img.external_link
            : companyProfileBanner
        }
      >
        <Styled.BannerImage
          src={
            props.companyProfile
              && props.companyProfile.background_img
              && props.companyProfile.background_img.external_link
              ? props.companyProfile.background_img.external_link
              : companyProfileBanner
          }
          alt="Banner image"
        />
      </Styled.BannerImageContainer>

      {/* Logo - shows up on screen until maxWidth of 767 */}
      <Styled.MobileDisplay>
        {props.companyProfile && props.companyProfile.logo_url && (
          <Styled.MobileLogoImage
            src={
              props.companyProfile && props.companyProfile.logo_url
                ? props.companyProfile.logo_url
                : companyProfileLogoPlaceholder
            }
          />
        )}
        <Styled.JobsButton
          onClick={() =>
            scroller.scrollTo('companyProfileJobsScroll', {
              duration: 1000,
              delay: 50,
              smooth: true,
              offset: 50,
            })
          }
          customcolor={
            props.companyProfile && props.companyProfile.primary_color
              ? props.companyProfile.primary_color
              : '#000'
          }
        >
          View Jobs
        </Styled.JobsButton>
      </Styled.MobileDisplay>
    </Grid.Column>

    <Grid.Column computer={3} tablet={5}>
      {/* Logo - shows up on screen from minWidth of 768 */}
      {props.companyProfile && props.companyProfile.logo_url && (
        <Styled.DesktopDisplay>
          <Styled.LogoImage
            src={
              props.companyProfile && props.companyProfile.logo_url
                ? props.companyProfile.logo_url
                : companyProfileLogoPlaceholder
            }
          />
        </Styled.DesktopDisplay>
      )}

      {props.companyProfile && props.companyProfile.industry && (
        <Styled.InfoText>
          <span>Industry:</span> <br />
          <span>
            {props.companyProfile && props.companyProfile.industry
              ? props.companyProfile.industry
              : '-'}
          </span>
        </Styled.InfoText>
      )}

      {props.companyProfile && props.companyProfile.primary_location && (
        <Styled.InfoText>
          <span>Location:</span>
          <br />
          <span>
            {props.companyProfile && props.companyProfile.primary_location
              ? props.companyProfile.primary_location
              : '-'}
          </span>
        </Styled.InfoText>
      )}

      {props.companyProfile && props.companyProfile.website_link && (
        <Styled.InfoText>
          <span>
            <a
              href={props.companyProfile.website_link}
              target="_blank"
              rel="noopener noreferrer"
            >
              Company Website
            </a>
          </span>
        </Styled.InfoText>
      )}

      {/* first we check if there is any of the social links so we can render InfoText
          element and then we check each one individually */}
      {props.companyProfile &&
        (props.companyProfile.facebook_link ||
          props.companyProfile.twitter_link ||
          props.companyProfile.instagram_link ||
          props.companyProfile.linkedin_link) && (
          <Styled.InfoText>
            <span>
              {props.companyProfile && props.companyProfile.facebook_link && (
                <a
                  href={props.companyProfile.facebook_link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Styled.SocialIconImage name="facebook f" />
                </a>
              )}
              {props.companyProfile && props.companyProfile.twitter_link && (
                <a
                  href={props.companyProfile.twitter_link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Styled.SocialIconImage name="twitter" />
                </a>
              )}
              {props.companyProfile && props.companyProfile.instagram_link && (
                <a
                  href={props.companyProfile.instagram_link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Styled.SocialIconImage name="instagram" />
                </a>
              )}
              {props.companyProfile && props.companyProfile.linkedin_link && (
                <a
                  href={props.companyProfile.linkedin_link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Styled.SocialIconImage name="linkedin" />
                </a>
              )}
            </span>
          </Styled.InfoText>
        )}
    </Grid.Column>
  </Styled.GridRow>
)

const mapStateToProps = (state) => ({
  companyProfile: state.companyProfileReducer.companyProfile,
  shortenUrl: state.companyProfileReducer.shortenUrl,
})

export default connect(mapStateToProps, {})(CompanyProfileHeader)
