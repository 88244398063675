/* This is container for all route components. Put styles in routeComponents_styled that should be used on all route components. */

import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { renderRoutes } from 'react-router-config'
import Cookies from 'universal-cookie'

import * as Styled from 'routeComponents_styled'
import { getAppliedJobsListAction } from '../../actions/api_actions'
import ErrorBoundaryWrapper from '../sharedComponents/errorPages/ErrorBoundary'

const cookies = new Cookies()
let cookieOptions = { secure: true, sameSite: 'strict' }

class RouteComponents extends Component {
  componentDidUpdate(prevProps) {
    /* If there is something in localStorage we save it to redux in this.componentDidUpdate inside JobDescription component. This will trigger
    componentDidUpdate here and we will call getAppliedJobsListAction. This will also be triggered if we login/register
    without reloading the page because we save the data to redux store only if prevProps was null.
    It will become null everytime we log out from application. That means that it gets triggered whenever someone register/login.
    Since user can login on any route, for example while being on company profile, we call getAppliedJobsListAction in
    RouteComponents component since it is top level and it will be triggered on any route */
    if (prevProps.applicant === null && this.props.applicant) {
      this.props.getAppliedJobsListAction(
        window.activeEnvironment,
        this.props.globalReducer.matchData.params.slug,
        this.props.applicant.value.id,
        this.props.interviewGuide,
        this.props.applicant.token,
      )
      /* Set cookie with user_id so we can use cookie data on server for SSR API call */
      cookies.set('user_id', this.props.applicant.value.id, cookieOptions);
    }

    /* Since id that we get from when user registers/login, person_id from easyApplyApplicant is only updated if applicant === null.
    That means that user hasn't logged in/register yet.
    Since we can apply on quick apply form with different email address, we also need to call this again if easyApplyApplicant property
    doesn't have the same value. */
    if (
      this.props.applicant === null &&
      JSON.stringify(prevProps.easyApplyApplicant) !==
        JSON.stringify(this.props.easyApplyApplicant)
    ) {
      this.props.getAppliedJobsListAction(
        window.activeEnvironment,
        this.props.globalReducer.matchData.params.slug,
        this.props.easyApplyApplicant.value.person_id,
        this.props.interviewGuide
      )
      /* Set cookie with user_id so we can use cookie data on server for SSR API call */
      cookies.set('user_id', this.props.easyApplyApplicant.value.person_id, cookieOptions);
    }
  }

  render() {
    return (
      <Styled.RouteComponentsContainer>
        <ErrorBoundaryWrapper>
        {renderRoutes(this.props.route.routes, {
          interviewGuide: this.props.interviewGuide, // send if interviewGuide is enabled to top level components
        })}
        </ErrorBoundaryWrapper>
      </Styled.RouteComponentsContainer>
    )
  }
}

const mapStateToProps = (state) => ({
  applicant: state.authenticationReducer.applicant,
  easyApplyApplicant: state.authenticationReducer.easyApplyApplicant,
  globalReducer: state.globalReducer,
})

RouteComponents.propTypes = {
  applicant: PropTypes.object, // comes from authenticationReducer. Contains data about the current logged in user.
  easyApplyApplicant: PropTypes.object, // data from local storage saved to redux store about user that did at least one quick apply form submit
  getAppliedJobsListAction: PropTypes.func, // get list of jobs that user applied to. This is for logged in users and users that .made at least one quick apply.
  globalReducer: PropTypes.object, // contains location, matchData and routeData that are saved when first component is loaded on every new route. That data is coming from react-router.
  interviewGuide: PropTypes.bool, // tells us if we should show resume.
  route: PropTypes.object, // contains routes array with the list of all the routes in application
}

export default connect(mapStateToProps, { getAppliedJobsListAction })(
  RouteComponents
)
