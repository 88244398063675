import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Modal } from 'semantic-ui-react'
import { withTranslation } from 'react-i18next'
import Cookies from 'universal-cookie'
import * as Styled from 'authorizationModals_styled'
import { closeSignOutModal } from '../../../../actions/modals_actions'
import spinner from '../../../../../../public/assets/img/spinner.gif'

class SignOutModal extends Component {
  state = {
    /* spinner */
    showSpinner: false,
  }

  handleSignOut = () => {
    this.setState({
      showSpinner: true,
    })
    /* When user sings out we delete local storage data that we saved during registration/login
    and we reload the page to empty redux store where we also saved it. At that point
    componentDidMount triggers again and updates accountName */
    localStorage.removeItem('applicant')
    localStorage.removeItem('token')

    /* If user came from the Truman link and has 'interview_guide' property in URL we remove it on Sign out */
    let removeInterviewGuideUrl = this.props.location.href.replace(
      /\?interview_guide=1|\?interview_guide=0|interview_guide=1|interview_guide=0/,
      ''
    )

    if (
      removeInterviewGuideUrl.charAt(removeInterviewGuideUrl.length - 1) === '&'
    ) {
      removeInterviewGuideUrl = removeInterviewGuideUrl.slice(0, -1)
    }

    const cookies = new Cookies()
    cookies.remove('user_id')

    window.location.href = removeInterviewGuideUrl
  }

  render() {
    const { t } = this.props
    return (
      <Styled.ModalContainer
        size="mini"
        open
        onClose={this.props.closeSignOutModal}
        closeIcon
      >
        <Styled.ModalContent>
          <Modal.Description>
            <Styled.ModalHeader>
              {t('signOutConfirmation')}
            </Styled.ModalHeader>

            {this.state.showSpinner ? (
              <img src={spinner} width="50" alt="spinner" />
            ) : (
              <Fragment>
                <Styled.ButtonModal
                  authentication="warning"
                  onClick={() => this.handleSignOut()}
                >
                  {t('signOutOk')}
                </Styled.ButtonModal>

                <Styled.ButtonModal
                  authentication="neutral"
                  onClick={() => this.props.closeSignOutModal()}
                >
                  {t('signOutCancel')}
                </Styled.ButtonModal>
              </Fragment>
            )}
          </Modal.Description>
        </Styled.ModalContent>
      </Styled.ModalContainer>
    )
  }
}

const mapStateToProps = (state) => ({
  companyProfile: state.companyProfileReducer.companyProfile,
  location: state.globalReducer.location,
})

SignOutModal.propTypes = {
  closeSignOutModal: PropTypes.func,
  location: PropTypes.object,
}

export default connect(mapStateToProps, { closeSignOutModal })(withTranslation('loginModal')(SignOutModal))
