import styled from 'styled-components'
import { Icon, Grid } from 'semantic-ui-react'

export const JobDescriptionCompanyDataContainer = styled.div``

export const InfoText = styled.div`
  display: inline-block

  span:first-of-type {
    font-family: 'NeutrafaceBold';
    font-size: 15px;
  }

  span:nth-of-type(2) {
    font-size: 14px;
    word-break: break-word;
  }

  span:nth-of-type(1) a {
    color: #1077e2 !important;
  }

  @media (max-width: 767px) {
    text-align: center;
  }
`

export const SocialIconImage = styled(Icon)`
  color: #006cdf;
  margin-right: 5px !important;
  &:hover {
    cursor: pointer;
    transform: scale(1.3);
  }
`

export const DataRow = styled.div`
  display: flex;
  font-size: 16px;
  margin-bottom: 15px;

  span:nth-of-type(1) {
    font-family: 'OpenSansBold';
    padding-right: 5px;
  }
  span:nth-of-type(2) {
    font-family: 'OpenSansRegular';
  }
`

export const SectionHeader = styled.div`
  font-family: 'Neutraface2TextBold';
  font-size: 18px;
  margin-bottom: 20px;

  @media (min-width: 768px) {
    font-size: 24px;
  }
`
