import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import * as Styled from 'jobDescriptionFullApplyForm_styled'
import { Accordion } from 'semantic-ui-react'
import AnimateHeight from 'react-animate-height'
import {
  changeQuestionExpand,
  saveTrumanQuestionsInformation,
  saveSubmitRequirements,
} from '../../../../../../../../actions/jobApplication_actions'

let ZiggeoRecorderImport
let ZiggeoPlayerImport

class VideoQuestion extends Component {
  state = {
    inputSuccessCheck: {
      // all the properties here can be only true or false. We put null at beginning in order to not apply statedesign when app first renders
      videoInput: false,
    },
  }

  componentDidMount() {
    /* We do this because there is some porblem with Ziggeo where it uses 'self' (this/window) before it is available */
    if (typeof window !== 'undefined') {
      const { ZiggeoRecorder, ZiggeoPlayer } = require('react-ziggeo')
      ZiggeoRecorderImport = ZiggeoRecorder
      ZiggeoPlayerImport = ZiggeoPlayer
    }
    this.checkOptionalConditions()
  }

  componentDidUpdate(prevProps) {
    const { questionData, answers } = this.props

    const checkChangedInputs = () => {
      /* Compare previous state and current for all monitored inputs and if its changed we
      call the function */
      /* -----videoInput------ */
      const videoInput =
        JSON.stringify(
          prevProps.answers[questionData.question_id].uploaded_file
            .external_link
        ) !==
        JSON.stringify(
          answers[questionData.question_id].uploaded_file.external_link
        )

      /* -----videoInput END------ */

      if (videoInput) {
        /* Even though we save it on separate endpoint we still need to check if it's submit ready when clicking submit button */
        this.checkOptionalConditions()
      }
    }

    checkChangedInputs()
  }

  handleQuestionExpand = (questionType) => {
    const { questionData } = this.props
    this.props.changeQuestionExpand({
      questionType,
      id: questionData.question_id,
    })
  }

  checkOptionalConditions = () => {
    const { questionData, answers, answersRequirements } = this.props

    /* Make conditions for when specific inputs are considered true and successfully filled out */
    /* --------------------- */
    let videoInput = false
    if (
      answers[questionData.question_id].uploaded_file.external_link &&
      answers[questionData.question_id].uploaded_file.external_link.length > 0
    ) {
      videoInput = true
    }
    /* --------------------- */

    /* Set new state after checking conditions for the inputs */
    this.setState(
      {
        inputSuccessCheck: {
          ...this.state.inputSuccessCheck,
          videoInput,
        },
      },
      () => {
        /* For questions that are not optional every input needs to be filled out correctly in order for us to be able to submit. */
        if (answersRequirements[questionData.question_id].optional === false) {
          /* We turn the object into array of boolean values */
          const inputSuccessList = Object.values(this.state.inputSuccessCheck)

          let submitReady = true
          /* If one of the values in the array is false, it means one of the inputs is not filled correctly and we set submitReady to false.
          Until submit ready is true we can not submit application. */
          for (let i = 0; i < inputSuccessList.length; i += 1) {
            if (inputSuccessList[i] === false) {
              submitReady = false
              break
            }
          }
          /* We save if the question is submitReady or not to redux store */
          this.props.saveSubmitRequirements(
            questionData.question_id,
            submitReady
          )
        }
        /* If question is optional inputs don't need to be filled out in order for us to submit so we set submitReady to true in redux store */
        if (answersRequirements[questionData.question_id].optional === true) {
          this.props.saveSubmitRequirements(questionData.question_id, true)
        }
      }
    )
  }

  reuploadVideo = () => {
    /* This is triggered only when we get back external_link from API and we want to upload new video. When we upload new video we are shown ZiggeoRecorder and not <video/>. At this point if we wanna edit and upload something new the external_link will not changed since the current value us still not saved on API, only on ZiggeoRecorderImport, so no matter how many times we change video, external_link stays the same that Ziggeo gave us. */
    const { questionData } = this.props
    const propertyValue = {
      uploaded_file: {
        external_link: null,
        mime_type: null,
        // id: null,
        // date_created: '',
        // name: '',
        // notes: '',
        // uuid: '',
      },
    }
    this.props.saveTrumanQuestionsInformation(
      questionData.question_id,
      propertyValue
    )
  }

  recorderProcessed = (response) => {
    const { questionData } = this.props

    const uploaded_file = {
      /* If uploading the video again we are only changing external file and the rest of
      properties stay the same for POST request. It's the same on churchill */
      external_link: `https://s3.amazonaws.com/bolivar-media-applicant/ziggeo-${response.video_data.token}.mp4`,
      /* Only .mp4 video */
      mime_type: 'video/mp4',
    }

    const propertyValue = {
      uploaded_file: { ...uploaded_file },
    }

    /* If I will need more properties I just take them from redux store and put them in uploaded_file */
    this.props.saveTrumanQuestionsInformation(
      questionData.question_id,
      propertyValue
    )
  }

  configureTimeLimit = () => {
    const { questionData, t } = this.props

    const limit = questionData.time_limit

    /* Turn seconds to minutes and say 'minute' if it's 1 and 'minutes' if it's more then 1 */
    if (limit > 0) {
      return `${Math.floor(limit / 60)} ${limit === 60 ? t('videoMinute') : t('videoMinutes')}`
    }
    return t('videoNoLimits')
  }

  checkQuestionDesign(property) {
    const {
      firstTimeSubmitButtonClicked,
      answersRequirements,
      questionData,
    } = this.props
    const { inputSuccessCheck } = this.state

    /* Depending if input or some other element on the page is filled out correctly, wrongly or nothing we change design */

    let warning
    let success
    let neutral

    const returnDesignPattern = () => {
      if (warning) {
        return warning
      }

      if (success) {
        return success
      }

      return neutral
    }

    /* Checks for which design we are gonna use on which element */
    /* ---------------------------------------------- */

    /* We show warning design only after submit button is clicked for the first TimeRanges,
    if question is not optional and if one of the inputs is not filled out correctly */
    const checkQuestionContainerWarning = () => {
      if (
        firstTimeSubmitButtonClicked &&
        !answersRequirements[questionData.question_id].optional &&
        Object.keys(inputSuccessCheck).some((k) => !inputSuccessCheck[k]) // check if any of the property in inputSuccessCheck is false
      ) {
        return 'warning'
      }
    }

    /* Check the whole question container design. If all the inputs are filled out correctly we
    show success design */

    const checkQuestionContainerSuccess = () => {
      if (
        Object.keys(inputSuccessCheck).every(
          (k) => inputSuccessCheck[k] === true
        )
      ) {
        return 'success'
      }
    }
    /* ---------------------------------------------- */

    /* based on the input that we are checking we are passing that property to check design */
    switch (property) {
      case 'questionContainer':
        warning = checkQuestionContainerWarning()
        success = checkQuestionContainerSuccess()
        neutral = 'neutral'

        return returnDesignPattern()
      default:
        return returnDesignPattern()
    }
  }

  checkQuestionExpandState() {
    const { questionExpand, questionData } = this.props
    let questionState = false
    if (questionExpand[this.props.questionType].length > 0) {
      const filteredQuestion = questionExpand[this.props.questionType].filter(
        (question) => {
          if (question.id === questionData.question_id) {
            return question
          }
        }
      )
      questionState = filteredQuestion[0].expand
    }
    return questionState
  }

  renderVideoState() {
    const { questionData, answers, t } = this.props

    if (
      answers[questionData.question_id] &&
      answers[questionData.question_id].uploaded_file.id
    ) {
      return (
        <Styled.VideoPlayerContainer>
          <video controls>
            <source
              src={
                (answers[questionData.question_id] &&
                  answers[questionData.question_id].uploaded_file
                    .external_link) ||
                ''
              }
              type="video/mp4"
            />
          </video>
          <Styled.NewVideoAction onClick={() => this.reuploadVideo()}>
            {t('videoNew')}
          </Styled.NewVideoAction>
        </Styled.VideoPlayerContainer>
      )
    }
    if (ZiggeoRecorderImport) {
      return (
        <div>
          <Styled.VideoQuestionLimit>
            {t('videoLimit')} - {this.configureTimeLimit()}
          </Styled.VideoQuestionLimit>

          <Styled.ZiggeoRecorderContainer
            statedesign={this.checkQuestionDesign('questionContainer')}
          >
            <ZiggeoRecorderImport
              apiKey="517c75ce2ce3deb169facb33c2ac7596" // unique key taken from Ziggeo website
              height={180}
              width={'100%'}
              timelimit={questionData.time_limit}
              onProcessed={this.recorderProcessed}
              allowedextensions={['mp4', 'mpg', 'avi', 'mov', 'mkv', 'wmv', 'ogv', 'webm', 'flv']}
            />
          </Styled.ZiggeoRecorderContainer>
        </div>
      )
    }
  }

  renderVideoQuestions() {
    const { questionData, answers, t } = this.props
    return (
      <Styled.QuestionBlock>
        <Styled.VideoQuestionRulesContainer>
          <div>
            {t('videoText1')}
          </div>
        </Styled.VideoQuestionRulesContainer>
        <Styled.FormGroup>
          <Styled.FormField>{this.renderVideoState()}</Styled.FormField>
        </Styled.FormGroup>

        <Styled.VideoQuestionRulesContainer>
          <span>{t('videoText2')}</span>
          <ul>
            <li>
              {t('videoText3')}
            </li>
            <li>{t('videoText4')}</li>
            <li>
              {t('videoText5')}
            </li>
            <li>{t('videoText6')}</li>
            <li>{t('videoText7')}</li>
          </ul>
        </Styled.VideoQuestionRulesContainer>
      </Styled.QuestionBlock>
    )
  }

  render() {
    const { questionData, answersRequirements, t } = this.props
    return (
      <Styled.QuestionContainer
        statedesign={this.checkQuestionDesign('questionContainer')}
      >
        <Accordion>
          <Styled.AccordionTitle
            active={this.checkQuestionExpandState()}
            onClick={() => this.handleQuestionExpand(this.props.questionType)}
          >
            <Styled.AccordionTitleTextContainer>
              <Styled.QuestionIconBorder>
                <Styled.QuestionIcon name="video" />{' '}
              </Styled.QuestionIconBorder>

              <Styled.AccordionTitleText>
                {questionData.question_text}
                <br />
                {answersRequirements[questionData.question_id].optional ? (
                  <span>({t('optional')})</span>
                ) : null}
              </Styled.AccordionTitleText>
            </Styled.AccordionTitleTextContainer>

            {this.checkQuestionExpandState() ? (
              <Styled.ExpandIcon name="chevron circle up" />
            ) : (
              <Styled.ExpandIcon name="chevron circle down" />
            )}
          </Styled.AccordionTitle>
          <Styled.AccordionContent active={this.checkQuestionExpandState()}>
            <AnimateHeight
              animateOpacity
              duration={300}
              height={this.checkQuestionExpandState() ? 'auto' : 0}
            >
              {this.renderVideoQuestions()}
            </AnimateHeight>
          </Styled.AccordionContent>
        </Accordion>
      </Styled.QuestionContainer>
    )
  }
}

const mapStateToProps = (state) => ({
  answers: state.jobApplicationReducer.answers,
  answersRequirements:
    state.jobApplicationReducer.submitRequirements.answersRequirements,
  firstTimeSubmitButtonClicked:
    state.jobApplicationReducer.submitRequirements.firstTimeSubmitButtonClicked,
  questionExpand: state.jobApplicationReducer.questionExpand,
})

VideoQuestion.propTypes = {
  answers: PropTypes.object, // comes from parent 'jobApplicationReducer'. Contains all the answers for the questions coming from truman, type of the question and id.
  answersRequirements: PropTypes.object, // comes from parent 'jobApplicationReducer'. Contains if questions coming from truman are optional or not. It also contains submitReady property that tells us if specific question is filled out ok.
  changeQuestionExpand: PropTypes.func, // used to expand or collapse question accordion.
  firstTimeSubmitButtonClicked: PropTypes.bool, // if true it means that "Submit" form button was clicked at least once.
  questionData: PropTypes.object, // passed down from parent 'JobDescriptionFullApplyFormQuestions' component. It contains all the data about that specific question coming from truman.
  questionExpand: PropTypes.object, // contains all the questions and tells if question accordion is expanded or not.
  questionType: PropTypes.string, // passed down from parent 'JobDescriptionFullApplyFormQuestions' component. It says which questionType question belongs to.
  saveSubmitRequirements: PropTypes.func, // Used to save if the question is submitReady or not to redux store.
  saveTrumanQuestionsInformation: PropTypes.func, // used to save user answers to redux store for every question.
}

export default connect(mapStateToProps, {
  changeQuestionExpand,
  saveTrumanQuestionsInformation,
  saveSubmitRequirements,
})(withTranslation('jobDescriptionFullApply')(VideoQuestion))
