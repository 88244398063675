import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { CSSTransition } from 'react-transition-group'
import * as Styled from 'verificationError_styled'
import { withTranslation } from 'react-i18next'
import WarningTriangleIcon from '../../../../../../../../../public/assets/svg/WarningTriangleIcon'

const NursysVerificationError = (props) => {
  const { verificationType, verificationStatus } = props

  const [state] = useState({
    icon: <WarningTriangleIcon />,
    titleText: `${verificationType}TitleText`,
    subtitleText: `${verificationType}SubtitleText`,
    descriptionText: `${verificationType}DescriptionText`,
    descriptionTextError503: `${verificationType}DescriptionTextError503`,
    buttonText: `${verificationType}ButtonText`,
  })

  const handleChangeActiveStep = (activeStep) => {
    props.changeActiveStep(activeStep, null)
  }

  const {
    titleText, subtitleText, descriptionText, descriptionTextError503, buttonText,
  } = state
  const { t } = props
  return (
    <Styled.VerificationErrorContainer data-testid="verification-error">
      <Styled.IconContainer>
        <CSSTransition timeout={1500} classNames="status-icon-animation" in={!!state.icon}>
          <div>{state.icon}</div>
        </CSSTransition>
      </Styled.IconContainer>
      <Styled.TitleText>{t(titleText)}</Styled.TitleText>
      <Styled.SubTitleText>{t(subtitleText)}</Styled.SubTitleText>
      {verificationStatus === '503' ? (
        <Styled.DescriptionText>{t(descriptionTextError503)}</Styled.DescriptionText>
      ) : (
        <Styled.DescriptionText>{t(descriptionText)}</Styled.DescriptionText>
      )}
      <Styled.ButtonContainer>
        <Styled.ButtonAction
          data-testid="try-again-button"
          onClick={() => handleChangeActiveStep('licenseVerification')}
        >
          {t(buttonText)}
        </Styled.ButtonAction>
      </Styled.ButtonContainer>
    </Styled.VerificationErrorContainer>
  )
}

NursysVerificationError.propTypes = {
  changeActiveStep: PropTypes.func, //  used to change activeStep which changes the verification screen
  verificationType: PropTypes.string, // lets us know which verification type we are one (LPN, RN...)
  verificationStatus: PropTypes.string, // error message that
}

export default withTranslation('verificationError')(NursysVerificationError)
