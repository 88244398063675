/* save user data */

const userDefaultState = {
  appliedJobsList: null, // Object
}

const userReducer = (state = userDefaultState, action) => {
  switch (action.type) {
    case 'SAVE_USER_DATA':
      return {
        ...state,
        appliedJobsList: action.payload.appliedJobsList,
      }
    default:
      return state
  }
}

export default userReducer
