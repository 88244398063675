import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Modal, Form } from 'semantic-ui-react'
import { withTranslation } from 'react-i18next'
import * as Styled from 'authorizationModals_styled'
import {
  closeResetPasswordModal,
  openLoginModal,
  openTimeoutImageModal,
} from '../../../../actions/modals_actions'

import { postResetPasswordApplicantAction } from '../../../../actions/api_actions'
import spinner from '../../../../../../public/assets/img/spinner.gif'

class ResetPasswordModal extends Component {
  state = {
    email: '',
    /* test inputs on submit */
    emailSubmit: null,
    showLoginErrorMsg: false,
    /* spinner */
    showSpinner: false,
    segmentData: {
      openLoginData: {
        entry_method: 'login',
        user_type: 'applicant',
      },
    },
  }

  handleFormSubmit = () => {
    const testEmailSubmit = () => this.emailValidation()

    const testInputsOnSubmit = async () => {
      const [emailSubmit] = await Promise.all([testEmailSubmit()])
      this.setState(
        {
          emailSubmit,
        },
        () => {
          if (this.state.emailSubmit) {
            this.finishFormSubmit()
          }
        }
      )
    }

    testInputsOnSubmit()
  }

  emailValidation = () =>
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g.test(
      this.state.email
    )

  handleInputChange(value, property) {
    this.setState(
      {
        [property]: value,
      },
      () => {
        if (property === 'email') {
          this.testEmail()
        }
      }
    )
  }

  testEmail() {
    const testEmail = this.emailValidation()

    /* First we check if emailSubmit state is not null. We only change input
      colors after user clicks Submit for the first time.
      At that point emailSubmit is false/true */
    if (this.state.emailSubmit !== null) {
      /* If user clicked "Submit" but email conditions were not met field becomes
          red as a warning. When all conditions are good we remove red color by setting
          emailSubmit state to false */
      if (testEmail) {
        this.setState({
          emailSubmit: true,
        })
      } else {
        this.setState({
          emailSubmit: false,
        })
      }
    }
  }

  finishFormSubmit() {
    const { t } = this.props
    /* Set spinner while data is loading */
    this.setState({
      showSpinner: true,
    })
    this.props
      .postResetPasswordApplicantAction(
        window.activeEnvironment,
        this.state.email
      )
      .then((response) => {
        this.props.openTimeoutImageModal({
          image: 'success',
          bodyMessage: `${t('passwordResetLinkSent')}
          ${this.state.email}`,
          timeout: 3000,
          pageReload: false,
          redirect: false,
          removeCloseModalOptions: false,
        })
        this.props.closeResetPasswordModal()
      })
      .catch((error) => {
        this.setState({
          showSpinner: false,
          showLoginErrorMsg: error.developer_message,
        })
      })
  }

  render() {
    const { email, emailSubmit } = this.state
    const { t } = this.props
    return (
      <Styled.ModalContainer
        size="mini"
        open
        onClose={this.props.closeResetPasswordModal}
        closeIcon
      >
        <Styled.ModalContent>
          <Modal.Description>
            <Styled.ModalSmallHeader>
              {t('enterEmailAddress')}
            </Styled.ModalSmallHeader>
            {/* Since the developer_message that we get back from API when we throw an error is good we show it here for user */}
            {this.state.showLoginErrorMsg && (
              <Styled.PopupWarning>
                {this.state.showLoginErrorMsg}
              </Styled.PopupWarning>
            )}
            <Form onSubmit={this.handleFormSubmit}>
              <Styled.FormField>
                <Styled.FormInputs
                  placeholder={t('registerModal:email')}
                  onChange={(e) =>
                    this.handleInputChange(e.target.value, 'email')
                  }
                  value={email}
                  passTest={emailSubmit}
                />
                {this.state.emailSubmit === false ? (
                  <Styled.PopupWarning passTest={this.state.emailSubmit}>
                    {t('registerModal:emailValidation2')}
                  </Styled.PopupWarning>
                ) : null}
              </Styled.FormField>

              {this.state.showSpinner ? (
                <img src={spinner} width="50" />
              ) : (
                <Styled.ButtonModal type="submit" authentication="warning">
                  {t('sendResetLink')}
                </Styled.ButtonModal>
              )}
            </Form>
          </Modal.Description>
        </Styled.ModalContent>
        {this.state.showSpinner ? null : (
          <Styled.ModalActions>
            <Styled.ModalActionsLinks
              onClick={() => {
                this.props.openLoginModal(this.state.segmentData.openLoginData)
                this.props.closeResetPasswordModal()
              }}
            >
              {t('backToLogin')}
            </Styled.ModalActionsLinks>
          </Styled.ModalActions>
        )}
      </Styled.ModalContainer>
    )
  }
}

const mapStateToProps = (state) => ({
  companyProfile: state.companyProfileReducer.companyProfile,
})

ResetPasswordModal.propTypes = {
  openLoginModal: PropTypes.func,
  closeResetPasswordModal: PropTypes.func,
}

export default connect(mapStateToProps, {
  closeResetPasswordModal,
  openLoginModal,
  openTimeoutImageModal,
  postResetPasswordApplicantAction,
})(withTranslation(['resetNewPassword', 'registerModal'])(ResetPasswordModal))
