import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Checkbox, Form, Icon } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
/* Styled Components */
import * as Styled from "jobDescriptionQuickApplyForm_styled";
import { postJobDescriptionQuickApplyFormAction } from "../../../../../../actions/api_actions";
import { openTimeoutMessageModal } from "../../../../../../actions/modals_actions";
import {
  quickApplyEntryRudderstack,
  quickApplyClearRudderstack,
  quickApplyExitRudderstack,
} from "../../../../../../rudderstack/rudderstack";
import spinner from "../../../../../../../../public/assets/img/spinner.gif";
import {
  extractUTM,
  getURLStringParams,
} from "../../../../../../helperFunctions/helperFunctions";
import {
  parsePhoneNumber
} from 'libphonenumber-js'
import { sanitizeString } from "../../../../../../../util";

export class JobDescriptionQuickApplyForm extends Component {
  state = {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    applyMethod: "",
    resume: {},
    utmData: {
      utm_campaign: "bolivar",
    },
    redirect: false,
    activeLoader: false,
    resumeUploaded: null, // we change this only if there is nothing in resume property when we hit submit button
    hasAgreedToTerms: false,
  };

  componentDidMount = () => {
    /* if user already applied to some job the data is saved in local Storage.
    We pull this data and pre-fill input for easy apply. */
    if (typeof window !== "undefined" && window.localStorage) {
      const appliedStatus = JSON.parse(
        localStorage.getItem("easyApplyApplicant")
      );
      if (appliedStatus) {
        let savedResume = false;

        if (appliedStatus.value.resume) {
          savedResume = true;
        }

        this.setState(
          {
            firstName: appliedStatus.value.first_name,
            lastName: appliedStatus.value.last_name,
            email: appliedStatus.value.email,
            phoneNumber: appliedStatus.value.phone_number,
            resume: savedResume
              ? {
                  file: {
                    data: appliedStatus.value.resume,
                    contentType: appliedStatus.value.resume_content_type,
                    fileName: appliedStatus.value.resume_file_name,
                  },
                }
              : {},
          },
          () => {
            /* Check if there is already applied job id that is the same as slug job id,
          among the list of applied jobs for specific user */
            if (this.props.appliedJobsList) {
              const applyMethod = this.checkApplyStatus();

              this.setState(
                {
                  applyMethod,
                },
                () => {
                  /* RUDDERSTACK */
                  /* ===================================== */
                  /* Call rudderstack event when page loads and when apply method is set. */
                  quickApplyEntryRudderstack(
                    this.state,
                    this.props.jobDescription,
                  );
                  /* ===================================== */
                }
              );
            }
          }
        );
        /* If there is nothing in local storage then user didn't apply to nothing jet so we
        render easy apply form */
        /* In case user deleted local storage after applying to some job, he will se apply
        form but after sending application with the same email, he will see an error */
      } else {
        this.setState(
          {
            applyMethod: "easy_apply_apploi",
          },
          () => {
            /* RUDDERSTACK */
            /* ===================================== */
            /* Call rudderstack event when page loads and when apply method is set. */
            quickApplyEntryRudderstack(
              this.state,
              this.props.jobDescription,
            );
            /* ===================================== */
          }
        );
      }

      /* Extract UTM's from url string */
      const utms = extractUTM();

      this.setState((prevState) => ({
        ...prevState,
        utmData: {
          ...prevState.utmData,
          ...utms,
        },
      }));
    }
  };

  componentDidUpdate = (prevProps) => {
    if (this.props.appliedJobsList) {
      if (prevProps.appliedJobsList !== this.props.appliedJobsList) {
        const applyMethod = this.checkApplyStatus();

        this.setState({
          applyMethod,
        });
      }
    }
  };

  checkApplyStatus = () => {
    let applyMethod = "";
    for (let i = 0; i < this.props.appliedJobsList.length; i += 1) {
      if (
        this.props.appliedJobsList[i].job_id == this.props.matchData.params.slug
      ) {
        applyMethod = "same_apply";
        break;
      } else {
        applyMethod = "1_click_apply";
      }
    }
    return applyMethod;
  };


  onFormSubmit = () => {
    const { jobDescription } = this.props;
    if (this.state.resume.file || !this.props.jobDescription.job_detail?.resume_required) {
      this.setState(
        {
          resumeUploaded: true,
        },
        () => {
          const { t } = this.props;
          /* disable submit button so we can not press it again until we get response back */
          this.setState({ activeLoader: true });
          this.props
            .postJobDescriptionQuickApplyFormAction(
              window.activeEnvironment,
              this.state,
              jobDescription.id
            )
            .then((easyApplyApplicant) => {
              this.setState({ activeLoader: false });
              /* RUDDERSTACK */
              /* ===================================== */
              /* Call rudderstack event after successful submit. */
              quickApplyExitRudderstack(
                this.state,
                this.props.jobDescription,
                easyApplyApplicant,
                "success",
              );
              /* ===================================== */

              /* We save to local storage that this user has applied for the job */
              /* We put different message based if it's a blind add and if we need to
              redirect or stay on the same page */
              const redirectMessage = this.props.jobDescription
                .show_company_logo
                ? t("redirectMessage")
                : "";
              this.props.openTimeoutMessageModal({
                headerMessage: t("applicationSubitted"),
                bodyMessage: redirectMessage,
                gtm: "apply-success",
                timeout: 3000,
                pageReload: false,
              });

              setTimeout(() => {
                /* Don't redirect to company profile if it's a blind add */
                if (this.props.jobDescription.show_company_logo) {
                  this.setState({
                    redirect: true,
                  });
                }
              }, 3000);
            })
            .catch((error) => {
              this.setState({ activeLoader: false });
              this.props.openTimeoutMessageModal({
                headerMessage: "ERROR",
                bodyMessage: error,
                footerMessage: null,
                timeout: 3000,
                pageReload: false,
              });
            });
        }
      );
    } else {
      this.setState({
        resumeUploaded: false,
      });
    }
  };

  handleChange = (e, property) =>
    this.setState({
      [property]: e.target.value,
    });

  handleFileChange = (e) => {
    const { t } = this.props;
    const file =
      e && e.target && e.target.files && e.target.files.length > 0
        ? e.target.files[0]
        : null;
    /* match .pdf, .docx, .doc */
    if (
      file &&
      file.type &&
      file.type.match(
        /application\/(pdf|vnd.openxmlformats-officedocument.wordprocessingml.document|msword)$/
      )
    ) {
      /* Turn uploaded file to base64 string */
      const turnToBase64File = (uploadedFile) =>
        new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(uploadedFile);
          reader.onloadend = () => {
            // Since it contains the Data URI, we should remove the prefix and keep only Base64 string
            resolve({ base64: reader.result.replace(/^data:.+;base64,/, "") });
          };
        });

      turnToBase64File(file).then((result) => {
        /* Make file object with all the informations that we need to send to API */
        const fileData = {
          data: result.base64,
          contentType: file.type,
          fileName: file.name,
        };

        this.setState({
          resume: { file: fileData },
          resumeUploaded: true,
        });
      });
    } else {
      this.props.openTimeoutMessageModal({
        headerMessage: "ERROR",
        bodyMessage: t("uploadError"),
        footerMessage: null,
        timeout: null,
        pageReload: false,
      });
    }
  };

  resetQuickApply = () => {
    /* RUDDERSTACK */
    /* ===================================== */
    /* Call rudderstack event when we reset the data in form */
    quickApplyClearRudderstack(this.state, this.props.jobDescription);
    /* ===================================== */
    this.setState({
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      applyMethod: "easy_apply_apploi",
      resume: {},
    });
  };

  // configureRedirectLocation = () => {
  //   /* If there is a slug value inside 'parent_url_slug' that means we are on a child team.
  //   If we make a quick apply on child team based on the redirect_to_parent property from
  //   truman we will redirect user to parent company profile or child company profile */
  //   if (this.props.companyProfile.parent_url_slug) {
  //     return this.props.companyProfile.redirect_to_parent
  //       ? `/profile/${this.props.companyProfile.parent_url_slug}${window.location.search}`
  //       : `/profile/${this.props.companyProfile.url_slug}${window.location.search}`
  //   }
  //   /* If there is no value in 'parent_url_slug' that means we are on parent team company profile
  //   and we redirect back to it after quick apply */
  //   return `/profile/${this.props.companyProfile.url_slug}${window.location.search}`
  // }

  configureRedirectLocation = () => {
    const keyParamsToFilter = [
      {
        key: "utm_source",
        replaceValue: false,
        valueToReplace: null,
      },
      {
        key: "utm_campaign",
        replaceValue: true,
        valueToReplace: "apploi_sourced",
      },
      {
        key: "utm_medium",
        replaceValue: false,
        valueToReplace: null,
      },
    ];
    const urlParams = getURLStringParams(keyParamsToFilter);
    /* If there is a slug value inside 'parent_url_slug' that means we are on a child team.
    If we make a quick apply on child team based on the redirect_to_parent property from
    truman we will redirect user to parent company profile or child company profile */
    if (this.props.companyProfile.parent_url_slug) {
      if (this.props.companyProfile.redirect_to_parent) {
        window.location.href = `${window.location.origin}/profile/${this.props.companyProfile.parent_url_slug}?${urlParams}`;
      } else {
        window.location.href = `${window.location.origin}/profile/${this.props.companyProfile.url_slug}?${urlParams}`;
      }
    } else {
      /* If there is no value in 'parent_url_slug' that means we are on parent team company profile
    and we redirect back to it after quick apply */
      window.location.href = `${window.location.origin}/profile/${this.props.companyProfile.url_slug}?${urlParams}`;
    }
  };

  render() {
    const { t } = this.props;
    const renderFormType = () => {
      if (this.state.applyMethod === "easy_apply_apploi") {
        return (
          <Form onSubmit={this.onFormSubmit} data-gtm="quick-apply">
            <Form.Group widths="equal">
              <Form.Field>
                <input
                  type="text"
                  placeholder={t("firstName")}
                  onChange={(e) => this.handleChange(e, "firstName")}
                  value={this.state.firstName}
                />
              </Form.Field>
              <Form.Field>
                <input
                  type="text"
                  placeholder={t("lastName")}
                  onChange={(e) => this.handleChange(e, "lastName")}
                  value={this.state.lastName}
                />
              </Form.Field>
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Field>
                <input
                  type="email"
                  placeholder={t("email")}
                  onChange={(e) => this.handleChange(e, "email")}
                  value={this.state.email}
                  required
                  pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-z]{2,4}$"
                />
              </Form.Field>

              <Form.Field>
                <input
                  type="text"
                  min={0}
                  placeholder={t("phone")}
                  onChange={(e) => {
                    const phoneNumber = e.target.value
                    const newChar = phoneNumber[phoneNumber.length - 1]

                    if (!isNaN(newChar) || newChar === undefined || newChar === '+') {
                      let phoneNumberFormatted = phoneNumber

                      if (phoneNumber && (phoneNumber.length >= 7 && phoneNumber.length <= 15)) {
                        phoneNumberFormatted = parsePhoneNumber(
                          phoneNumber,
                          'US'
                        ).format('E.164')
                      }

                      this.setState({
                        phoneNumber: phoneNumberFormatted,
                      })
                    }
                  }}
                  value={this.state.phoneNumber}
                />
              </Form.Field>
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Field>
                <Styled.FileInputContainer>
                  <label htmlFor="fileInput">
                    <Styled.FileInputText
                      resumeUploadedColor={
                        this.state.resumeUploaded === false ? "red" : "#000"
                      }
                    >
                      <Icon name="attach" />{" "}
                      {this.state.resume.file && this.state.resume.file.fileName
                        ? this.state.resume.file.fileName
                        : t("attachResume")}
                    </Styled.FileInputText>
                    {!this.state.resume.file && (
                      <Styled.FileInputSubText
                        resumeUploadedColor={
                          this.state.resumeUploaded === false ? "red" : "#000"
                        }
                      >
                        {this.props.jobDescription.job_detail?.resume_required
                          ? t("required")
                          : t("optional")}
                      </Styled.FileInputSubText>
                    )}

                    <input
                      type="file"
                      name="myFile"
                      onChange={(e) => this.handleFileChange(e)}
                      id="fileInput"
                      accept=".pdf, .docx, .doc"
                    />
                  </label>
                </Styled.FileInputContainer>
              </Form.Field>
              <Form.Field>
                <Styled.SubmitButtonContainer>
                  {this.state.activeLoader ? (
                    <img src={spinner} width="50" alt="loading spinner" />
                  ) : (
                    <Styled.SubmitButton
                      type="submit"
                      customcolor={
                        this.props.companyProfile &&
                        this.props.companyProfile.primary_color
                          ? this.props.companyProfile.primary_color
                          : "#000"
                      }
                      disabled={!this.state.hasAgreedToTerms}
                    >
                      <Styled.SubmitButtonText>
                        {t("sumbit")}
                      </Styled.SubmitButtonText>
                    </Styled.SubmitButton>
                  )}
                </Styled.SubmitButtonContainer>
              </Form.Field>
            </Form.Group>

            <Form.Field>
              <Styled.FooterText>
                <Styled.ButtonAndCheckboxContainer>
                  <Checkbox
                    id="agreeToTerms"
                    checked={this.state.hasAgreedToTerms}
                    onChange={(e) => this.setState({
                      hasAgreedToTerms: e.target.checked,
                    })}
                  />
                  <label
                    htmlFor="agreeToTerms"
                    dangerouslySetInnerHTML={{
                      __html: t('jobDescription:termsAndConditionsText')?.replace(
                        '[Company Name]',
                        sanitizeString(this.props.jobDescription.brand_name ?? '')
                      ),
                    }}
                  />
                </Styled.ButtonAndCheckboxContainer>
              </Styled.FooterText>
            </Form.Field>
          </Form>
        );
      }
      if (this.state.applyMethod === "1_click_apply") {
        return (
          <Styled.QuickApplyInfoContainer>
            <Styled.QuickApplyInfoTitle>
              {t("quickApply")}
            </Styled.QuickApplyInfoTitle>
            <Styled.QuickApplyInfo>
              <Styled.InfoContainer>
                <span>{t("firstName")}:</span> {this.state.firstName || "-"}
              </Styled.InfoContainer>

              <Styled.InfoContainer>
                <span>{t("lastName")}:</span> {this.state.lastName || "-"}
              </Styled.InfoContainer>

              <Styled.InfoContainer>
                <span>{t("email")}:</span> {this.state.email || "-"}
              </Styled.InfoContainer>

              <Styled.InfoContainer>
                <span>{t("phone2")}:</span> {this.state.phoneNumber || "-"}
              </Styled.InfoContainer>
            </Styled.QuickApplyInfo>

            <Styled.QuickApplyInfoText>
              {t("quickApplyInfoText")}{" "}
              <Styled.StartOverText onClick={() => this.resetQuickApply()}>
                {t("quickApplyClick")}
              </Styled.StartOverText>
            </Styled.QuickApplyInfoText>

            {this.state.activeLoader ? (
              <img src={spinner} width="50" alt="loading spinner" />
            ) : (
              <Fragment>
                {/* When we apply to a job where we don't need to upload a Resume file and then go to
              some other job where we have to, we will see 1-click-apply screen. So in order to tell
              users that they need to go back and upload a resume we show the message */}
                <Styled.WarningPopupText>
                  {this.state.resumeUploaded === false
                    ? t("resumeValidation")
                    : null}
                </Styled.WarningPopupText>
                <Styled.ActionButton
                  type="submit"
                  onClick={() => this.onFormSubmit()}
                  disabled={!this.state.hasAgreedToTerms}
                  customcolor={
                    this.props.companyProfile &&
                    this.props.companyProfile.primary_color
                      ? this.props.companyProfile.primary_color
                      : "#000"
                  }
                >
                  {t("applyNow")}
                </Styled.ActionButton>
              </Fragment>
            )}

            <Styled.FooterText>
              <Styled.ButtonAndCheckboxContainer>
                <Checkbox
                  id="agreeToTerms"
                  checked={this.state.hasAgreedToTerms}
                  onChange={(e) => this.setState({
                    hasAgreedToTerms: e.target.checked,
                  })}
                />
                <label
                  htmlFor="agreeToTerms"
                  dangerouslySetInnerHTML={{
                    __html: t('jobDescription:termsAndConditionsText')?.replace(
                      '[Company Name]',
                      sanitizeString(this.props.jobDescription.brand_name ?? '')
                    ),
                  }}
                />
              </Styled.ButtonAndCheckboxContainer>
            </Styled.FooterText>
          </Styled.QuickApplyInfoContainer>
        );
      }
      if (this.state.applyMethod === "same_apply") {
        return (
          <Styled.QuickApplyInfoContainer>
            <Styled.QuickApplyInfoTitle>
              {t("alreadyApplied")}
            </Styled.QuickApplyInfoTitle>

            <Styled.QuickApplyInfo>
              <Styled.InfoContainer>
                <span>{t("firstName")}:</span> {this.state.firstName || "-"}
              </Styled.InfoContainer>

              <Styled.InfoContainer>
                <span>{t("lastName")}:</span> {this.state.lastName || "-"}
              </Styled.InfoContainer>

              <Styled.InfoContainer>
                <span>{t("email")}:</span> {this.state.email || "-"}
              </Styled.InfoContainer>

              <Styled.InfoContainer>
                <span>{t("phone2")}:</span> {this.state.phoneNumber || "-"}
              </Styled.InfoContainer>
            </Styled.QuickApplyInfo>

            <Styled.QuickApplyInfoText>
              {t("quickApplyInfoText2")}
            </Styled.QuickApplyInfoText>

            <Styled.ActionButton
              onClick={() => this.resetQuickApply()}
              customcolor={
                this.props.companyProfile &&
                this.props.companyProfile.primary_color
                  ? this.props.companyProfile.primary_color
                  : "#000"
              }
            >
              {t("applyAgain")}
            </Styled.ActionButton>
            <Styled.FooterText>
              <Styled.ButtonAndCheckboxContainer>
                <Checkbox
                  id="agreeToTerms"
                  checked={this.state.hasAgreedToTerms}
                  onChange={(e) => this.setState({
                    hasAgreedToTerms: e.target.checked,
                  })}
                />
                <label
                  htmlFor="agreeToTerms"
                  dangerouslySetInnerHTML={{
                    __html: t('jobDescription:termsAndConditionsText')?.replace(
                      '[Company Name]',
                      sanitizeString(this.props.jobDescription.brand_name ?? '')
                    ),
                  }}
                />  
              </Styled.ButtonAndCheckboxContainer>
            </Styled.FooterText>
          </Styled.QuickApplyInfoContainer>
        );
      }
      /* Until we get the data `user` we need to show something since not '1_click_apply'
      nor 'easy_apply' is active as default one in order to not show any information on the screen */
      return t("loading");
    };

    return (
      <Fragment>
        {this.state.redirect === true ? (
          this.configureRedirectLocation()
        ) : (
          <Styled.QuickApplyFormContainer>
            {" "}
            {this.state.applyMethod === "easy_apply_apploi" ? (
              <Styled.SectionHeader>{t("applyInstantly")}</Styled.SectionHeader>
            ) : null}
            <Styled.FormContainer>{renderFormType()}</Styled.FormContainer>
          </Styled.QuickApplyFormContainer>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  jobDescription: state.jobDescriptionReducer.jobDescription,
  companyProfile: state.companyProfileReducer.companyProfile,
  appliedJobsList: state.userReducer.appliedJobsList,
});

JobDescriptionQuickApplyForm.propTypes = {
  jobDescription: PropTypes.object, // comes from jobDescriptionReducer. Contains data about current active job.
  companyProfile: PropTypes.object, // comes from companyProfileReducer. Contains data about current active company.
  appliedJobsList: PropTypes.array, // shows the list of all the jobs current user applied to, quickApply and fullApply jobs (when logged in).
  matchData: PropTypes.object, // comes from parent 'JobDescriptionQuickApply' component. It's a route prop 'match' from react-router.
  openTimeoutMessageModal: PropTypes.func, // used to open TimeoutMessageModal.
  postJobDescriptionQuickApplyFormAction: PropTypes.func, // submit form
};

export default connect(mapStateToProps, {
  openTimeoutMessageModal,
  postJobDescriptionQuickApplyFormAction,
})(
  withTranslation(["jobDescriptionQuickApply", "jobDescription"])(
    JobDescriptionQuickApplyForm
  )
);
