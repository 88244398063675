/* import polyfills for older browser */
import 'core-js/stable'
import 'regenerator-runtime/runtime'
import React from 'react'
import * as Sentry from '@sentry/browser'
import { Integrations } from '@sentry/tracing'
import { render, hydrate } from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import Scenes from 'Scenes'
import { HelmetProvider } from 'react-helmet-async'
import TagManager from 'react-gtm-module'

// Import all related to i18n
import './i18n'

// we import semantic-ui-css styles before any of our local styles so we can overwrite them
import 'semantic-ui-css/semantic.min.css'
import GlobalStyled from 'global_styled'
import createStore from './store/configureStore'
import { clientConfig } from '../config/sentry'

/* Use deviceType throughout the app to see if user is coming from mobile/table/desktop.
This is useful when using SSR because data is passed from the server and not dependent
on JS and CSS for the first load. */
window.deviceType = deviceType
/* Use activeEnvironment to determine which environment is active on Heroku */
window.activeEnvironment = activeEnvironment
/* Use baseURL to setup custom base URL link */
window.baseURL = baseURL
/* Use integrationsURL to setup custom integration URL link */
window.integrationsURL = integrationsURL
/* Use interviewGuide to determine if we should render interviewGuide or normal Full apply page */
window.interviewGuideEnabled = interviewGuideEnabled

// use IPS_FROM_INDEED env var from server
window.IpsFromIndeed = IpsFromIndeed

window.releaseVersion = releaseVersion

window.hiringURL = hiringURL

/* create store */
const store = createStore(window.REDUX_DATA)
// expose store when run in Cypress so we can test store
window.store = store

const tagManagerArgs = {
  gtmId: process.env.NODE_ENV === 'production' ? 'GTM-PTPBRBB' : 'GTM-KV9ZWDL3'
};

TagManager.initialize(tagManagerArgs)

if (process.env.NODE_ENV === 'production' && process.env.SENTRY_DSN_TOKEN) {
  Sentry.init({
    dsn: process.env.SENTRY_DSN_TOKEN,
    environment: activeEnvironment,
    release: releaseVersion || window.releaseVersion,
    ...clientConfig,
    integrations: [
      new Integrations.BrowserTracing({
        beforeNavigate: (context) => ({
          ...context,
          name: context.name
            .replace(/\/\d+/g, '/:id')
            .replace(/=\d+/g, '=:id')
            // Custom rules
            .replace(/\/profile\/[\w\W\d]+/g, '/profile/:company'),
        }),
      }),
    ],
    tracesSampleRate: activeEnvironment === 'production' ? 0.25 : 1.0,
  })
}
/* ================================= */

const app = document.getElementById('app')

const renderApp = (Component) => {
  if (app == null) {
    throw new Error('no app element')
  }

  /* If development environment don't use SSR */
  const renderMethod = module.hot ? render : hydrate

  renderMethod(
    <Provider store={store}>
      <BrowserRouter>
        <HelmetProvider>
          <GlobalStyled />
          <Component />
        </HelmetProvider>
      </BrowserRouter>
    </Provider>,
    app,
  )
}

renderApp(Scenes)
