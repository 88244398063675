import React from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import * as Sentry from '@sentry/browser'
import { useSelector } from 'react-redux'
import { captureException } from '../../../../config/sentry'

const myErrorHandler = (error, {
  info: { componentStack },
  tags = {},
  extras = {},
}) => {
  const myTags = {
    ...tags,
    'app-error': String(error),
  }

  const myExtras = {
    ...extras,
    componentStack,
  }

  captureException(Sentry)(error, {
    tags: myTags,
    extras: myExtras,
  })
}

const ErrorFallback = ({ sectionName }) => (
  <div>
    Something went wrong with {sectionName ? `${sectionName} section` : 'this section'}, please
    retry. If the error persist contact the administrator
  </div>
)

const ErrorBoundaryWrapper = (props) => {
  const {
    children,
    sectionName,
    tags,
    extras,
  } = props

  const {
    applicant,
    easyApplyApplicant,
  } = useSelector(({ authenticationReducer }) => authenticationReducer)

  const applicantId = applicant?.value?.id
  const easyApplyApplicantId = easyApplyApplicant?.value?.person_id

  return (
    <ErrorBoundary
      FallbackComponent={() => <ErrorFallback sectionName={sectionName} />}
      onError={(error, info) => myErrorHandler(error, {
        info,
        tags: {
          ...tags,
          person_id: applicantId || (easyApplyApplicantId || null),
        },
        extras,
      })}
    >
      {children}
    </ErrorBoundary>
  )
}

export default ErrorBoundaryWrapper
