import React, { useEffect, Fragment } from 'react'
import { connect } from 'react-redux'
import { Grid, Container } from 'semantic-ui-react'
import CompanyProfileHeader from 'CompanyProfileHeader'
import CompanyProfileBody from 'CompanyProfileBody'
import { Helmet } from 'react-helmet-async'
import queryString from 'query-string'
import { useTranslation } from 'react-i18next'
import { saveRouteData } from '../../../actions/global_actions'
import {
  getCompanyProfileAction,
  getIPInfoAction,
  getCompanyProfileJobsAction,
} from '../../../actions/api_actions'
import { saveNumberOfQuestions } from '../../../actions/jobDescription_actions'
import { saveIpInfo } from '../../../actions/companyProfile_actions'
import ErrorBoundary from '../../sharedComponents/errorPages/ErrorBoundary'

const CompanyProfile = (props) => {
  const {
    saveNumberOfQuestions,
    getIPInfoAction,
    getCompanyProfileAction,
    getCompanyProfileJobsAction,
    saveRouteData,
    route,
    match,
    location,
    companyProfile,
    ipInfo,
  } = props
  const searchParams = queryString.parse(location.search)
  const debug = Boolean(searchParams?.debug)
  const utm = {
    utm_source: searchParams?.utm_source || 'company_profile_page',
    utm_campaign: null, // searchParams?.utm_campaign,
    utm_medium: null, // searchParams?.utm_medium,
  }
  const {
    i18n,
    i18n: { language },
  } = useTranslation('')

  useEffect(() => {
    /* save global route data */
    saveRouteData(route, match, location)

    getCompanyProfileAction(
      window.activeEnvironment,
      match.params.slug,
    )
    // TODO Check if this function can be removed as it is being already fetch from Serverside with fallback also

    getIPInfoAction(window.activeEnvironment)

    /* We reset number of questions for here just for UI look. When someone clicks
    on some job it looks for the number of questions for that job and renders
    quick apply of full apply page. If someone goes back with "back" button
    and clicks second job, until data is fetched for a number of questions the
    old number stays and renders quick apply of full apply page. In order not to
    show that flash we reset it here. To do this on job page with componentWillUnmount
    will break stuff so don't do it (after login on quick apply it will not redirect to full apply) */

    saveNumberOfQuestions(null)
    i18n.changeLanguage('en')
  }, [])

  useEffect(() => {
    if (!companyProfile) {
      return
    }

    getCompanyProfileJobsAction(
      window.activeEnvironment,
      1,
      null,
      ipInfo?.latitude,
      ipInfo?.longitude,
      companyProfile?.teams_to_show,
      null,
      null,
      debug,
      language,
      null,
      null,
      null,
      utm,
    )
  }, [ipInfo, companyProfile, debug, language])

  return (
    <div>
      <Helmet>
        <title>
          {companyProfile && companyProfile.title ? companyProfile.title : ''}{' '}
          Profile
        </title>
        <meta
          property="og:title"
          content={
            companyProfile && companyProfile.title ? companyProfile.title : ''
          }
        />
        <meta
          property="og:description"
          content={
            companyProfile && companyProfile.description ?
              companyProfile.description
                .replace(/<[^>]+>/g, '')
                .replace(/&nbsp;/g, ' ')
                .replace(/\&amp;/g, '&') :
              ''
          }
        />
        <meta
          property="og:image"
          content={
            companyProfile && companyProfile.logo_url ?
              companyProfile.logo_url :
              ''
          }
        />
        <meta property="og:type" content="website" />
        <meta
          name="twitter:title"
          content={
            companyProfile && companyProfile.title ? companyProfile.title : ''
          }
        />
        <meta
          name="twitter:description"
          content={
            companyProfile && companyProfile.description ?
              companyProfile.description
                .replace(/<[^>]+>/g, '')
                .replace(/&nbsp;/g, ' ')
                .replace(/\&amp;/g, '&') :
              ''
          }
        />
        <meta
          name="twitter:image"
          content={
            companyProfile && companyProfile.logo_url ?
              companyProfile.logo_url :
              ''
          }
        />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <Container>
        <Grid stackable>
          <Fragment>
            <ErrorBoundary
              tags={{ component: 'CompanyProfileHeader' }}
              sectionName="Company Profile Header"
            >
              <CompanyProfileHeader />
            </ErrorBoundary>
            <ErrorBoundary
              tags={{ component: 'CompanyProfileBody' }}
              sectionName="Company Profile Header"
            >
              <CompanyProfileBody
                match={match}
                debug={debug}
                utm={utm}
              />
            </ErrorBoundary>
          </Fragment>
        </Grid>
      </Container>
    </div>
  )
}

CompanyProfile.serverFetch = [getCompanyProfileAction, getIPInfoAction, ]

const mapStateToProps = (state) => ({
  companyProfile: state.companyProfileReducer.companyProfile,
  companyProfileJobs: state.companyProfileReducer.companyProfileJobs,
  ipInfo: state.companyProfileReducer.ipInfo,
  shortenUrl: state.companyProfileReducer.shortenUrl,
})

export default connect(mapStateToProps, {
  saveRouteData,
  getCompanyProfileAction,
  getIPInfoAction,
  getCompanyProfileJobsAction,
  saveNumberOfQuestions,
  saveIpInfo,
})(CompanyProfile)
