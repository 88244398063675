import styled from 'styled-components'

export const VerificationFlowWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 15px;

  .item-mount-exit {
    position: absolute;
    opacity: 1;
  }
  .item-mount-exit-active {
    opacity: 0;
    transition: opacity 500ms;
  }

  .item-mount-enter {
    opacity: 0;
  }
  .item-mount-enter-active {
    opacity: 1;
    transition: opacity 500ms;
  }

  .item-mount-appear {
    opacity: 0;
  }

  .item-mount-appear-active {
    opacity: 1;
    transition: opacity 500ms;
  }
`
