import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import * as Styled from 'lpnRnVerification_styled'
import { Form, Icon, Popup } from 'semantic-ui-react'
import Dropzone from 'react-dropzone'
import { CSSTransition } from 'react-transition-group'
import {
  postStreamlineVerificationDataAction,
  postVerificationFileUploadAction,
} from '../../../../../../../actions/api_actions'
import spinner from '../../../../../../../../../public/assets/img/spinner.gif'

export function containsInvalidCharacters(licenseNumber) {
  // Regular expressions to match invalid characters
  const regex = /[^a-zA-Z0-9 .-]/
  return regex.test(licenseNumber)
}
const activeSteps = {
  yes: {
    activeStep: 'verificationStatus',
    verificationStatus: 'success',
  },
  invalid: {
    activeStep: 'verificationStatus',
    verificationStatus: 'notActive',
  },
  success: {
    activeStep: 'verificationSuccess',
    verificationStatus: null,
  },
  error: {
    activeStep: 'verificationError',
    verificationStatus: 'error',
  },
  expired: {
    activeStep: 'verificationStatus',
    verificationStatus: 'expired',
  },
  notFound: {
    activeStep: 'verificationError',
    verificationStatus: 'notFound',
  },
  default: {
    activeStep: null,
    verificationStatus: null,
  },
}

const CNAVerification = (props) => {
  const { lastFourSsnEnabled } = props
  const [firstName, setFirstName] = useState({
    firstName: '',
    firstNameMissing: false,
  })

  const [credentialNumber, setCredentialNumber] = useState('')
  const [credentialNumberError, setCredentialNumberError] = useState(null)

  const [lastName, setLastName] = useState({
    lastName: '',
    lastNameMissing: false,
  })

  const [lastFourSsn, setLastFourSsn] = useState({
    lastFourSsn: '',
    lastFourSsnMissing: false,
  })

  const [showSpinner, showSpinnerSet] = useState(false)

  const [fileData, fileDataSet] = useState({})

  const handleInputChange = (setValue, key, value) => {
    setValue({
      [key]: value,
      [`${key}Missing`]: false,
    })
  }

  const { t } = useTranslation('svVerification')
  const dispatch = useDispatch()
  // licenseNumber, ssnNumber
  const handleLicenseDataChange = (value) => {
    setCredentialNumber(value.trim())
    setCredentialNumberError(null)
  }

  const handleLastFourSsnChange = (value) => {
    setLastFourSsn({
      lastFourSsn: value,
      lastFourSsnMissing: value.length !== 4,
    })
  }

  const handleSubmit = () => {
    /* Before submitting check if all the inputs are filled out and show warning message for the ones that are not */
    let firstNameMissing = true
    let lastNameMissing = true
    let lastFourSsnMissing = true && lastFourSsnEnabled
    let credentialNumberErrorSubmit = credentialNumberError

    if (firstName.firstName) {
      firstNameMissing = false
    }

    if (lastName.lastName) {
      lastNameMissing = false
    }

    if (lastFourSsnEnabled && lastFourSsn.lastFourSsn.length === 4) {
      lastFourSsnMissing = false
    }

    if (!credentialNumber.length) {
      credentialNumberErrorSubmit = t('certificateNumberWarning');
      setCredentialNumberError(credentialNumberError)
    } else if (containsInvalidCharacters(credentialNumber)) {
      credentialNumberErrorSubmit = t('certificateNumberNotSupported');
      setCredentialNumberError(credentialNumberErrorSubmit)
    }
    setLastFourSsn({
      ...lastFourSsn,
      lastFourSsnMissing,
    })

    setFirstName({
      ...firstName,
      firstNameMissing,
    })

    setLastName({
      ...lastName,
      lastNameMissing,
    })

    if (!firstNameMissing && !lastNameMissing && !lastFourSsnMissing && !credentialNumberErrorSubmit) {
      showSpinnerSet(true)

      const rudderstackFields = {
        team_name: props.verificationData.team_name,
        user_type: props.verificationData.user_type,
        team_id: props.verificationData.team_id,
        source: props.verificationData.source,
        parent_id: props.verificationData.parent_id,
        parent_name: props.verificationData.parent_name,
      }
      const streamlineFields = {
        license_number: credentialNumber,
        last_name: lastName.lastName,
        first_name: firstName.firstName,
        last_four_ssn: lastFourSsn.lastFourSsn,
      }

      dispatch(postStreamlineVerificationDataAction({
        environment: window.activeEnvironment,
        personId: props.verificationData.person_id,
        state: props.verificationData.state,
        jobId: props.verificationData.job_id,
        verificationType: props.verificationType,
        rudderstackFields,
        streamlineFields,
      }))

        .then((response) => {
          showSpinnerSet(false)
          let stepSelected = activeSteps.default

          if (response?.status === 202) {
            stepSelected = activeSteps.success
          }

          if (response?.active) {
            const activeStatus = response.active.toLowerCase();
            if (['yes', 'valid'].includes(activeStatus)) {
              stepSelected = activeSteps.yes
            }
            if (['no', 'invalid'].includes(activeStatus)) {
              stepSelected = activeSteps.invalid
            }
            if (response.engine === 'SvApi') {
              stepSelected = activeSteps.success
            }
          }


          if (
            response &&
            response.active &&
            response.license_status &&
            response.license_status === 'EXPIRED'
          ) {
            stepSelected = activeSteps.expired
          }

          /* If file was added, upload it after getting response from verification */

          if (fileData.name) {
            const formFile = new FormData()

            formFile.append('license', fileData)
            formFile.append('person_id', props.verificationData.person_id)
            formFile.append('nurse_id', response.nurse_id)
            formFile.append(
              'uploaded_by_recruiter',
              props.verificationData.uploaded_by_recruiter ?
                props.verificationData.uploaded_by_recruiter :
                0,
            )

            formFile.append('rudderstack_fields', JSON.stringify(rudderstackFields))

            dispatch(postVerificationFileUploadAction(window.activeEnvironment, formFile))
          }
          props.changeActiveStep(stepSelected.activeStep, stepSelected.verificationStatus)
        })

        .catch((error) => {
          if (error?.response) {
            if (error.response.status >= 400) {
              props.changeActiveStep(
                activeSteps.notFound.activeStep,
                activeSteps.notFound.verificationStatus,
              )
            }
          } else if (error.message.includes('Network Error')) {
            props.changeActiveStep(
              activeSteps.success.activeStep,
              activeSteps.success.verificationStatus,
            )
            showSpinnerSet(false)
          } else {
            throw error
          }
        })
    }
  }

  const handleFileChange = (acceptedFiles) => {
    const file = acceptedFiles[0]

    if (
      (file &&
        file.size <= 10000000 &&
        file.type &&
        file.type.match(
          /application\/(pdf|vnd.openxmlformats-officedocument.wordprocessingml.document|msword|jpg)$/,
        )) ||
      file.type.match('image/jpeg|image/jpg|image/png')
    ) {
      fileDataSet(file)
    }
  }

  const handleClearFile = () => {
    fileDataSet({})
  }

  const { verificationType } = props

  return (
    <Styled.LpnRnVerificationContainer data-testid="sv-verification-form">
      <Styled.TitleText>
        {t('typeTitleText', { verificationType: verificationType.toUpperCase() })}
      </Styled.TitleText>
      <Styled.SubTitleText>{t('subtitleText', { state: props.longStateName })}</Styled.SubTitleText>

      <Form onSubmit={() => handleSubmit()}>
        <Styled.InputContainer>
          <Styled.InputColumnWrapper>
            {/* First name */}

            <Styled.FormField>
              <Styled.InputLabel htmlFor="first-name">{t('firstName')}</Styled.InputLabel>
              <Styled.InputField
                type="text"
                id="first-name"
                placeholder={t('firstNamePlaceholder')}
                value={firstName.firstName}
                onChange={(e) => handleInputChange(setFirstName, 'firstName', e.target.value)}
              />
              <CSSTransition
                timeout={500}
                classNames="warning-message-mount"
                in={firstName.firstNameMissing}
                unmountOnExit
              >
                <Styled.WarningMessage>{t('firstNameWarning')}</Styled.WarningMessage>
              </CSSTransition>
            </Styled.FormField>

            {/* Last name */}

            <Styled.FormField>
              <Styled.InputLabel htmlFor="last-name">{t('lastName')}</Styled.InputLabel>

              <Styled.InputField
                id="last-name"
                type="text"
                placeholder="Enter Last Name"
                value={lastName.lastName}
                onChange={(e) => handleInputChange(setLastName, 'lastName', e.target.value)}
              />
              <CSSTransition
                timeout={500}
                classNames="warning-message-mount"
                in={lastName.lastNameMissing}
                unmountOnExit
              >
                <Styled.WarningMessage>{t('lastNameWarning')}</Styled.WarningMessage>
              </CSSTransition>
            </Styled.FormField>

            {/* License Number */}

            <Styled.FormField>
              <Styled.InputLabel htmlFor="credential-number">
                {t('certificateNumber')}
                <Popup
                  inverted
                  position="top center"
                  content={
                    <div style={{ 'white-space': 'pre-wrap' }}>{t('certificateNumberPopup')}</div>
                  }
                  trigger={<Icon name="question circle outline" />}
                />
              </Styled.InputLabel>

              <Styled.InputField
                type="text"
                id="credential-number"
                placeholder={t('certificateNumberPlaceholder')}
                value={credentialNumber}
                onChange={(e) => handleLicenseDataChange(e.target.value)}
              />
              <CSSTransition
                timeout={500}
                classNames="warning-message-mount"
                in={!!credentialNumberError}
                unmountOnExit
              >
                <Styled.WarningMessage>{credentialNumberError}</Styled.WarningMessage>
              </CSSTransition>
            </Styled.FormField>

            {/* Last 4 Digits of SSN */}
            {lastFourSsnEnabled ? (
              <Styled.FormField>
                <Styled.InputLabel htmlFor="last-four-ssn">
                  {t('lastFourSsn')}
                </Styled.InputLabel>

                <Styled.InputField
                  type="password"
                  id="last-four-ssn"
                  pattern="\d{4}"
                  title={t('lastFourSsn')}
                  placeholder={t('lastFourSsnPlaceholder')}
                  value={lastFourSsn.lastFourSsn}
                  onChange={(e) => handleLastFourSsnChange(e.target.value)}
                />
                <CSSTransition
                  timeout={500}
                  classNames="warning-message-mount"
                  in={lastFourSsn.lastFourSsnMissing}
                  unmountOnExit
                >
                  <Styled.WarningMessage>
                    {t('lastFourSsnWarning')}
                  </Styled.WarningMessage>
                </CSSTransition>
              </Styled.FormField>
            ) : null}
          </Styled.InputColumnWrapper>

          <Styled.FileColumnWrapper>
            <Form.Field>
              {/* Dropzone */}

              <Dropzone onDrop={(acceptedFiles) => handleFileChange(acceptedFiles)}>
                {({ getRootProps, getInputProps }) => (
                  <section>
                    <Styled.DropzoneField {...getRootProps({ className: 'dropzone' })}>
                      <input {...getInputProps()} accept=".pdf, .docx, .doc, .jpg, .jpeg, .png" />
                      <Styled.FolderIcon name="folder open outline" />
                      <Styled.DragTitle>{t('dragTitleText')}</Styled.DragTitle>
                      <Styled.DragTitleOptional>
                        {t('dragTitleOptionalText')}
                      </Styled.DragTitleOptional>
                      <Styled.DragSubTitle>{t('dragSubTitleText')}</Styled.DragSubTitle>
                      <Styled.DragFileType>{t('dragFileType')}</Styled.DragFileType>
                      <Styled.DragFileType>{t('dragFileSize')}</Styled.DragFileType>
                    </Styled.DropzoneField>
                  </section>
                )}
              </Dropzone>

              {/* File Name */}

              <CSSTransition
                timeout={{ enter: 500, exit: 1 }}
                classNames="file-name-mount"
                in={!!fileData.name}
                unmountOnExit
              >
                <Styled.FileNameContainer>
                  <Styled.CheckIconContainer>
                    <Icon name="check" />
                  </Styled.CheckIconContainer>

                  <Styled.FileName>{fileData.name}</Styled.FileName>

                  <Styled.ClearIconContainer onClick={() => handleClearFile()}>
                    <Icon name="times circle outline" />
                  </Styled.ClearIconContainer>
                </Styled.FileNameContainer>
              </CSSTransition>
            </Form.Field>
          </Styled.FileColumnWrapper>
        </Styled.InputContainer>

        {/* Submit button */}

        {showSpinner ? (
          <img src={spinner} width="40" alt="spinner" />
        ) : (
          <Styled.ButtonContainer>
            <Styled.SubmitButton
              data-testid="submit-button"
              type="submit"
              value={t('submitButtonText')}
            />
          </Styled.ButtonContainer>
        )}
      </Form>

      <Styled.FooterText>
        {t('footerText')}
        &nbsp;
        <a href="https://www.apploi.com/terms-conditions/" target="_blank" rel="noreferrer">
          {t('termsAndConditions')}
        </a>
      </Styled.FooterText>
    </Styled.LpnRnVerificationContainer>
  )
}

CNAVerification.propTypes = {
  // used to change activeStep which changes the verification screen
  changeActiveStep: PropTypes.func,
  verificationType: PropTypes.string, // lets us know wich verification type we are one (LPN, RN...)
  verificationData: PropTypes.object, // data extracted from URL string
  postStreamlineVerificationDataAction: PropTypes.func, // send input data for verification
  postVerificationFileUploadAction: PropTypes.func, // used to upload license files
  longStateName: PropTypes.string, // full name of the state
  lastFourSsn: PropTypes.bool, // flag to signify whether or not the SSN last 4 are required 
}

export default CNAVerification
