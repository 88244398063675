import styled from 'styled-components'
import { Input, Button } from 'semantic-ui-react'

export const SkillsContainer = styled.div`
  h4 {
    font-family: 'NeutrafaceBold' !important;
  }
`

export const SkillWrapper = styled.div`
  background-color: #f9f9f9;
  border: 1px solid #d0d0d0;
  border-radius: 10px;
  margin: 0px 10px 10px 0;
  padding: 10px 10px;
  text-transform: uppercase;
`

export const BlockContainer = styled.div`
  margin: 20px 0;
`

export const CompanyProfileTagline = styled.div`
  font-weight: bold;
  margin-bottom: 20px;
`

export const NoJobsContainer = styled.div`
  font-family: 'NeutrafaceBold';
  font-size: 18px;
  margin: 20px 0;
  text-align: center;
`
