import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import * as Styled from 'jobDescriptionFullApplyForm_styled'
import { Accordion, Form } from 'semantic-ui-react'
import moment from 'moment'
import 'rc-time-picker/assets/index.css'
import AnimateHeight from 'react-animate-height'
import {
  changeQuestionExpand,
  saveTrumanQuestionsInformation,
  saveSubmitRequirements,
} from '../../../../../../../../actions/jobApplication_actions'

class TimeAvailabilityQuestion extends Component {
  state = {
    startTime: 0,
    morningTime: 8,
    eveningTime: 18,
    endTime: 24,
    anytime: [
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
      'Sunday',
    ],
    weekdays: [
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
    ],
    weekends: [
      'Saturday',
      'Sunday',
    ],
    inputSuccessCheck: {
      timeInputs: false,
    },
  }

  componentDidMount() {
    this.checkOptionalConditions()
  }

  componentDidUpdate(prevProps) {
    const { questionData, answers } = this.props

    const checkChangedInputs = () => {
      /* Compare previous state and current for all monitored inputs and if its changed we
      call the function */
      /* -----timeInputs------ */
      const timeInputs =
        JSON.stringify(
          prevProps.answers[questionData.question_id].time_availability
        ) !==
        JSON.stringify(answers[questionData.question_id].time_availability)

      /* -----timeInputs END------ */

      /* In parenthesis we put all the inputs that could have changed and if any of them has
      we call the function */
      if (timeInputs) {
        this.checkOptionalConditions()
      }
    }

    checkChangedInputs()
  }

  checkOptionalConditions = () => {
    const { questionData, answers, answersRequirements } = this.props

    /* Make conditions when specific inputs are considered true and successfully filled out */
    /* --------------------- */
    let timeInputs = false
    if (
      answers[questionData.question_id].time_availability &&
      answers[questionData.question_id].time_availability.length > 0
    ) {
      timeInputs = true
    }
    /* --------------------- */

    /* Set new state after checking conditions for the inputs */
    this.setState(
      {
        inputSuccessCheck: {
          ...this.state.inputSuccessCheck,
          timeInputs,
        },
      },
      () => {
        /* For questions that are not optional every input needs to be filled out correctly in order for us to be able to submit. */
        if (answersRequirements[questionData.question_id].optional === false) {
          /* We turn the object into array of boolean values */
          const inputSuccessList = Object.values(this.state.inputSuccessCheck)

          let submitReady = true
          /* If one of the values in the array is false, it means one of the inputs is not filled correctly and we set submitReady to false.
          Until submit ready is true we can not submit application. */
          for (let i = 0; i < inputSuccessList.length; i += 1) {
            if (inputSuccessList[i] === false) {
              submitReady = false
              break
            }
          }
          /* We save if the question is submitReady or not to redux store */
          this.props.saveSubmitRequirements(
            questionData.question_id,
            submitReady
          )
        }
        /* If question is optional inputs don't need to be filled out in order for us to submit so we set submitReady to true in redux store */
        if (answersRequirements[questionData.question_id].optional === true) {
          this.props.saveSubmitRequirements(questionData.question_id, true)
        }
      }
    )
  }

  handleQuestionExpand = (questionType) => {
    const { questionData } = this.props
    this.props.changeQuestionExpand({
      questionType,
      id: questionData.question_id,
    })
  }

  checkQuestionDesign(property) {
    const {
      firstTimeSubmitButtonClicked,
      answersRequirements,
      questionData,
    } = this.props
    const { inputSuccessCheck } = this.state

    /* Depending if input or some other element on the page is filled out correctly, wrongly or nothing we change design */

    let warning
    let success
    let neutral

    const returnDesignPattern = () => {
      if (warning) {
        return warning
      }

      if (success) {
        return success
      }

      return neutral
    }

    /* Checks for which design we are gonna use on which element */
    /* ---------------------------------------------- */
    /* We show warning design only after submit button is clicked for the first TimeRanges,
    if question is not optional and if one of the inputs is not filled out correctly */
    const checkQuestionContainerWarning = () => {
      if (
        firstTimeSubmitButtonClicked &&
        !answersRequirements[questionData.question_id].optional &&
        Object.keys(inputSuccessCheck).some((k) => !inputSuccessCheck[k]) // check if any of the property in inputSuccessCheck is false
      ) {
        return 'warning'
      }
    }

    /* Check the whole question container design. If all the inputs are filled out correctly we
    show success design */

    const checkQuestionContainerSuccess = () => {
      if (
        Object.keys(inputSuccessCheck).every(
          (k) => inputSuccessCheck[k] === true
        )
      ) {
        return 'success'
      }
    }
    /* ---------------------------------------------- */

    /* based on the input that we are checking we are passing that property to check design */
    switch (property) {
      case 'questionContainer':
        warning = checkQuestionContainerWarning()
        success = checkQuestionContainerSuccess()
        neutral = 'neutral'

        return returnDesignPattern()
      default:
        return returnDesignPattern()
    }
  }

  /* HANDLE INPUT TIME DROPDOWN CHANGE */
  /* ************************************************* */
  handleTimeChange(value, day, timeFromTo) {
    const { questionData, answers, t } = this.props

    /* Format moment to normal number */
    let timeValue = Number(value.format('HH'))

    /* If user selects 12am that is 0 so if he selects 12am - 12am it should say 0 - 24 */
    if (timeFromTo === 'time_to' && timeValue === 0) {
      timeValue = this.state.endTime
    }

    /* copy array  */
    const timeAvailability = answers[
      questionData.question_id
    ].time_availability.slice()

    /* Check if we already added time to the input for the current day */
    const index = timeAvailability.findIndex(
      (dayIndex) => dayIndex.day_of_week === day
    )

    /* If the specific day already exists we change the selected time */
    if (index >= 0) {
      if (
        timeFromTo === 'time_from' &&
        timeValue >= timeAvailability[index].time_to
      ) {
        alert(t('incorrectTimeAvailability'))
      } else if (
        timeFromTo === 'time_to' &&
        timeValue <= timeAvailability[index].time_from
      ) {
        alert(t('incorrectTimeAvailability'))
      } else {
        timeAvailability[index].day_of_week = day
        timeAvailability[index][timeFromTo] = timeValue
      }
    } else {
      /* If there is still no current day we cerate it and push it to array. So user wouldn't forget to fill out the
      next input we add it automatically so it's one hour more then selected time */
      timeAvailability.push({
        day_of_week: day,
        time_from: timeFromTo === 'time_from' ? timeValue : timeValue - 1,
        time_to: timeFromTo === 'time_to' ? timeValue : timeValue + 1,
      })
    }

    const propertyValue = {
      time_availability: timeAvailability,
    }

    /* Save data to redux store */
    this.props.saveTrumanQuestionsInformation(
      questionData.question_id,
      propertyValue
    )
  }

  /* HANDLE ALL DAY CHECKBOX CHANGE */
  /* ************************************************* */
  handleAllDayChange(day) {
    const { questionData, answers } = this.props
    const { startTime, endTime, morningTime, eveningTime } = this.state

    /* copy array  */
    const timeAvailability = answers[
      questionData.question_id
    ].time_availability.slice()

    /* Check if we already selected the current day */
    const index = timeAvailability.findIndex(
      (dayIndex) => dayIndex.day_of_week === day
    )

    /* If the specific day already exists we change the selected time */
    if (index >= 0) {
      /* If "All day" is already active 0 - 24 and we click "All day" checkbox we change it to some other value */
      if (
        timeAvailability[index].time_from === startTime &&
        timeAvailability[index].time_to === endTime
      ) {
        timeAvailability[index].time_from = morningTime
        timeAvailability[index].time_to = eveningTime
      } else {
        /* If "All day" is not active we change time to 0 - 24 */
        timeAvailability[index].time_from = startTime
        timeAvailability[index].time_to = endTime
      }
    } else {
      /* If there is still no current day we cerate it and push it to timeAvailability array */
      timeAvailability.push({
        day_of_week: day,
        time_from: startTime,
        time_to: endTime,
      })
    }

    const propertyValue = {
      time_availability: timeAvailability,
    }

    this.props.saveTrumanQuestionsInformation(
      questionData.question_id,
      propertyValue
    )
  }

  /* HANDLE SPECIFIC DAY CHECKBOX CHANGE */
  /* ************************************************* */
  handleSpecificDayChange(day) {
    const { questionData, answers } = this.props
    const { morningTime, eveningTime } = this.state

    /* copy array  */
    const timeAvailability = answers[
      questionData.question_id
    ].time_availability.slice()

    /* Check if we already have time in input for the selected day */
    const index = timeAvailability.findIndex(
      (dayIndex) => dayIndex.day_of_week === day
    )

    /* If the specific day already exists remove it from array after unchecking the checkbox */
    if (index >= 0) {
      timeAvailability.splice(index, 1)
    } else {
      /* If specific day doesn't exist we put some default values and add it to array */
      timeAvailability.push({
        day_of_week: day,
        time_from: morningTime,
        time_to: eveningTime,
      })
    }

    const propertyValue = {
      time_availability: timeAvailability,
    }

    this.props.saveTrumanQuestionsInformation(
      questionData.question_id,
      propertyValue
    )
  }

  /* HANDLE CHANGING PREDEFINED DAYS LIKE WEEKDAYS, WEEKENDS... */
  /* ********************************************************* */
  handlePredefinedDaysChange(predefinedDay) {
    const { questionData } = this.props
    const {
      startTime,
      endTime,
      morningTime,
      eveningTime,
      anytime,
      weekdays,
      weekends,
    } = this.state

    /* Put days that correspond the selected predefined option into this array */
    const timeAvailability = []

    switch (predefinedDay) {
      case 'weekdays':
        weekdays.forEach((day) => {
          timeAvailability.push({
            day_of_week: day,
            time_from: morningTime,
            time_to: eveningTime,
          })
        })
        break

      case 'weeknights':
        weekdays.forEach((day) => {
          timeAvailability.push({
            day_of_week: day,
            time_from: eveningTime,
            time_to: endTime,
          })
        })
        break

      case 'weekends':
        weekends.forEach((day) => {
          timeAvailability.push({
            day_of_week: day,
            time_from: morningTime,
            time_to: eveningTime,
          })
        })
        break
      case 'anytime':
        anytime.forEach((day) => {
          timeAvailability.push({
            day_of_week: day,
            time_from: startTime,
            time_to: endTime,
          })
        })
        break
      default:
        return 'success'
    }

    const propertyValue = {
      time_availability: timeAvailability,
    }

    /* Save new array of active days to redux */
    return this.props.saveTrumanQuestionsInformation(
      questionData.question_id,
      propertyValue
    )
  }

  /* CHECK IF INPUT VALUE EXISTS AND RETURN IT */
  /* ********************************************************* */
  checkTimeInputValue(day, timeFromTo) {
    const { questionData, answers } = this.props

    let time = null
    if (
      answers[questionData.question_id] &&
      answers[questionData.question_id].time_availability.length > 0
    ) {
      /* We check the time in input. If it exists and it's not null we format it using moment and return it to input value */
      answers[questionData.question_id].time_availability.map((timeDay) => {
        if (timeDay.day_of_week === day && timeDay[timeFromTo] !== null) {
          time = moment(timeDay[timeFromTo], 'HH')
        }
      })
    }
    return time
  }

  /* CHECK IF ALL DAY CHECKBOX SHOULD BE CHECKED OR NOT */
  /* ********************************************************* */
  checkIfAllDayChecked(day) {
    const { questionData, answers } = this.props
    const { startTime, endTime } = this.state

    let checked = false
    if (
      answers[questionData.question_id] &&
      answers[questionData.question_id].time_availability.length > 0
    ) {
      /* If time is 0 (time_from) to 24 (time_to) then we check all day checkbox */
      answers[questionData.question_id].time_availability.map((timeDay) => {
        if (
          timeDay.day_of_week === day &&
          timeDay.time_from === startTime &&
          timeDay.time_to === endTime
        ) {
          checked = true
        }
      })
    }
    return checked
  }

  /* CHECK IF SPECIFIC DAY CHECKBOX SHOULD BE CHECKED OR NOT */
  /* ********************************************************* */
  checkIfSpecificDayChecked(day) {
    const { questionData, answers } = this.props

    let checked = false
    if (
      answers[questionData.question_id] &&
      answers[questionData.question_id].time_availability.length > 0
    ) {
      /* If time_from and time_to is 0 or bigger the "All day" checkbox gets checked */
      answers[questionData.question_id].time_availability.map((timeDay) => {
        if (
          timeDay.day_of_week === day &&
          timeDay.time_from >= 0 &&
          timeDay.time_to >= 0
        ) {
          checked = true
        }
      })
    }
    return checked
  }

  /* CHECK PREDEFINED DAYS DESIGN */
  /* ********************************************************* */
  checkPredefinedDaysDesign(predefinedDay) {
    const { questionData, answers } = this.props

    const {
      startTime,
      endTime,
      morningTime,
      eveningTime,
      anytime,
      weekdays,
      weekends,
    } = this.state

    let predefinedNotSelected = true
    switch (predefinedDay) {
      case 'weekdays':
        if (answers[questionData.question_id].time_availability.length === 5) {
          /* If everything is ok predefinedNotSelected will be false. If one of the conditions is not filled out it will become true */
          predefinedNotSelected = answers[
            questionData.question_id
          ].time_availability.some(
            (day) =>
              weekdays.includes(day.day_of_week) === false ||
              day.time_from !== morningTime ||
              day.time_to !== eveningTime
          )
        }
        return predefinedNotSelected === true ? 'neutral' : 'success'

      case 'weeknights':
        if (answers[questionData.question_id].time_availability.length === 5) {
          predefinedNotSelected = answers[
            questionData.question_id
          ].time_availability.some(
            (day) =>
              weekdays.includes(day.day_of_week) === false ||
              day.time_from !== eveningTime ||
              day.time_to !== endTime
          )
        }
        return predefinedNotSelected === true ? 'neutral' : 'success'

      case 'weekends':
        if (answers[questionData.question_id].time_availability.length === 2) {
          predefinedNotSelected = answers[
            questionData.question_id
          ].time_availability.some(
            (day) =>
              weekends.includes(day.day_of_week) === false ||
              day.time_from !== morningTime ||
              day.time_to !== eveningTime
          )
        }
        return predefinedNotSelected === true ? 'neutral' : 'success'

      case 'anytime':
        if (answers[questionData.question_id].time_availability.length === 7) {
          predefinedNotSelected = answers[
            questionData.question_id
          ].time_availability.some(
            (day) =>
              anytime.includes(day.day_of_week) === false ||
              day.time_from !== startTime ||
              day.time_to !== endTime
          )
        }
        return predefinedNotSelected === true ? 'neutral' : 'success'

      default:
        return 'neutral'
    }
  }

  checkQuestionExpandState() {
    const { questionExpand, questionData } = this.props
    let questionState = false
    if (questionExpand[this.props.questionType].length > 0) {
      const filteredQuestion = questionExpand[this.props.questionType].filter(
        (question) => {
          if (question.id === questionData.question_id) {
            return question
          }
        }
      )
      questionState = filteredQuestion[0].expand
    }
    return questionState
  }

  renderPredefinedTimes() {
    const { t } = this.props
    return (
      <Fragment>
        <Styled.PredefinedTimes
          onClick={() => this.handlePredefinedDaysChange('weekdays')}
          customcolor={this.checkPredefinedDaysDesign('weekdays')}
        >
          {t('weekdays')}
        </Styled.PredefinedTimes>
        <Styled.PredefinedTimes
          onClick={() => this.handlePredefinedDaysChange('weeknights')}
          customcolor={this.checkPredefinedDaysDesign('weeknights')}
        >
          {t('weeknights')}
        </Styled.PredefinedTimes>
        <Styled.PredefinedTimes
          onClick={() => this.handlePredefinedDaysChange('weekends')}
          customcolor={this.checkPredefinedDaysDesign('weekends')}
        >
          {t('weekends')}
        </Styled.PredefinedTimes>
        <Styled.PredefinedTimes
          onClick={() => this.handlePredefinedDaysChange('anytime')}
          customcolor={this.checkPredefinedDaysDesign('anytime')}
        >
          {t('anytime')}
        </Styled.PredefinedTimes>
      </Fragment>
    )
  }

  renderTimeDays() {
    const { t } = this.props
    const timeDays = [
      { nameToShow: t('Monday'), value: 'Monday' },
      { nameToShow: t('Tuesday'), value: 'Tuesday' },
      { nameToShow: t('Wednesday'), value: 'Wednesday' },
      { nameToShow: t('Thursday'), value: 'Thursday' },
      { nameToShow: t('Friday'), value: 'Friday' },
      { nameToShow: t('Saturday'), value: 'Saturday' },
      { nameToShow: t('Sunday'), value: 'Sunday' },
    ]

    return timeDays.map((day) => (
      <Styled.TimeFormGroup key={day.value}>
        <Form.Field>
          <Styled.CheckboxDay
            label={day.nameToShow.toUpperCase()}
            onClick={() => this.handleSpecificDayChange(day.value)}
            checked={this.checkIfSpecificDayChecked(day.value)}
          />
        </Form.Field>

        <Styled.TimeInputFormField>
          <Styled.TimePickerComponent
            onChange={(value) => this.handleTimeChange(value, day.value, 'time_from')}
            use12Hours
            allowEmpty={false}
            showMinute={false}
            showSecond={false}
            popupStyle={{ width: '115px' }}
            value={this.checkTimeInputValue(day.value, 'time_from')}
          />
        </Styled.TimeInputFormField>

        <Form.Field>{t('to')}</Form.Field>

        <Styled.TimeInputFormField>
          <Styled.TimePickerComponent
            onChange={(value) => this.handleTimeChange(value, day.value, 'time_to')}
            use12Hours
            allowEmpty={false}
            showMinute={false}
            showSecond={false}
            popupStyle={{ width: '115px' }}
            value={this.checkTimeInputValue(day.value, 'time_to')}
          />
        </Styled.TimeInputFormField>

        <Form.Field>
          <Styled.CheckboxAllDay
            label={t('allDay')}
            onClick={() => this.handleAllDayChange(day.value)}
            checked={this.checkIfAllDayChecked(day.value)}
          />
        </Form.Field>
      </Styled.TimeFormGroup>
    ))
  }

  render() {
    const { questionData, answersRequirements, t } = this.props
    return (
      <Styled.QuestionContainer
        statedesign={this.checkQuestionDesign('questionContainer')}
      >
        <Accordion>
          <Styled.AccordionTitle
            active={this.checkQuestionExpandState()}
            onClick={() => this.handleQuestionExpand(this.props.questionType)}
          >
            <Styled.AccordionTitleTextContainer>
              <Styled.QuestionIconBorder>
                <Styled.QuestionIcon name="clock outline" />{' '}
              </Styled.QuestionIconBorder>

              <Styled.AccordionTitleText>
                {questionData.question_text}
                <br />
                {answersRequirements[questionData.question_id].optional ? (
                  <span>({t('optional')})</span>
                ) : null}
              </Styled.AccordionTitleText>
            </Styled.AccordionTitleTextContainer>

            {this.checkQuestionExpandState() ? (
              <Styled.ExpandIcon name="chevron circle up" />
            ) : (
              <Styled.ExpandIcon name="chevron circle down" />
            )}
          </Styled.AccordionTitle>
          <Styled.AccordionContent active={this.checkQuestionExpandState()}>
            <AnimateHeight
              animateOpacity
              duration={300}
              height={this.checkQuestionExpandState() ? 'auto' : 0}
            >
              <Styled.PredefinedTimesContainer>
                {this.renderPredefinedTimes()}
              </Styled.PredefinedTimesContainer>
              <Styled.QuestionBlock>
                {this.renderTimeDays()}
              </Styled.QuestionBlock>
            </AnimateHeight>
          </Styled.AccordionContent>
        </Accordion>
      </Styled.QuestionContainer>
    )
  }
}

const mapStateToProps = (state) => ({
  answers: state.jobApplicationReducer.answers,
  answersRequirements:
    state.jobApplicationReducer.submitRequirements.answersRequirements,
  firstTimeSubmitButtonClicked:
    state.jobApplicationReducer.submitRequirements.firstTimeSubmitButtonClicked,
  questionExpand: state.jobApplicationReducer.questionExpand,
})

TimeAvailabilityQuestion.propTypes = {
  answers: PropTypes.object, // comes from parent 'jobApplicationReducer'. Contains all the answers for the questions coming from truman, type of the question and id.
  answersRequirements: PropTypes.object, // comes from parent 'jobApplicationReducer'. Contains if questions coming from truman are optional or not. It also contains submitReady property that tells us if specific question is filled out ok.
  changeQuestionExpand: PropTypes.func, // used to expand or collapse question accordion.
  firstTimeSubmitButtonClicked: PropTypes.bool, // if true it means that "Submit" form button was clicked at least once.
  questionData: PropTypes.object, // passed down from parent 'JobDescriptionFullApplyFormQuestions' component. It contains all the data about that specific question coming from truman.
  questionExpand: PropTypes.object, // contains all the questions and tells if question accordion is expanded or not.
  questionType: PropTypes.string, // passed down from parent 'JobDescriptionFullApplyFormQuestions' component. It says which questionType question belongs to.
  saveSubmitRequirements: PropTypes.func, // Used to save if the question is submitReady or not to redux store.
  saveTrumanQuestionsInformation: PropTypes.func, // used to save user answers to redux store for every question.
}

export default connect(mapStateToProps, {
  changeQuestionExpand,
  saveSubmitRequirements,
  saveTrumanQuestionsInformation,
})(withTranslation('jobDescriptionFullApply')(TimeAvailabilityQuestion))
