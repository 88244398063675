import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const JobsListContainer = styled.div`
  height: 800px;
  max-height: 800px;
  min-height: 200px;
  overflow: auto;
  width: 100%;
  .threshold-fix {
    height: calc(100% + 1px);
  }
  ${({ allTeamsSearch }) => allTeamsSearch && `
    padding: 2rem;
    border-left: 2px solid #795548;
  `}
`

export const JobContainer = styled.div.attrs({
  'data-cy': 'job-apply-container',
})`
  align-items: center;
  border-bottom: 1px solid #e0e1e2;
  display: flex;
  justify-content: space-between;
  padding: 20px 20px 20px 0;
  visibility: ${(props) => props.visibility} !important;
`
export const JobInfoContainer = styled.div`
  /* border-bottom: 1px solid #e0e1e2; */
  color: #a5a5a5;
  padding-bottom: 10px;
  width: 70%;
`

export const JobLocationInfo = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 10px 0;
`

export const JobInfoName = styled.a`
  color: #1077e2;
  font-size: 22px;
  font-weight: bold;
  padding-top: 5px;
`
export const JobApplyButton = styled.div`
  background-color: ${(props) => props.customcolor} !important;
  border-radius: 20px !important;
  color: #fff !important;
  font-family: 'NeutrafaceBold';
  padding: 10px;
`

export const FilterJobsContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const JobApploiImageContainer = styled.div`
  display: inline-block !important;
  & span {
    color: black;
    font-family: monospace;
    font-size: 15px;
    margin-left: 5px;
    opacity: 0.6;
  }
`

export const JobApploiImage = styled.img`
  margin-bottom: -7px;
  margin-left: 5px;
  width: 30px;
`

export const JobScoresContainer = styled.div`
  color: black;
  font-family: monospace;
  font-size: 15px;
  opacity: 0.6;
`

export const NoJobsContainer = styled.div`
  font-family: 'NeutrafaceBold';
  font-size: 18px;
  margin: 20px 0;
  text-align: center;
`
