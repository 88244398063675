import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { Grid } from 'semantic-ui-react'
/* Styled Components */
import * as Styled from 'jobDescriptionCompanyData_styled'

const SocialIconsLinks = (props) => (
  <Fragment>
    {props.companyProfile &&
      (props.companyProfile.facebook_link ||
        props.companyProfile.twitter_link ||
        props.companyProfile.instagram_link ||
        props.companyProfile.linkedin_link) && (
        <Styled.InfoText>
          <span>
            {props.companyProfile && props.companyProfile.facebook_link && (
              <a
                href={props.companyProfile.facebook_link}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Styled.SocialIconImage name="facebook f" />
              </a>
            )}
            {props.companyProfile && props.companyProfile.twitter_link && (
              <a
                href={props.companyProfile.twitter_link}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Styled.SocialIconImage name="twitter" />
              </a>
            )}
            {props.companyProfile && props.companyProfile.instagram_link && (
              <a
                href={props.companyProfile.instagram_link}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Styled.SocialIconImage name="instagram" />
              </a>
            )}
            {props.companyProfile && props.companyProfile.linkedin_link && (
              <a
                href={props.companyProfile.linkedin_link}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Styled.SocialIconImage name="linkedin" />
              </a>
            )}
          </span>
        </Styled.InfoText>
      )}
  </Fragment>
)

const mapStateToProps = (state) => ({
  companyProfile: state.companyProfileReducer.companyProfile,
  jobDescription: state.jobDescriptionReducer.jobDescription,
})

export default connect(mapStateToProps, {})(SocialIconsLinks)
