/* eslint-disable no-shadow */
import React, { useEffect, useState } from 'react'
import axios from 'axios'
/* Styled Components */
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import * as Styled from 'citiesFilter_styled'
import {
  setCity, setCityCenter
} from '../../../../actions/global_actions'
import { apiUrl } from '../../../../../constants/urlConstants'
import {
  getCompanyProfileJobsAction,
} from '../../../../actions/api_actions'
import {
  saveCompanyProfileJobsFilters, saveCompanyProfileJobs,
} from '../../../../actions/companyProfile_actions'

export function CitiesFilter(props) {
  const {
    getCompanyProfileJobsAction,
    companyProfileJobsFilters,
    filteredIndustries,
    saveCompanyProfileJobsFilters,
    saveCompanyProfileJobs,
    cityCenter,
    city,
    setCity,
    setCityCenter,
    backfilledJobs,
    debug,
    language,
    utm,
  } = props

  const centerCities = [
    {
      key: 1,
      text: 'Houston, Texas',
      value: '29.7604267,-95.3698028',
    },
    {
      key: 2,
      text: 'Chicago, Illinois',
      value: '41.8781136,-87.6297982',
    },
    {
      key: 3,
      text: 'New York City, New York',
      value: '40.712776,-74.005974',
    },
    {
      key: 4,
      text: 'Miami, Florida',
      value: '25.761681,-80.191788',
    },
    {
      key: 5,
      text: 'Los Angeles, California',
      value: '34.052235,-118.243683',
    },
    {
      key: 6,
      text: 'Dallas, Texas',
      value: '32.776665,-96.796989',
    },
    {
      key: 7,
      text: 'Atlanta, Georgia',
      value: '33.748550,-84.391502',
    },
    {
      key: 8,
      text: 'Austin, Texas ',
      value: '30.264980,-97.746597',
    },
    {
      key: 9,
      text: 'Philadelphia, Pennsylvania',
      value: '39.951061,-75.165619',
    },
    {
      key: 10,
      text: 'Phoenix, Arizona',
      value: '33.448200,-112.072578',
    },
    {
      key: 11,
      text: 'San Antonio, Texas',
      value: '29.425171,-98.494614',
    },
    {
      key: 12,
      text: 'San Francisco, California',
      value: '37.774929,-122.419418',
    },
    {
      key: 14,
      text: 'Tampa, Florida',
      value: '27.947821,-82.454528',
    },
    {
      key: 15,
      text: 'Orlando, Florida',
      value: '28.538407,-81.378941',
    },

    {
      key: 16,
      text: 'Fresno, California',
      value: '6.737797,-119.787125',
    },
    {
      key: 17,
      text: 'Bakersfield, California',
      value: '5.373291,-119.018715',
    },
    {
      key: 18,
      text: 'Sacramento, California',
      value: '8.581573,-121.494400',
    },
    {
      key: 19,
      text: 'Raleigh, North Carolina',
      value: '35.779591,-78.638176',
    },
    {
      key: 20,
      text: 'Philadelphia, Pennsylvania',
      value: '39.952583,-75.165222',
    },
    {
      key: 21,
      text: 'Salt Lake City, Utah',
      value: '0.760780,-111.891045',
    },
    {
      key: 22,
      text: 'San Diego, California',
      value: '2.715736,-117.161087',
    },
    {
      key: 23,
      text: 'Washington D.C.',
      value: '38.907192,-77.036873',
    },
  ].sort((a, b) => (a.text > b.text) ? 1 : ((b.text > a.text) ? -1 : 0))

  const { t } = useTranslation('internal')

  const [cityList, setCityList, ] = useState()
  const [actualFilteredIndustries, setIndustriesFilter, ] = useState()
  const [selectedLocation, setLocation, ] = useState()

  const getCities = (location) => {
    setLocation(location)
    const params = {
      location,
      radius: '50',
      industry: filteredIndustries,
    }
    apiUrl(window.activeEnvironment, 'integrations', 'jobCities')
      .then((url) => axios.get(`${url}`, { params }, { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${(localStorage ? (localStorage.getItem('token') ? JSON.parse(localStorage.getItem('token')).value : '') : '')}` } })
        .then((response) => {
          // handle success
          const data = response?.data?.data
          setCityList([
            {
              key: 1,
              text: 'All',
              value: location,
            },
            ...data.map((cityValue) => {
              const cityName = `${cityValue.city}, ${cityValue.state}`
              const cityText = `${cityValue.city}, ${cityValue.state} (${cityValue.amount_jobs})`
              return ({
                key: cityName,
                text: cityText,
                value: `${cityName},${cityValue.lat},${cityValue.long}`,
              })
            })
            .sort((a, b) => (a.text > b.text) ? 1 : ((b.text > a.text) ? -1 : 0)),
          ])
          // return { data: response, type: 'saveCompanyProfileJobs' }
        })
        .catch((error) => {
          // handle error
          console.log('ERROR OPEN POSITIONS', error)
          return error
        }))
  }

  useEffect(() => {
    if (actualFilteredIndustries !== filteredIndustries) {
      getCities(selectedLocation)
      setIndustriesFilter(filteredIndustries)
    }
  }, [filteredIndustries,])

  const onChangeCity = (event, data) => {
    const { value: argValue } = data
    const { target } = event
    let text = target.textContent
    let radius = null
    const cityState = text.split(',')
    let cityName
    let stateName
    if (target.textContent === 'All') {
      text = centerCities.find((cc) => cc.value === argValue).text
      radius = '50'
      cityName = null
      stateName = null
    } else {
      cityName = cityState[0]
      stateName = cityState[1].split('(')[0].replace(' ', '')
    }
    const location = argValue.split(',')
    let lat = ''
    let lng = ''
    if (target.textContent === 'All') {
      lat = location[0]
      lng = location[1]
    } else {
      lat = null
      lng = null
    }
    setCity({
      text,
      value: argValue,
      city: cityName,
      state: stateName,
    })
    saveCompanyProfileJobsFilters({
      address: `${cityName}, ${stateName}`,
      lat,
      lng,
      radius,
    })
    saveCompanyProfileJobs([])
    getCompanyProfileJobsAction(
      window.activeEnvironment,
      1,
      companyProfileJobsFilters?.searchbar,
      lat,
      lng,
      '',
      filteredIndustries,
      backfilledJobs,
      debug,
      language,
      cityName,
      stateName,
      radius,
      utm,
    )
  }

  const onChangeCityCenter = (event, data) => {
    const { value: argValue } = data
    const { target } = event
    const cityState = target.textContent.split(',')
    const cityName = cityState[0]
    const stateName = cityState[1].replace(' ', '')
    const location = argValue.split(',')
    const lat = location[0]
    const lng = location[1]
    const radius = '50'
    setCityCenter({
      text: target.textContent,
      value: argValue,
    })
    saveCompanyProfileJobsFilters({
      address: target.textContent,
      lat,
      lng,
      radius,
    })
    saveCompanyProfileJobs([])
    getCompanyProfileJobsAction(
      window.activeEnvironment,
      1,
      companyProfileJobsFilters?.searchbar,
      lat,
      lng,
      '',
      filteredIndustries,
      backfilledJobs,
      debug,
      language,
      null,
      null,
      radius,
      utm,
    )

    setCityList(null)
    getCities(argValue)
    setLocation(argValue)
  }

  return (
    <Styled.IndustriesFilterContainer data-testid="cities-filter-container">
      <Styled.IndustriesHeaderTitle>
        {t('cityFilterTitle')}
      </Styled.IndustriesHeaderTitle>
      <Styled.FilterContainer>
        <Styled.FilterDropdown
          data-testid="cities-filter-select"
          onChange={onChangeCityCenter}
          placeholder={t('cityFilterCityCenter')}
          options={centerCities}
          value={cityCenter?.value || ''}
        />
        {`50 ${t('milesFilterCities')}`}
      </Styled.FilterContainer>
      <Styled.FilterContainer>
        {cityList && cityCenter?.value ? (
          <Styled.FilterDropdown
            onChange={onChangeCity}
            // placeholder={t('cityFilterCities')}
            options={cityList}
            value={city?.value || cityCenter?.value}
          />
        ) : cityCenter?.value && (
          <Styled.DropdownLoader active inline size="mini" />
        )}
      </Styled.FilterContainer>
      <Styled.IndustriesBodyCheckboxes />
    </Styled.IndustriesFilterContainer>
  )
}

const mapStateToProps = (state) => ({
  industries: state.globalReducer.industries,
  cityCenter: state.globalReducer.cityCenter,
  city: state.globalReducer.city,
  companyProfileJobsFilters:
  state.companyProfileReducer.companyProfileJobsFilters,
  filteredIndustries: state.globalReducer.filteredIndustries,
})

export default connect(mapStateToProps, {
  setCityCenter,
  setCity,
  getCompanyProfileJobsAction,
  saveCompanyProfileJobsFilters,
  saveCompanyProfileJobs,
})(CitiesFilter)
