import styled from 'styled-components'

export const MapContainer = styled.div``

export const MapLocationText = styled.div`
  font-family: 'OpenSansRegular';
  font-size: 16px;
  margin-bottom: 15px;
`

export const SectionHeader = styled.div`
  font-family: 'Neutraface2TextBold';
  font-size: 18px;
  margin-bottom: 20px;

  @media (min-width: 768px) {
    font-size: 24px;
  }
`
