import styled from 'styled-components'
import { Modal, Header, Form, Button } from 'semantic-ui-react'

export const ModalContainer = styled(Modal)`
  i {
    color: #000 !important;
    top: 1.0535rem !important;
    right: 1rem !important;
  }
`
export const ModalContent = styled(Modal.Content)`
  text-align: center;
  padding: 20px;
  background-color: #f7f3f3 !important;
  color: #000;
  border-radius: 0px !important;
`

export const FormField = styled(Form.Field)`
  width: 100%;
  margin-bottom: 10px !important;

  input {
    border: 1px solid #7c2e97 !important;

    &::placeholder {
      color: #423f3d !important;
    }
  }
`

export const ModalHeader = styled(Header)`
  font-family: 'Neutraface';
  font-size: 18px;
  color: #000 !important;
  margin: 40px 10px 20px 10px !important;
`

export const ModalSmallHeader = styled(Header)`
  font-family: 'Neutraface';
  font-size: 12px !important;
  color: #000 !important;
  margin: 40px 5px 20px 5px !important;
`

export const ButtonModal = styled(Button)`
  background-color: ${(props) => {
    if (props.authentication === 'warning') {
      return '#EF4778'
    }
    if (props.authentication === 'success') {
      return '#8dcea6'
    }
    if (props.authentication === 'neutral') {
      return '#bcbeC0'
    }
  }} !important;
  transition: background-color 0.5s !important;

  :hover {
    background-color: ${(props) => {
      if (props.authentication === 'warning') {
        return '#eb1856'
      }
      if (props.authentication === 'success') {
        return '#69bf8a'
      }
      if (props.authentication === 'neutral') {
        return '#a2a5a7'
      }
    }} !important;
  }

  border-radius: 0 !important;
  color: #fff !important;
  width: 100%;
  margin-bottom: 10px !important;
`

export const ModalActions = styled(Modal.Actions)`
  background-color: #f7f3f3 !important;
  border-radius: 0px !important;
  color: #000;
  display: flex;
  justify-content: space-between;

  &&&& {
    padding: 1rem 1rem !important;
  }
`

export const ModalActionsLinks = styled.div`
  &:hover {
    cursor: pointer;
  }
  &:first-of-type {
    text-decoration: underline;
  }
`

export const ModalActionsLoginLink = styled.div`
&:hover {
  cursor: pointer;
}`

/* Password conditions test */
/* ============================= */

export const PasswordTestText = styled.div`
  font-size: 13px;
  margin: 15px 0;
  text-align: center;
`

export const PasswordLengthTest = styled.span`
  color: ${(props) => {
    if (props.passTest) {
      return '#3eaf6a'
    }
    return '#ea4750'
  }};
`

export const PasswordNumeralTest = styled.span`
  color: ${(props) => {
    if (props.passTest) {
      return '#3eaf6a'
    }
    return '#ea4750'
  }};
`

export const PasswordLowercaseTest = styled.span`
  color: ${(props) => {
    if (props.passTest) {
      return '#3eaf6a'
    }
    return '#ea4750'
  }};
`

export const PasswordUppercaseTest = styled.span`
  color: ${(props) => {
    if (props.passTest) {
      return '#3eaf6a'
    }
    return '#ea4750'
  }};
`
/* Wrong input warning message */
/* ============================= */
export const PopupWarning = styled.div`
  color: #ea4750;
  font-size: 13px;
  margin-bottom: 20px;
  margin-top: 10px;
`

/* Password and Email submit test */
/* ============================= */

export const FormInputs = styled.input`
  background-color: ${(props) => {
    if (props.passTest === false) {
      return '#fad0d3'
    }
  }} !important;
  border-radius: 0px !important;
  outline: ${(props) => {
    if (props.passTest === false) {
      return '1px solid #ea4750'
    }
  }} !important;
`
