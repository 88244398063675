import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Modal, Icon } from 'semantic-ui-react'
import { withTranslation } from 'react-i18next'
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
} from 'react-share'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import * as Styled from 'shareModal_styled'
import {
  getShortenJobDescriptionUrlAction,
  getShortenCompanyUrlAction,
} from '../../../../actions/api_actions'
import { closeShareModal } from '../../../../actions/modals_actions'
import spinner from '../../../../../../public/assets/img/spinner.gif'
/* Images */

/* If we send `null` instead of some props, this will not be rendered.
If we send `null` instead of `timeout` prop then modal will not get close automatically.
We can only close it manually. */

class ShareModal extends Component {
  state = {
    showTooltip: false,
    type: null,
  }

  UNSAFE_componentWillMount() {
    if (this.props.routeData && this.props.routeData.key) {
      switch (this.props.routeData.key) {
        case 'companyProfile':
          this.setState(
            {
              type: 'shortenUrl',
            },
            () => {
              this.props.getShortenCompanyUrlAction(
                window.activeEnvironment,
                this.props.matchData.params.slug,
                window.location.protocol,
                window.location.hostname
              )
            }
          )
          break

        case 'jobDescription':
          this.setState(
            {
              type: 'shortenJobDescriptionUrl',
            },
            () => {
              this.props.getShortenJobDescriptionUrlAction(
                window.activeEnvironment,
                this.props.matchData.params.slug,
                window.location.protocol,
                window.location.hostname
              )
            }
          )
          break
        default:
      }
    }
  }

  renderTooltip = () => {
    this.setState(
      {
        showTooltip: true,
      },
      () => {
        setTimeout(
          () =>
            this.setState({
              showTooltip: false,
            }),
          3000
        )
      }
    )
  }

  render() {
    const { companyProfile, t } = this.props

    return (
      <Styled.ModalContainer
        size="tiny"
        open
        onClose={this.props.closeShareModal}
      >
        <Styled.CloseIcon
          name="close"
          onClick={() => this.props.closeShareModal()}
        />
        <Styled.ModalContent>
          <Modal.Description>
            <Styled.SocialInput
              label={t('copyLink')}
              action={
                <CopyToClipboard
                  text={
                    this.props[this.state.type] &&
                    this.props[this.state.type].website
                      ? this.props[this.state.type].website
                      : ''
                  }
                >
                  <Styled.CopyButton
                    icon
                    onClick={() => this.renderTooltip()}
                    customcolor={
                      companyProfile && companyProfile.primary_color
                        ? companyProfile.primary_color
                        : '#000'
                    }
                  >
                    <Icon name="copy outline" size="large" />
                    {this.state.showTooltip && (
                      <span>{t('copied')}</span>
                    )}
                  </Styled.CopyButton>
                </CopyToClipboard>
              }
              value={
                this.props[this.state.type] &&
                this.props[this.state.type].website
                  ? this.props[this.state.type].website
                  : ''
              }
              color="#fab03f"
            />

            {/* Load social share button when we get back all the shorten links */}
            {this.props[this.state.type] &&
            this.props[this.state.type].facebook &&
            this.props[this.state.type].twitter &&
            this.props[this.state.type].linkedin ? (
              <Styled.SocialButtons>
                <FacebookShareButton
                  url={
                    this.props[this.state.type] &&
                    this.props[this.state.type].facebook
                      ? this.props[this.state.type].facebook
                      : ''
                  }
                >
                  <FacebookIcon size={45} round />
                </FacebookShareButton>

                {/* twitter scraper doesn't work without https */}
                <TwitterShareButton
                  url={
                    this.props[this.state.type] &&
                    this.props[this.state.type].twitter
                      ? `https://${this.props[this.state.type].twitter}`
                      : ''
                  }
                >
                  <TwitterIcon size={45} round />
                </TwitterShareButton>

                <LinkedinShareButton
                  url={
                    this.props[this.state.type] &&
                    this.props[this.state.type].linkedin
                      ? this.props[this.state.type].linkedin
                      : ''
                  }
                >
                  <LinkedinIcon size={45} round />
                </LinkedinShareButton>
              </Styled.SocialButtons>
            ) : (
              <img src={spinner} width="50" />
            )}
          </Modal.Description>
        </Styled.ModalContent>
      </Styled.ModalContainer>
    )
  }
}

const mapStateToProps = (state) => ({
  routeData: state.globalReducer.routeData,
  matchData: state.globalReducer.matchData,
  companyProfile: state.companyProfileReducer.companyProfile,
  shortenUrl: state.companyProfileReducer.shortenUrl,
  shortenJobDescriptionUrl:
    state.jobDescriptionReducer.shortenJobDescriptionUrl,
  jobDescription: state.jobDescriptionReducer.jobDescription,
})

ShareModal.propTypes = {
  closeShareModal: PropTypes.func,
}

export default connect(mapStateToProps, {
  closeShareModal,
  getShortenJobDescriptionUrlAction,
  getShortenCompanyUrlAction,
})(withTranslation('shareModal')(ShareModal))
