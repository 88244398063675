import styled from 'styled-components'
import { Modal, Header } from 'semantic-ui-react'

export const TimeoutMessageModal = styled(Modal)`
  min-height: 100px;

  max-width: 300px !important;
  i {
    top: 0 !important;
    right: 0 !important;
    color: #000 !important;
  }
`
export const TimeoutMessageModalContent = styled(Modal.Content)`
  text-align: center;

  height: 100%;
  width: 100% !important;
  padding: 20px;
  background-color: #f6f3f3 !important;
  color: #000;
  border-radius: 0px !important;
`
export const TimeoutMessageModalDescription = styled(Modal.Description)`
  height: 100%;
  display: flex !important;
  flex-direction: column;
`

export const TimeoutMessageModalHeader = styled(Header)`
  font-family: 'Neutraface';
  font-size: 18px;
  color: #000 !important;
  flex-grow: 1;
  padding-top: 40px !important;
`

export const TimeoutMessageModalBody = styled.div`
  flex-grow: 2;
  font-family: 'OpenSansRegular';
  padding: 20px 0;
`

export const TimeoutMessageModalFooter = styled.div`
  font-family: 'OpenSansRegular';

  a {
    color: #000;
    text-decoration: underline;

    &:hover {
      color: #000;
      font-weight: bold;
      text-decoration: underline;
    }
  }
`
