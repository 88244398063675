/* eslint-disable no-shadow */
import React, { useEffect, useState } from 'react'
/* Styled Components */
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import * as Styled from 'industriesFilter_styled'
import {
  getIndustriesAction,
  getCompanyProfileJobsAction
} from '../../../../actions/api_actions'
import {
  saveFilteredIndustries
} from '../../../../actions/global_actions'

export function IndustriesFilter(props) {
  const {
    getIndustriesAction,
    industries,
    saveFilteredIndustries,
    companyProfileJobsFilters,
    backfilledJobs,
    debug,
    getCompanyProfileJobsAction,
    language,
    city,
    utm,
    filteredIndustries,
  } = props

  const { t } = useTranslation('internal')

  const [industriesCheckboxes, setIndustriesCheckboxes,] = useState()
  const hasSelectedCity = !!(city && city.city && city.state)
  useEffect(() => {
    getIndustriesAction(
      window.activeEnvironment,
      hasSelectedCity ? city?.city : null,
      hasSelectedCity ? city?.state : null,
      (!hasSelectedCity && companyProfileJobsFilters?.lat && companyProfileJobsFilters?.lng) ?
        `${companyProfileJobsFilters?.lat}, ${companyProfileJobsFilters?.lng}` :
        null,
      !hasSelectedCity ? companyProfileJobsFilters?.radius : null
    )
  }, [
    getIndustriesAction,
    city?.city,
    city?.state,
    companyProfileJobsFilters?.lat,
    companyProfileJobsFilters?.lng,
    companyProfileJobsFilters?.radius,
  ])

  useEffect(() => {
    setIndustriesCheckboxes(industries?.map((industry, index) => {
      const selectedInduestries = filteredIndustries ? filteredIndustries.split(',') : []
      const isSelected = !!selectedInduestries.find((selected) => selected === industry.en)
      return {
        id: index,
        value: isSelected,
        name: industry,
        count: industry.count
      }
    }))
  }, [industries,])

  const onChangeCheckbox = (id, defaultVal = null) => {
    const newIndustriesCheckboxes = industriesCheckboxes.map((industry) => (industry.id === id ?
      {
        ...industry,
        value: defaultVal !== null ? defaultVal : !industry.value,
      } : {
        ...industry,
        value: defaultVal !== null ? defaultVal : industry.value,
      }))
    setIndustriesCheckboxes(newIndustriesCheckboxes)
    const filteredIndustries = newIndustriesCheckboxes?.filter((industry) => industry.value === true)
      .map((industry) => industry.name.en)
      .join(',')
    saveFilteredIndustries(filteredIndustries)
    const hasSelectedCity = !!(city && city.city && city.state)

    getCompanyProfileJobsAction(
      window.activeEnvironment,
      1,
      companyProfileJobsFilters?.searchbar,
      !hasSelectedCity ? companyProfileJobsFilters?.lat : null,
      !hasSelectedCity ? companyProfileJobsFilters?.lng : null,
      '',
      filteredIndustries,
      backfilledJobs,
      debug,
      language,
      city?.city,
      city?.state,
      !hasSelectedCity ? companyProfileJobsFilters.radius : null,
      utm,
    )
  }

  return (
    <Styled.IndustriesFilterContainer>
      <Styled.IndustriesHeaderTitle>
        {t('industryFilterTitle')}
      </Styled.IndustriesHeaderTitle>
      <Styled.LabelCheckbox style={{ display: 'flex' }}>
        <Styled.InputCheckbox
          onClick={() => onChangeCheckbox(null, true)}
          value={t('industryFilterSelectAll')}
          type="button"
        />
        <Styled.InputCheckbox
          onClick={() => onChangeCheckbox(null, false)}
          value={t('industryFilterUnselectAll')}
          type="button"
        />
      </Styled.LabelCheckbox>
      <Styled.IndustriesBodyCheckboxes>
        {industriesCheckboxes && industriesCheckboxes
          .sort((a, b) => (a.name[language] > b.name[language]) ? 1 : ((b.name[language] > a.name[language]) ? -1 : 0))
          .map((industry) => (
            <Styled.LabelCheckbox key={industry.id}>
              <Styled.InputCheckbox
                checked={industry.value}
                onChange={() => onChangeCheckbox(industry.id)}
                type="checkbox"
              />
              {industry.name[language]} ({industry.count})
            </Styled.LabelCheckbox>
          ))}
      </Styled.IndustriesBodyCheckboxes>
    </Styled.IndustriesFilterContainer>
  )
}

const mapStateToProps = (state) => ({
  industries: state.globalReducer.industries,
  companyProfileJobsFilters:
  state.companyProfileReducer.companyProfileJobsFilters,
  city: state.globalReducer.city,
  filteredIndustries: state.globalReducer.filteredIndustries,
})

export default connect(mapStateToProps, {
  getIndustriesAction,
  saveFilteredIndustries,
  getCompanyProfileJobsAction,
})(IndustriesFilter)
