import styled from 'styled-components'

export const SectionHeader = styled.div`
  font-family: 'Neutraface2TextBold';
  font-size: 18px;
  margin-bottom: 20px;

  @media (min-width: 768px) {
    font-size: 24px;
  }
`

export const ImageContainer = styled.div`
  @media (max-width: 767px) {
    text-align: center !important;
  }
`

export const ImageWrapper = styled.div`

background-image: url('${(props) => props.link}');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  border: solid 10px #ffffff;
  box-sizing: border-box;;
  display: inline-flex !important;;
  flex-direction: row !important;
  flex-wrap: wrap !important;
  height: 225px;
  width: 225px;

  &:hover {

    cursor: pointer;
    transform: scale(1.03);
  }

@media (min-width: 544px) {
  height: 225px;
    width: 225px;
}

@media (min-width: 768px) {
    height: 150px;
    width: 150px;
  }

  @media (min-width: 1200px) {
    height: 200px;
    width: 200px;
  }
`
