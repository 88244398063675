import axios from 'axios'

export const sanitizeString = (string) => {
  const map = {
    "&": "&amp;",
    "<": "&lt;",
    ">": "&gt;",
    '"': "&quot;",
    "'": "&#x27;",
    "/": "&#x2F;",
  };
  const reg = /[&<>"'/]/gi;
  return string.replace(reg, (match) => map[match]);
};

export const isEmpty = (obj) => {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) return false
  }
  return true
}

export function isFunction(functionToCheck) {
  return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]'
}

export function sanitizeURL(url) {
  let finalUrl = url

  finalUrl = finalUrl.startsWith('/') ?
    finalUrl.substring(1) :
    finalUrl

  finalUrl = finalUrl.endsWith('/') ?
    finalUrl.slice(0, -1) :
    finalUrl

  finalUrl = finalUrl.endsWith('?') ?
    finalUrl.slice(0, -1) :
    finalUrl

  return finalUrl
}

export function getEnvironment() {
  const appName = process.env.HEROKU_APP_NAME

  return appName ? 'development' : process.env.ENVIRONMENT
}

export function getRawURL(url, baseURL) {
  // Remove base URL
  const parsedURL = url.replace(`${baseURL}/`, '')

  return parsedURL
    .replace(/\/\d+/g, '/:id')
    .replace(/=[\w\W\d]+/g, '=:id')
    // Custom rules
    .replace(/\/get-num-questions\/[\w\W\d]+/g, '/get-num-questions/:id')
    .replace(/\/company_profiles\/[^?][\w\W\d]+/g, '/company_profiles/:company')
}

export function getGeneric(req, res) {
  const pathString = req.originalUrl.toString()
  const isReplica = (pathString.includes('/replica/') ? true : false)
  const query = isReplica ? pathString.replace('/api/replica/', '') : pathString.replace('/api/',
    '')
  const url = isReplica ? (process.env.BASE_REPLICA_URL
    ? process.env.BASE_REPLICA_URL
    : process.env.BASE_URL) : process.env.BASE_URL

  axios
    .get(`${url}${query}`)
    .then((response) => {
      res.send(response.data)
    })
    .catch(() => {
      res.send({})
    })
}

export function getEnvVars() {
  return {
    NODE_ENV: process.env.NODE_ENV,
    activeEnvironment: JSON.stringify(process.env.ENVIRONMENT),
    baseURL: JSON.stringify(process.env.BASE_URL),
    integrationsURL: JSON.stringify(process.env.INTEGRATIONS_URL),
    hiringURL: JSON.stringify(process.env.HIRING_URL),
    IpsFromIndeed: JSON.stringify(process.env.IPS_FROM_INDEED),
    releaseVersion: JSON.stringify(process.env.RELEASE_VERSION),
  }
}
