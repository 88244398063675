/* Initialize Sentry. Sentry automatically creates environment
depending on what we send. We must first call the API to find out what Environment is active. */
/* ================================= */
import { getRawURL } from '../util'

export const clientConfig = {
  ignoreErrors: [
    'top.GLOBALS',
    '/tinymce/',
    'api/aptrinsic',
    '/maps/api/js',
    '/maps-api/',
    'NotFoundError: Failed to execute \'insertBefore\' on \'Node\'',
  ],
  denyUrls: [
    /extensions\//i,
    /^chrome:\/\//i,
  ],
}

export const captureException = (Sentry) => (error, {
  tags,
  extras,
  fingerprint,
  transactionName,
}) => {
  if (!Sentry) {
    return
  }

  Sentry.withScope((scope) => {
    if (fingerprint) {
      scope.setFingerprint(fingerprint)
    }

    if (extras) {
      scope.setExtras({ ...extras })
    }

    if (transactionName) {
      scope.setTransactionName(String(transactionName))
    }

    if (tags) {
      Object.keys(tags)
        .forEach((key) => {
          scope.setTag(key, tags[key] || 'unknown')
        })
    }

    Sentry.captureException(error)
  })
}

export const logError = (Sentry) => (error, extraInfo = {}) => {
  const browserExtras = window ? {
    userAgent: window.navigator.userAgent,
    browserVendor: window.navigator.vendor,
  } : {}

  const extras = {
    cause: `Exception during ${error.request ? 'request' : 'response'}`,
    source: 'bolivar',
    ...browserExtras,
    ...extraInfo,
  }

  if (process.env.NODE_ENV !== 'production') {
    console.log({
      error,
      extras,
    })
    return
  }

  if (!error.config) {
    captureException(Sentry)(error, { extras })
    return
  }

  const {
    baseURL,
    url,
    method,
  } = error.config

  const rawURL = getRawURL(url, baseURL)

  let tags = {
    request_url: url,
    request_method: method,
  }

  const fingerprint = [
    rawURL,
    method,
  ]

  const statusCode = error.response?.status

  if (error.message.toLowerCase()
    .includes('network')) {
    const statusText = statusCode ? ` (${statusCode})` : ''
    error.message = `${error.message}${statusText} - [${method.toUpperCase()}] ${rawURL}`
  }

  if (statusCode) {
    tags = {
      ...tags,
      response_status: statusCode,
    }
    fingerprint.push(String(statusCode))
  }

  // Right now the transaction name would be the same as the fingerprint
  const transactionName = [...fingerprint]

  captureException(Sentry)(error, {
    tags,
    extras,
    fingerprint,
    transactionName,
  })
}
