/* @flow */

import React from 'react'
import * as Styled from 'page404_styled'

const renderErrormessage = (props) => {
  switch (props.location.pathname) {
    case '/profile/company-profile-unknown':
      return (
        <Styled.UnknownTitle>COMPANY PROFILE NOT FOUND!</Styled.UnknownTitle>
      )
      break
    case '/view/job-description-unknown':
      return (
        <div>
          <Styled.UnknownTitle>JOB NOT FOUND!</Styled.UnknownTitle>
          <Styled.UnknownSubTitle>
            The position you are looking for has been filled, taken down, or has
            expired.
          </Styled.UnknownSubTitle>
        </div>
      )
      break
    case '/verification/state-role-not-supported':
      return (
        <div>
          <Styled.UnknownTitle>VERIFICATION FOR STATE & ROLE NOT SUPPORTED!</Styled.UnknownTitle>
          <Styled.UnknownSubTitle>
          We are unable to support credentialing for this state and role combination. 
          Please reach out to the person who sent you this link for additional steps.
          </Styled.UnknownSubTitle>
        </div>
      )
      break
    default:
      return (
        <div>
          <Styled.UnknownTitle>UNKNOWN PAGE!</Styled.UnknownTitle>
          <Styled.UnknownSubTitle>
            You have tried to access unknown page at: {props.location.pathname}
          </Styled.UnknownSubTitle>
        </div>
      )
  }
}

const Page404 = (props) => (
  <Styled.Container404>
    <Styled.MessageContainer>
      {renderErrormessage(props)}
    </Styled.MessageContainer>
  </Styled.Container404>
)

export default Page404
