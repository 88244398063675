const companyProfileDefaultState = {
  companyProfile: null, // Object
  companyProfileJobsFilters: {
    searchbar: '',
    address: '',
    lat: '',
    lng: '',
    radius: '15',
  }, // Object
  companyProfileJobs: null, // Array of objects
  ipInfo: null, // Object,
  shortenUrl: null, // Object
}

const companyProfileReducer = (state = companyProfileDefaultState, action) => {
  switch (action.type) {
    case 'SAVE_COMPANY_PROFILE':
      return { ...state, companyProfile: { ...action.payload.companyProfile } }
    case 'SAVE_COMPANY_PROFILE_JOBS':
      return {
        ...state,
        companyProfileJobs: [...action.payload.companyProfileJobs],
      }
    case 'SAVE_MORE_COMPANY_PROFILE_JOBS':
      return {
        ...state,
        companyProfileJobs: [
          ...state.companyProfileJobs,
          ...action.payload.moreCompanyProfileJobs,
        ],
      }
    case 'SAVE_COMPANY_PROFILE_JOBS_FILTERS':
      return {
        ...state,
        companyProfileJobsFilters: {
          ...state.companyProfileJobsFilters,
          ...action.payload.filters,
        },
      }
    case 'SAVE_IP_INFO':
      return { ...state, ipInfo: { ...action.payload.ipInfo } }
    case 'SAVE_SHORTEN_COMPANY_URL':
      return { ...state, shortenUrl: { ...action.payload.shortenUrl } }
    default:
      return state
  }
}

export default companyProfileReducer
