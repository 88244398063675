import styled from 'styled-components'

export const SummaryContainer = styled.div`
  margin-bottom: 25px;
  margin-top: 45px;
`

export const SectionHeader = styled.div`
  font-family: 'Neutraface2TextBold';
  font-size: 18px;
  margin-bottom: 20px;

  @media (min-width: 768px) {
    font-size: 24px;
  }
`

export const DangerousDiv = styled.div`
  font-family: 'OpenSansRegular';
  font-size: 16px;
  line-height: 22px;
`
