import styled from 'styled-components'
import { Grid } from 'semantic-ui-react'

export const ComponentRow = styled(Grid.Row)`
  padding-top: 15px !important;
  margin-top: 20px;
`

export const ColumnContainer = styled(Grid.Column)`
  transition: all 1s;
  height: 'inital';
`
export const InnerContainer = styled.div``

export const DescriptionColumnContainer = styled(Grid.Column)`
  transition: all 1s;
  overflow: hidden !important;

  border-right: ${(props) => {
    if (props.position !== 'leftSideExpanded') {
      return '0.5px solid rgba(0, 0, 0, 0.2);'
    }
  }} !important;
`

export const FormColumnContainer = styled(Grid.Column)`
  transition: all 1s;
  overflow: hidden !important;
`
export const ReduceInnerContainer = styled.div`
  height: 100%;

  @media only screen and (min-width: 768px) {
    display: ${(props) => {
      if (props.descriptionColumn) {
        return props.descriptionColumn
      }
    }} !important;

    transition: ${(props) => {
      if (props.descriptionColumn) {
        return 'all 1s'
      }
      return 'all 1s'
    }} !important;
  }

  @media only screen and (min-width: 768px) {
    display: ${(props) => {
      if (props.formColumn) {
        return props.formColumn
      }
    }} !important;

    transition: ${(props) => {
      if (props.formColumn) {
        return 'all 1s'
      }
      return 'all 1s'
    }} !important;
  }

  @media only screen and (max-width: 767px) {
    display: ${(props) => {
      if (props.formColumnMobile === 'true') {
        return 'none'
      }
    }} !important;

    transition: ${(props) => {
      if (props.formColumnMobile === 'true') {
        return 'all 1s'
      }
      return 'all 1s'
    }} !important;
  }

  @media only screen and (max-width: 767px) {
    display: ${(props) => {
      if (props.descriptionColumnMobile === 'true') {
        return 'none'
      }
    }} !important;

    transition: ${(props) => {
      if (props.descriptionColumnMobile === 'true') {
        return 'all 1s'
      }
      return 'all 1s'
    }} !important;
  }
`

export const PreColumn = styled.div`
  background-color: green;
  margin: 0px;
  padding: 0px;
`

export const LeftSideButtonControl = styled.div`
  align-items: center;
  background-color: #fcb040;
  border: 1px solid #fcb040;
  border-radius: 2px;

  bottom: 50%;
  color: #fff;
  cursor: pointer;
  font-family: 'Neutraface2TextBold';

  font-size: 18px;
  letter-spacing: 1.14px;
  line-height: 22px;
  padding: 5px 5px;
  position: absolute;
  right: 0px;
  text-align: center;
  text-decoration: none;

  transform: rotate(-90deg) translate(100%, -50%);
  transform-origin: 100% 50%;
  /* transform: rotate(-90deg); */

  /*
  writing-mode: vertical-rl; */
  z-index: 100;
`

export const TopButtonControl = styled.div`
  background-color: #fcb040;
  border: 1px solid #fcb040;
  border-radius: 2px;
  color: #fff;
  cursor: pointer;
  /*  display: inline-block; */
  font-size: 13px;
  line-height: 20px;

  margin: 20px;
  padding: 2px 10px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
`

export const BottomButtonControl = styled.div`
  background-color: #fcb040;
  border: 1px solid #fcb040;
  border-radius: 2px;
  color: #fff;
  cursor: pointer;
  /*  display: inline-block; */
  font-size: 13px;
  line-height: 20px;

  margin: 20px;
  padding: 2px 10px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
`

export const HideButton = styled.div`
  background-color: yellow;
  bottom: 50px;
  height: 70px;

  margin: auto;
  position: absolute;
  right: 0;

  top: 0;
  width: 60px;
  writing-mode: vertical-rl;
  z-index: 100;
`

/* RESPONSIVE */
/* =========================== */
export const DesktopScreen = styled.div`
  @media only screen and (max-width: 767px) {
    display: none !important;
  }
`
export const MobileScreen = styled.div`
  @media only screen and (min-width: 768px) {
    display: none !important;
  }
`
