import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
/* Styled Components */
import * as Styled from 'jobDescriptionCompanyData_styled'
import SocialIconsLinks from 'SocialIconsLinks'
import {withTranslation} from "react-i18next";

class JobDescriptionCompanyData extends Component {
  render() {
    const { t } = this.props
    return (
      <Styled.JobDescriptionCompanyDataContainer>
        <Styled.SectionHeader>
          {`${t('about')} `}
          {this.props.companyProfile &&
            this.props.companyProfile.name.toUpperCase()}
        </Styled.SectionHeader>
        <Styled.DataRow>
          <span>{t('industry')}:</span>{' '}
          <span>
            {(this.props.companyProfile &&
              this.props.companyProfile.industry) ||
              '-'}
          </span>
        </Styled.DataRow>
        <Styled.DataRow>
          <span>Social:</span>
          <SocialIconsLinks />
        </Styled.DataRow>
        <Styled.DataRow>
          {this.props.companyProfile &&
          this.props.companyProfile.website_link ? (
            <span>
              <a
                href={this.props.companyProfile.website_link}
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('companyWebsite')}
              </a>
            </span>
          ) : (
            '-'
          )}
        </Styled.DataRow>
      </Styled.JobDescriptionCompanyDataContainer>
    )
  }
}

const mapStateToProps = (state) => ({
  companyProfile: state.companyProfileReducer.companyProfile,
})

JobDescriptionCompanyData.propTypes = {
  companyProfile: PropTypes.object, // Contains data about current active company.
}

export default connect(mapStateToProps, {})(
  withTranslation('jobDescription')(JobDescriptionCompanyData)
)
