import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
/* Styled Components */
import * as Styled from 'jobDescriptionBrandLogo_styled'

class JobDescriptionBrandLogo extends Component {
  render() {
    return (
      <Styled.BrandLogo
        src={
          this.props.jobDescription &&
          this.props.jobDescription.company_logo_url
        }
        size="small"
      ></Styled.BrandLogo>
    )
  }
}

const mapStateToProps = (state) => ({
  jobDescription: state.jobDescriptionReducer.jobDescription,
})

JobDescriptionBrandLogo.propTypes = {
  jobDescription: PropTypes.object, // contains data about current active job.
}

export default connect(mapStateToProps, {})(JobDescriptionBrandLogo)
