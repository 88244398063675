const licenseVerificationDefaultState = {
  licenseVerification: null, // Object
}

const licenseVerificationReducer = (
  state = licenseVerificationDefaultState,
  action
) => {
  switch (action.type) {
    case 'SAVE_LICENSE_VERIFICATION_DATA':
      return {
        ...state,
        licenseVerification: action.payload.licenseVerification,
      }

    default:
      return state
  }
}

export default licenseVerificationReducer
