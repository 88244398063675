import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import {Container, Grid} from 'semantic-ui-react'
import * as Styled from 'jobDescriptionQuickApply_styled'
import Scroll from 'react-scroll'
import JobDescriptionMap from 'JobDescriptionMap'
import JobDescriptionQuickApplyForm from 'JobDescriptionQuickApplyForm'
import JobDescriptionBrandLogo from 'JobDescriptionBrandLogo'
import JobDescriptionBrandName from 'JobDescriptionBrandName'
import JobDescriptionSalary from 'JobDescriptionSalary'
import JobDescriptionExperience from 'JobDescriptionExperience'
import JobDescriptionLevelOfEducation from 'JobDescriptionLevelOfEducation'
import JobDescriptionSummary from 'JobDescriptionSummary'
import JobDescriptionCompanyData from 'JobDescriptionCompanyData'
import CompanyProfileImageGallery from 'CompanyProfileImageGallery'
import CompanyProfileVideo from 'CompanyProfileVideo'
import ErrorBoundaryWrapper from '../../../../sharedComponents/errorPages/ErrorBoundary'

const CompanyProfileDescriptionScroll = Scroll.Element
const CompanyProfilePhotosScroll = Scroll.Element
const CompanyProfileVideosScroll = Scroll.Element
const JobDescriptionScroll = Scroll.Element
const JobLocationScroll = Scroll.Element

class JobDescriptionQuickApply extends Component {
  render() {
    return (
      <Styled.JobDescriptionQuickApplyContainer>
        <Grid stackable>
          <Grid.Row columns={2}>
            <Grid.Column verticalAlign="middle">
              <Styled.BrandContainer>
                {/* If company profile logo is not uploaded or if it's a blind job don't render this component */}
                {this.props.jobDescription &&
                  this.props.jobDescription.show_company_logo &&
                  this.props.jobDescription.company_logo_url &&
                  this.props.jobDescription.company_logo_url !==
                    '/public/images/missing-company-logo.png' && (
                    <JobDescriptionBrandLogo />
                  )}
                  <JobDescriptionBrandName />
              </Styled.BrandContainer>
            </Grid.Column>
            <Grid.Column>
              <ErrorBoundaryWrapper tags={{component: 'JobDescriptionQuickApplyForm'}} sectionName="Application form">
                <JobDescriptionQuickApplyForm matchData={this.props.matchData} />
              </ErrorBoundaryWrapper>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={2}>
            <Grid.Column>
              <ErrorBoundaryWrapper tags={{component: 'JobDescription'}} sectionName="Job Description">
                <Styled.BlockContainer>
                  <JobDescriptionScroll name="jobDescriptionScroll" />
                  {
                    !!this.props.jobDescription?.job_detail?.salary_min &&
                    !!this.props.jobDescription?.job_detail?.salary_max &&
                    this.props.jobDescription.job_detail.salary_min !== 0 &&
                    this.props.jobDescription.job_detail.salary_max !== 0 &&
                    <JobDescriptionSalary />
                  }
                  {
                    !!this.props.jobDescription?.job_detail?.years_of_experience &&
                    this.props.jobDescription.job_detail.years_of_experience !== 0 &&
                      <JobDescriptionExperience />
                  }
                  {
                    !!this.props.jobDescription?.job_detail?.min_education_required &&
                    this.props.jobDescription.job_detail.min_education_required !== 'NONE' &&
                      <JobDescriptionLevelOfEducation />
                  }
                  <JobDescriptionSummary />
                </Styled.BlockContainer>
              </ErrorBoundaryWrapper>
            </Grid.Column>

            <Grid.Column>
              <Styled.BlockContainer>
                <ErrorBoundaryWrapper tags={{component: 'JobLocation'}} sectionName="Job Location">
                  <JobLocationScroll name="jobLocationScroll" />
                  <JobDescriptionMap />
                </ErrorBoundaryWrapper>
              </Styled.BlockContainer>
              {/* Hide Photo and Video if Blind job */}
              {this.props.jobDescription &&
              this.props.jobDescription.show_company_logo ? (
                <Fragment>
                  <Styled.BlockContainer>
                    <ErrorBoundaryWrapper tags={{component: 'JobDescriptionCompanyData'}} sectionName="Company Data">
                      <CompanyProfileDescriptionScroll name="companyProfileDescriptionScroll" />
                      <JobDescriptionCompanyData />
                    </ErrorBoundaryWrapper>
                  </Styled.BlockContainer>
                  <Styled.BlockContainer>
                    <ErrorBoundaryWrapper tags={{component: 'CompanyProfileVideo'}} sectionName="Company Video">
                      <CompanyProfileVideosScroll name="companyProfileVideosScroll" />
                      <CompanyProfileVideo />
                    </ErrorBoundaryWrapper>
                  </Styled.BlockContainer>
                  <Styled.BlockContainer>
                    <CompanyProfilePhotosScroll name="companyProfilePhotosScroll" />
                    <CompanyProfileImageGallery />
                  </Styled.BlockContainer>
                </Fragment>
              ) : null}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Styled.JobDescriptionQuickApplyContainer>
    )
  }
}

const mapStateToProps = (state) => ({
  jobDescription: state.jobDescriptionReducer.jobDescription,
})

JobDescriptionQuickApply.propTypes = {
  jobDescription: PropTypes.object, // contains data about current active job.
  matchData: PropTypes.object, // comes from parent 'JobDescription' component. It's a route prop 'match' from react-router.
}

export default connect(mapStateToProps, {})(JobDescriptionQuickApply)
