import styled from 'styled-components'
import { Form, Icon, Dropdown } from 'semantic-ui-react'

export const LpnRnVerificationContainer = styled.div`
  text-align: center;
  max-width: 620px;
  padding: 35px 50px;
  margin: 72px 15px 15px;
  box-shadow: 0px 4px 4px 2px rgba(0, 0, 0, 0.25);
  border-radius: 10px;

  @media only screen and (max-width: 767px) {
    margin: 30px 15px 15px;
  }
`

export const TitleText = styled.div`
  font-weight: 700;
  font-size: 24px;
  margin: 15px 0;
  line-height: 28px;
`

export const SubTitleText = styled.div`
  font-weight: 400;
  font-size: 20px;
  margin: 15px 0px 25px;
  line-height: 23px;
`

export const InputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 35px 0px 10px;
  /* flex-direction: column !important; */

  @media only screen and (max-width: 590px) {
    justify-content: center;
  }
`

export const InputColumnWrapper = styled.div`
  width: 215px;

  @media only screen and (max-width: 590px) {
    width: 100%;
  }
`

export const InputLabel = styled.label`
  text-align: left;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 14px;
  font-size: 14px;
  margin-bottom: 10px !important;

  i {
    margin-left: 5px !important;
  }
`

export const InputField = styled.input`
  height: 30px;
  border: 1px solid #7c2e97 !important;
  border-radius: 3px !important;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`

export const DropdownField = styled(Dropdown)`
  height: 30px;
  border: 1px solid #7c2e97 !important;
  border-radius: 3px !important;
  min-height: 30px !important;
  padding-top: 9.5px !important;
  padding-bottom: 9.5px !important;
  display: flex !important;
  align-items: center !important;
  i {
    height: 30px !important;
    padding-top: 9.5px !important;
    padding-bottom: 9.5px !important;
    display: flex !important;
    align-items: center !important;
  }
`

export const FormField = styled(Form.Field)`
  margin-bottom: 30px !important;

  .warning-message-mount-exit {
    opacity: 1;
  }
  .warning-message-mount-exit-active {
    opacity: 0;
    transition: opacity 500ms;
  }
  .warning-message-mount-enter {
    opacity: 0;
  }
  .warning-message-mount-enter-active {
    opacity: 1;
    transition: opacity 500ms;
  }
`

export const WarningMessage = styled.div`
  color: #d62626;
  text-align: left;
  font-weight: 400;
  font-size: 10px;
  line-height: 16px;
  margin-top: 5px;
`

export const FileColumnWrapper = styled.div`
  .file-name-mount-exit {
    opacity: 1;
  }
  .file-name-mount-exit-active {
    opacity: 1;
    transition: opacity 1ms;
  }
  .file-name-mount-enter {
    opacity: 0;
  }
  .file-name-mount-enter-active {
    opacity: 1;
    transition: opacity 500ms;
  }
`

export const FileNameContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const CheckIconContainer = styled.div`
  i {
    color: #74b566;
    margin: 0px !important;
  }
`

export const ClearIconContainer = styled.div`
  i {
    color: #000;
    margin: 0px !important;
  }

  &:hover {
    cursor: pointer;
  }
`

export const FileName = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  margin: 0 10px;
  display: flex;
  word-break: break-all;
  justify-content: center;
  max-width: 120px;
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-start;

  @media only screen and (max-width: 590px) {
    margin-top: 40px;
    justify-content: center;
  }
`

export const SubmitButton = styled.input`
  font-size: 16px;
  font-weight: 500;

  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 105px;
  height: 35px;
  box-sizing: border-box;
  border: 2px solid #a761a9;
  border-radius: 8px;
  background-color: #a761a9;
  color: #fff;
  position: relative;
  transition: all 0.3s;

  &:hover {
    cursor: pointer;
    background-color: #fff;
    color: #a761a9;
    transition: all 0.3s;
  }
  &:focus {
    outline: none !important;
  }
`

export const FooterText = styled.div`
  font-weight: 400;
  font-size: 13px;
  margin: 25px 0px 15px;
  text-align: left;
  @media only screen and (max-width: 590px) {
    text-align: center;
  }
`

export const DropzoneField = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 10px;
  border-style: dashed;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
  width: 185px;
  height: 180px;
  margin: 25px 0px 15px;

  &:hover {
    cursor: pointer;
  }
`

export const FolderIcon = styled(Icon)`
  font-size: 28px !important;
  color: #e38e66;
  box-sizing: border-box;
`

export const DragTitle = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #000;
  margin-top: 10px;
`
export const DragTitleOptional = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #000;
`

export const DragSubTitle = styled.div`
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  color: #939597;
  margin-top: 10px;
  width: 170px;
`

export const DragFileType = styled.div`
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  color: #939597;
  margin-top: 10px;
  width: 170px;
`
