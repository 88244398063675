export const countries = [
  { text: 'United States', value: 'US', key: 'us' },
  { text: 'United Kingdom', value: 'GB', key: 'gb' },
  { text: 'Afghanistan', value: 'AF', key: 'af' },
  { text: 'Åland Islands', value: 'AX', key: 'ax' },
  { text: 'Albania', value: 'AL', key: 'al' },
  { text: 'Algeria', value: 'DZ', key: 'dz' },
  { text: 'American Samoa', value: 'AS', key: 'as' },
  { text: 'Andorra', value: 'AD', key: 'ad' },
  { text: 'Angola', value: 'AO', key: 'ao' },
  { text: 'Anguilla', value: 'AI', key: 'ai' },
  { text: 'Antarctica', value: 'AQ', key: 'aq' },
  { text: 'Antigua and Barbuda', value: 'AG', key: 'ag' },
  { text: 'Argentina', value: 'AR', key: 'ar' },
  { text: 'Armenia', value: 'AM', key: 'am' },
  { text: 'Aruba', value: 'AW', key: 'aw' },
  { text: 'Australia', value: 'AU', key: 'au' },
  { text: 'Austria', value: 'AT', key: 'at' },
  { text: 'Azerbaijan', value: 'AZ', key: 'az' },
  { text: 'Bahamas', value: 'BS', key: 'bs' },
  { text: 'Bahrain', value: 'BH', key: 'bh' },
  { text: 'Bangladesh', value: 'BD', key: 'bd' },
  { text: 'Barbados', value: 'BB', key: 'bb' },
  { text: 'Belarus', value: 'BY', key: 'by' },
  { text: 'Belgium', value: 'BE', key: 'be' },
  { text: 'Belize', value: 'BZ', key: 'bz' },
  { text: 'Benin', value: 'BJ', key: 'bj' },
  { text: 'Bermuda', value: 'BM', key: 'bm' },
  { text: 'Bhutan', value: 'BT', key: 'bt' },
  { text: 'Bolivia', value: 'BO', key: 'bo' },
  { text: 'Bosnia and Herzegovina', value: 'BA', key: 'ba' },
  { text: 'Botswana', value: 'BW', key: 'bw' },
  { text: 'Bouvet Island', value: 'BV', key: 'bv' },
  { text: 'Brazil', value: 'BR', key: 'br' },
  { text: 'British Indian Ocean Territory', value: 'IO', key: 'io' },
  { text: 'Brunei Darussalam', value: 'BN', key: 'bn' },
  { text: 'Bulgaria', value: 'BG', key: 'bg' },
  { text: 'Burkina Faso', value: 'BF', key: 'bf' },
  { text: 'Burundi', value: 'BI', key: 'bi' },
  { text: 'Cambodia', value: 'KH', key: 'kh' },
  { text: 'Cameroon', value: 'CM', key: 'cm' },
  { text: 'Canada', value: 'CA', key: 'ca' },
  { text: 'Cape Verde', value: 'CV', key: 'cv' },
  { text: 'Cayman Islands', value: 'KY', key: 'ky' },
  { text: 'Central African Republic', value: 'CF', key: 'cf' },
  { text: 'Chad', value: 'TD', key: 'td' },
  { text: 'Chile', value: 'CL', key: 'cl' },
  { text: 'China', value: 'CN', key: 'cn' },
  { text: 'Christmas Island', value: 'CX', key: 'cx' },
  { text: 'Cocos (Keeling) Islands', value: 'CC', key: 'cc' },
  { text: 'Colombia', value: 'CO', key: 'co' },
  { text: 'Comoros', value: 'KM', key: 'km' },
  { text: 'Congo', value: 'CG', key: 'cg' },
  { text: 'Congo, The Democratic Republic of the', value: 'CD', key: 'cd' },
  { text: 'Cook Islands', value: 'CK', key: 'ck' },
  { text: 'Costa Rica', value: 'CR', key: 'cr' },
  { text: 'Cote D"Ivoire', value: 'CI', key: 'ci' },
  { text: 'Croatia', value: 'HR', key: 'hr' },
  { text: 'Cuba', value: 'CU', key: 'cu' },
  { text: 'Cyprus', value: 'CY', key: 'cy' },
  { text: 'Czech Republic', value: 'CZ', key: 'cz' },
  { text: 'Denmark', value: 'DK', key: 'dk' },
  { text: 'Djibouti', value: 'DJ', key: 'dj' },
  { text: 'Dominica', value: 'DM', key: 'dm' },
  { text: 'Dominican Republic', value: 'DO', key: 'do' },
  { text: 'Ecuador', value: 'EC', key: 'ec' },
  { text: 'Egypt', value: 'EG', key: 'eg' },
  { text: 'El Salvador', value: 'SV', key: 'sv' },
  { text: 'Equatorial Guinea', value: 'GQ', key: 'gq' },
  { text: 'Eritrea', value: 'ER', key: 'er' },
  { text: 'Estonia', value: 'EE', key: 'ee' },
  { text: 'Ethiopia', value: 'ET', key: 'et' },
  { text: 'Falkland Islands (Malvinas)', value: 'FK', key: 'fk' },
  { text: 'Faroe Islands', value: 'FO', key: 'fo' },
  { text: 'Fiji', value: 'FJ', key: 'fj' },
  { text: 'Finland', value: 'FI', key: 'fi' },
  { text: 'France', value: 'FR', key: 'fr' },
  { text: 'French Guiana', value: 'GF', key: 'gf' },
  { text: 'French Polynesia', value: 'PF', key: 'pf' },
  { text: 'French Southern Territories', value: 'TF', key: 'tf' },
  { text: 'Gabon', value: 'GA', key: 'a' },
  { text: 'Gambia', value: 'GM', key: 'gm' },
  { text: 'Georgia', value: 'GE', key: 'ge' },
  { text: 'Germany', value: 'DE', key: 'de' },
  { text: 'Ghana', value: 'GH', key: 'gh' },
  { text: 'Gibraltar', value: 'GI', key: 'gi' },
  { text: 'Greece', value: 'GR', key: 'gr' },
  { text: 'Greenland', value: 'GL', key: 'gl' },
  { text: 'Grenada', value: 'GD', key: 'gd' },
  { text: 'Guadeloupe', value: 'GP', key: 'gp' },
  { text: 'Guam', value: 'GU', key: 'gu' },
  { text: 'Guatemala', value: 'GT', key: 'gt' },
  { text: 'Guernsey', value: 'GG', key: 'gg' },
  { text: 'Guinea', value: 'GN', key: 'gn' },
  { text: 'Guinea-Bissau', value: 'GW', key: 'gw' },
  { text: 'Guyana', value: 'GY', key: 'gy' },
  { text: 'Haiti', value: 'HT', key: 'ht' },
  { text: 'Heard Island and Mcdonald Islands', value: 'HM', key: 'hm' },
  { text: 'Holy See (Vatican City State)', value: 'VA', key: 'va' },
  { text: 'Honduras', value: 'HN', key: 'hn' },
  { text: 'Hong Kong', value: 'HK', key: 'hk' },
  { text: 'Hungary', value: 'HU', key: 'hu' },
  { text: 'Iceland', value: 'IS', key: 'is' },
  { text: 'India', value: 'IN', key: 'in' },
  { text: 'Indonesia', value: 'ID', key: 'id' },
  { text: 'Iran, Islamic Republic Of', value: 'IR', key: 'ir' },
  { text: 'Iraq', value: 'IQ', key: 'iq' },
  { text: 'Ireland', value: 'IE', key: 'ie' },
  { text: 'Isle of Man', value: 'IM', key: 'im' },
  { text: 'Israel', value: 'IL', key: 'il' },
  { text: 'Italy', value: 'IT', key: 'it' },
  { text: 'Jamaica', value: 'JM', key: 'jm' },
  { text: 'Japan', value: 'JP', key: 'jp' },
  { text: 'Jersey', value: 'JE', key: 'je' },
  { text: 'Jordan', value: 'JO', key: 'jo' },
  { text: 'Kazakhstan', value: 'KZ', key: 'kz' },
  { text: 'Kenya', value: 'KE', key: 'ke' },
  { text: 'Kiribati', value: 'KI', key: 'ki' },
  { text: 'Korea, Democratic People"S Republic of', value: 'KP', key: 'kp' },
  { text: 'Korea, Republic of', value: 'KR', key: 'kr' },
  { text: 'Kuwait', value: 'KW', key: 'kw' },
  { text: 'Kyrgyzstan', value: 'KG', key: 'kg' },
  { text: 'Lao People"S Democratic Republic', value: 'LA', key: 'la' },
  { text: 'Latvia', value: 'LV', key: 'lv' },
  { text: 'Lebanon', value: 'LB', key: 'lb' },
  { text: 'Lesotho', value: 'LS', key: 'ls' },
  { text: 'Liberia', value: 'LR', key: 'lr' },
  { text: 'Libyan Arab Jamahiriya', value: 'LY', key: 'ly' },
  { text: 'Liechtenstein', value: 'LI', key: 'li' },
  { text: 'Lithuania', value: 'LT', key: 'lt' },
  { text: 'Luxembourg', value: 'LU', key: 'lu' },
  { text: 'Macao', value: 'MO', key: 'mo' },
  {
    text: 'Macedonia, The Former Yugoslav Republic of',
    value: 'MK',
    key: 'mk',
  },
  { text: 'Madagascar', value: 'MG', key: 'mg' },
  { text: 'Malawi', value: 'MW', key: 'mw' },
  { text: 'Malaysia', value: 'MY', key: 'my' },
  { text: 'Maldives', value: 'MV', key: 'mv' },
  { text: 'Mali', value: 'ML', key: 'ml' },
  { text: 'Malta', value: 'MT', key: 'mt' },
  { text: 'Marshall Islands', value: 'MH', key: 'mh' },
  { text: 'Martinique', value: 'MQ', key: 'mq' },
  { text: 'Mauritania', value: 'MR', key: 'mr' },
  { text: 'Mauritius', value: 'MU', key: 'mu' },
  { text: 'Mayotte', value: 'YT', key: 'yt' },
  { text: 'Mexico', value: 'MX', key: 'mx' },
  { text: 'Micronesia, Federated States of', value: 'FM', key: 'fm' },
  { text: 'Moldova, Republic of', value: 'MD', key: 'md' },
  { text: 'Monaco', value: 'MC', key: 'mc' },
  { text: 'Mongolia', value: 'MN', key: 'mn' },
  { text: 'Montserrat', value: 'MS', key: 'ms' },
  { text: 'Morocco', value: 'MA', key: 'ma' },
  { text: 'Mozambique', value: 'MZ', key: 'mz' },
  { text: 'Myanmar', value: 'MM', key: 'mm' },
  { text: 'Namibia', value: 'NA', key: 'na' },
  { text: 'Nauru', value: 'NR', key: 'nr' },
  { text: 'Nepal', value: 'NP', key: 'np' },
  { text: 'Netherlands', value: 'NL', key: 'nl' },
  { text: 'Netherlands Antilles', value: 'AN', key: 'an' },
  { text: 'New Caledonia', value: 'NC', key: 'nc' },
  { text: 'New Zealand', value: 'NZ', key: 'nz' },
  { text: 'Nicaragua', value: 'NI', key: 'ni' },
  { text: 'Niger', value: 'NE', key: 'ne' },
  { text: 'Nigeria', value: 'NG', key: 'ng' },
  { text: 'Niue', value: 'NU', key: 'nu' },
  { text: 'Norfolk Island', value: 'NF', key: 'nf' },
  { text: 'Northern Mariana Islands', value: 'MP', key: 'mp' },
  { text: 'Norway', value: 'NO', key: 'no' },
  { text: 'Oman', value: 'OM', key: 'om' },
  { text: 'Pakistan', value: 'PK', key: 'pk' },
  { text: 'Palau', value: 'PW', key: 'pw' },
  { text: 'Palestinian Territory, Occupied', value: 'PS', key: 'ps' },
  { text: 'Panama', value: 'PA', key: 'pa' },
  { text: 'Papua New Guinea', value: 'PG', key: 'pg' },
  { text: 'Paraguay', value: 'PY', key: 'py' },
  { text: 'Peru', value: 'PE', key: 'pe' },
  { text: 'Philippines', value: 'PH', key: 'ph' },
  { text: 'Pitcairn', value: 'PN', key: 'pn' },
  { text: 'Poland', value: 'PL', key: 'pl' },
  { text: 'Portugal', value: 'PT', key: 'pt' },
  { text: 'Qatar', value: 'QA', key: 'qa' },
  { text: 'Reunion', value: 'RE', key: 're' },
  { text: 'Romania', value: 'RO', key: 'ro' },
  { text: 'Russian Federation', value: 'RU', key: 'ru' },
  { text: 'RWANDA', value: 'RW', key: 'rw' },
  { text: 'Saint Helena', value: 'SH', key: 'sh' },
  { text: 'Saint Kitts and Nevis', value: 'KN', key: 'kn' },
  { text: 'Saint Lucia', value: 'LC', key: 'lc' },
  { text: 'Saint Pierre and Miquelon', value: 'PM', key: 'pm' },
  { text: 'Saint Vincent and the Grenadines', value: 'VC', key: 'vc' },
  { text: 'Samoa', value: 'WS', key: 'ws' },
  { text: 'San Marino', value: 'SM', key: 'sm' },
  { text: 'Sao Tome and Principe', value: 'ST', key: 'st' },
  { text: 'Saudi Arabia', value: 'SA', key: 'sa' },
  { text: 'Senegal', value: 'SN', key: 'sn' },
  { text: 'Serbia and Montenegro', value: 'CS', key: 'cs' },
  { text: 'Seychelles', value: 'SC', key: 'sc' },
  { text: 'Sierra Leone', value: 'SL', key: 'sl' },
  { text: 'Singapore', value: 'SG', key: 'sg' },
  { text: 'Slovakia', value: 'SK', key: 'sk' },
  { text: 'Slovenia', value: 'SI', key: 'si' },
  { text: 'Solomon Islands', value: 'SB', key: 'sb' },
  { text: 'Somalia', value: 'SO', key: 'so' },
  { text: 'South Africa', value: 'ZA', key: 'za' },
  {
    text: 'South Georgia and the South Sandwich Islands',
    value: 'GS',
    key: 'gs',
  },
  { text: 'Spain', value: 'ES', key: 'es' },
  { text: 'Sri Lanka', value: 'LK', key: 'lk' },
  { text: 'Sudan', value: 'SD', key: 'sd' },
  { text: 'Suritext', value: 'SR', key: 'sr' },
  { text: 'Svalbard and Jan Mayen', value: 'SJ', key: 'sj' },
  { text: 'Swaziland', value: 'SZ', key: 'sz' },
  { text: 'Sweden', value: 'SE', key: 'se' },
  { text: 'Switzerland', value: 'CH', key: 'ch' },
  { text: 'Syrian Arab Republic', value: 'SY', key: 'sy' },
  { text: 'Taiwan, Province of China', value: 'TW', key: 'tw' },
  { text: 'Tajikistan', value: 'TJ', key: 'tj' },
  { text: 'Tanzania, United Republic of', value: 'TZ', key: 'tz' },
  { text: 'Thailand', value: 'TH', key: 'th' },
  { text: 'Timor-Leste', value: 'TL', key: 'tl' },
  { text: 'Togo', value: 'TG', key: 'tg' },
  { text: 'Tokelau', value: 'TK', key: 'tk' },
  { text: 'Tonga', value: 'TO', key: 'to' },
  { text: 'Trinidad and Tobago', value: 'TT', key: 'tt' },
  { text: 'Tunisia', value: 'TN', key: 'tn' },
  { text: 'Turkey', value: 'TR', key: 'tr' },
  { text: 'Turkmenistan', value: 'TM', key: 'tm' },
  { text: 'Turks and Caicos Islands', value: 'TC', key: 'tc' },
  { text: 'Tuvalu', value: 'TV', key: 'tv' },
  { text: 'Uganda', value: 'UG', key: 'ug' },
  { text: 'Ukraine', value: 'UA', key: 'ua' },
  { text: 'United Arab Emirates', value: 'AE', key: 'ae' },
  { text: 'United States Minor Outlying Islands', value: 'UM', key: 'um' },
  { text: 'Uruguay', value: 'UY', key: 'uy' },
  { text: 'Uzbekistan', value: 'UZ', key: 'uz' },
  { text: 'Vanuatu', value: 'VU', key: 'vu' },
  { text: 'Venezuela', value: 'VE', key: 've' },
  { text: 'Viet Nam', value: 'VN', key: 'vn' },
  { text: 'Virgin Islands, British', value: 'VG', key: 'vg' },
  { text: 'Virgin Islands, U.S.', value: 'VI', key: 'vi' },
  { text: 'Wallis and Futuna', value: 'WF', key: 'wf' },
  { text: 'Western Sahara', value: 'EH', key: 'eh' },
  { text: 'Yemen', value: 'YE', key: 'ye' },
  { text: 'Zambia', value: 'ZM', key: 'zm' },
  { text: 'Zimbabwe', value: 'ZW', key: 'zw' },
]

export const states = [
  { text: 'Alabama', value: 'AL', key: 'al' },
  { text: 'Alaska', value: 'AK', key: 'ak' },
  { text: 'Arizona', value: 'AZ', key: 'az' },
  { text: 'Arkansas', value: 'AR', key: 'ar' },
  { text: 'California', value: 'CA', key: 'ca' },
  { text: 'Colorado', value: 'CO', key: 'co' },
  { text: 'Connecticut', value: 'CT', key: 'ct' },
  { text: 'Delaware', value: 'DE', key: 'de' },
  { text: 'District of Columbia', value: 'DC', key: 'dc' },
  { text: 'Florida', value: 'FL', key: 'fl' },
  { text: 'Georgia', value: 'GA', key: 'ga' },
  { text: 'Hawaii', value: 'HI', key: 'hi' },
  { text: 'Idaho', value: 'ID', key: 'id' },
  { text: 'Illinois', value: 'IL', key: 'il' },
  { text: 'Indiana', value: 'IN', key: 'in' },
  { text: 'Iowa', value: 'IA', key: 'ia' },
  { text: 'Kansas', value: 'KS', key: 'ks' },
  { text: 'Kentucky', value: 'KY', key: 'ky' },
  { text: 'Louisiana', value: 'LA', key: 'la' },
  { text: 'Maine', value: 'ME', key: 'me' },
  { text: 'Maryland', value: 'MD', key: 'md' },
  { text: 'Massachusetts', value: 'MA', key: 'ma' },
  { text: 'Michigan', value: 'MI', key: 'mi' },
  { text: 'Minnesota', value: 'MN', key: 'mn' },
  { text: 'Mississippi', value: 'MS', key: 'ms' },
  { text: 'Missouri', value: 'MO', key: 'mo' },
  { text: 'Montana', value: 'MT', key: 'mt' },
  { text: 'Nebraska', value: 'NE', key: 'ne' },
  { text: 'Nevada', value: 'NV', key: 'nv' },
  { text: 'New Hampshire', value: 'NH', key: 'nh' },
  { text: 'New Jersey', value: 'NJ', key: 'nj' },
  { text: 'New Mexico', value: 'NM', key: 'nm' },
  { text: 'New York', value: 'NY', key: 'ny' },
  { text: 'North Carolina', value: 'NC', key: 'nc' },
  { text: 'North Dakota', value: 'ND', key: 'nd' },
  { text: 'Ohio', value: 'OH', key: 'oh' },
  { text: 'Oklahoma', value: 'OK', key: 'ok' },
  { text: 'Oregon', value: 'OR', key: 'or' },
  { text: 'Puerto Rico', value: 'PR', key: 'pr' },
  { text: 'Pennsylvania', value: 'PA', key: 'pa' },
  { text: 'Rhode Island', value: 'RI', key: 'ri' },
  { text: 'South Carolina', value: 'SC', key: 'sc' },
  { text: 'South Dakota', value: 'SD', key: 'sd' },
  { text: 'Tennessee', value: 'TN', key: 'tn' },
  { text: 'Texas', value: 'TX', key: 'tx' },
  { text: 'Utah', value: 'UT', key: 'ut' },
  { text: 'Vermont', value: 'VT', key: 'vt' },
  { text: 'Virginia', value: 'VA', key: 'va' },
  { text: 'Washington', value: 'WA', key: 'wa' },
  { text: 'West Virginia', value: 'WV', key: 'wv' },
  { text: 'Wisconsin', value: 'WI', key: 'wi' },
  { text: 'Wyoming', value: 'WY', key: 'wy' },
]

export const qualifications = [
  { text: "Master's Degree", value: "Master's Degree", key: 'MG' },
  {
    text: 'Postsecondary Non-degree Award',
    value: 'Postsecondary Non-degree Award',
    key: 'PNA',
  },
  { text: 'College', value: 'College', key: 'CO' },
  { text: "Bachelor's Degree", value: "Bachelor's Degree", key: 'BD' },
  { text: "Associate's Degree", value: "Associate's Degree", key: 'AD' },
  { text: 'GED', value: 'GED', key: 'GED' },
  { text: 'High School Diploma', value: 'High School Diploma', key: 'HSD' },
  { text: 'Some High School', value: 'Some High School', key: 'SHS' },
  {
    text: 'Completed Middle School',
    value: 'Completed Middle School',
    key: 'CMS',
  },
]
