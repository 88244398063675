import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import * as Styled from 'companyProfileVideo_styled'

const CompanyProfileVideo = (props) => (
  <Fragment>
    {props.companyProfile &&
    props.companyProfile.video &&
    props.companyProfile.video.external_link ? (
      <div>
        {' '}
        {props.routeData && props.routeData.key === 'jobDescription' ? (
          <Styled.SectionHeader>VIDEOS</Styled.SectionHeader>
        ) : (
          ''
        )}
        <video
          width="100%"
          height="100%"
          controls
          style={{ backgroundColor: '#000' }}
        >
          <source src={props.companyProfile.video.external_link} />
          <track default kind="captions" srcLang="en" />
        </video>
      </div>
    ) : null}
  </Fragment>
)

const mapStateToProps = (state) => ({
  routeData: state.globalReducer.routeData,
  companyProfile: state.companyProfileReducer.companyProfile,
})

CompanyProfileVideo.propTypes = {
  companyProfile: PropTypes.object,
}

export default connect(mapStateToProps, {})(CompanyProfileVideo)
