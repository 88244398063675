import React, { Component } from 'react'
import { connect } from 'react-redux'
import CompanyProfileBody from 'CompanyProfileBody'
import IndustriesFilter from 'IndustriesFilter'
import CitiesFilter from 'CitiesFilter'
import * as Styled from 'search_styled'

import queryString from 'query-string'
import { Dimmer, Loader } from 'semantic-ui-react'
import { withTranslation } from 'react-i18next'
import { saveRouteData } from '../../../actions/global_actions'
import {
  getCompanyProfileAction,
  getIPInfoAction,
  getCompanyProfileJobsAction,
} from '../../../actions/api_actions'
import { saveNumberOfQuestions } from '../../../actions/jobDescription_actions'

class Search extends Component {
  state = {
    debug: Boolean(queryString.parse(this.props.location.search)?.debug)
  };

  componentDidMount() {
    const { debug } = this.state
    const {
      route, match, location, i18n: { language }
    } = this.props
    const searchParams = queryString.parse(location.search)
    this.utm = {
      utm_source: searchParams?.utm_source || 'bolivar-internal-search-page',
      utm_campaign: searchParams?.utm_campaign,
      utm_medium: searchParams?.utm_medium,
    }
    /* save global route data */
    this.props.saveRouteData(route, match, location)

    this.props.getCompanyProfileJobsAction(
      window.activeEnvironment,
      1,
      null,
      '',
      '',
      '',
      '',
      true,
      debug,
      language,
      null,
      null,
      null,
      this.utm,
    )
    /* We reset number of questions for here just for UI look. When someone clicks
    on some job it looks for the number of questions for that job and renders
    quick apply of full apply page. If someone goes back with "back" button
    and clicks second job, until data is fetched for a number of questions the
    old number stays and renders quick apply of full apply page. In order not to
    show that flash we reset it here. To do this on job page with componentWillUnmount
    will break stuff so don't do it (after login on quick apply it will not redirect to full apply) */

    this.props.saveNumberOfQuestions(null)
  }

  componentDidUpdate = (prevProps) => {
    /* We are calling this only on first load but we can not call it before
    we get back the companyProfile and ipInfo data. First we check for null and then if
    they are available so they will not be triggered again latter since they can not be
    null again */
    if (prevProps.companyProfile === null || prevProps.ipInfo === null) {
      if (this.props.companyProfile && this.props.ipInfo) {
        // On first load we are always starting from page "1"
        // there is no search keyword so it's "null"
        // We got latitude and longitude from ipInfo
        // We got teams to show from company profile API response
        this.props.getCompanyProfileJobsAction(
          window.activeEnvironment,
          1,
          null,
          this.props.ipInfo.latitude,
          this.props.ipInfo.longitude,
          ''
        )
      }
    }
  }

  updateJobs = () => {
    const {
      i18n: { language }, city, filteredIndustries, companyProfileJobsFilters
    } = this.props
    this.setState({
      debug: !this.state.debug,
    }, () => {
      this.props.getCompanyProfileJobsAction(
        window.activeEnvironment,
        1,
        null,
        companyProfileJobsFilters?.lat,
        companyProfileJobsFilters?.lng,
        '',
        filteredIndustries,
        true,
        this.state.debug,
        language,
        city?.city,
        city?.state,
        companyProfileJobsFilters?.radius,
        this.utm,
      )
    })
  }

  render() {
    const { debug } = this.state
    const { loading, i18n, ipInfo } = this.props
    return (
      <div>
        <Styled.SearchContainer>
          <div style={{ marginLeft: '0.15rem' }}>
            <CitiesFilter
              backfilledJobs
              debug={debug}
              language={i18n.language}
              utm={this.utm}
            />
            <IndustriesFilter
              backfilledJobs
              debug={debug}
              language={i18n.language}
              utm={this.utm}
            />
          </div>
          <Styled.JobsContainer>
            <CompanyProfileBody
              allTeamsSearch
              match={this.props.match}
              backfilledJobs
              debug={debug}
              updateJobs={this.updateJobs}
              utm={this.utm}
            />
          </Styled.JobsContainer>
          {loading && (
            <Dimmer active inverted page>
              <Loader size="large">Loading</Loader>
            </Dimmer>
          )}
        </Styled.SearchContainer>
      </div>
    )
  }
}

Search.serverFetch = [getCompanyProfileAction, getIPInfoAction, ]

const mapStateToProps = (state) => ({
  companyProfile: state.companyProfileReducer.companyProfile,
  companyProfileJobs: state.companyProfileReducer.companyProfileJobs,
  ipInfo: state.companyProfileReducer.ipInfo,
  shortenUrl: state.companyProfileReducer.shortenUrl,
  loading: state.globalReducer.loading,
  city: state.globalReducer.city,
  filteredIndustries: state.globalReducer.filteredIndustries,
  companyProfileJobsFilters:
  state.companyProfileReducer.companyProfileJobsFilters,
})

export default connect(mapStateToProps, {
  saveRouteData,
  getCompanyProfileAction,
  getIPInfoAction,
  getCompanyProfileJobsAction,
  saveNumberOfQuestions,
})(
  withTranslation('account')(Search)
)
